import { createSlice } from "@reduxjs/toolkit";

const tableFilterSlice = createSlice({
  name: "tableFilter",
  initialState: {
    tableName: "",
    filters: [],
    mainCondition: "",
  },
  reducers: {
    setTableName: (state, action) => {
      state.tableName = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = [...action.payload];
    },
    setMainCondition: (state, action) => {
      state.mainCondition = action.payload;
    },
    resetFilterState: (state) => {
      state.tableName = "";
      state.filters = [];
      state.mainCondition = "";
    },
  },
});

export const { setTableName, setFilters, setMainCondition, resetFilterState } =
  tableFilterSlice.actions;

export default tableFilterSlice.reducer;

export const selectTableFilter = (state) => state.tableFilter;
