import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Box,
  Button,
  IconButton,
  Avatar,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

// components
import { Close, CloudUploadOutlined } from "@mui/icons-material";
import { countries } from "countries-list";
import {
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
} from "../../../redux/services/organization.service";
import Scrollbar from "../../../components/scrollbar";
import { OrganizationSchema } from "../../../forms-schema/form.schema";
import { useUploadSingleFileMutation } from "../../../redux/services/upload.service";

OrganizationForm.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  openRow: PropTypes.object,
};

export default function OrganizationForm({ open, onClose, edit, openRow }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
    control,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(OrganizationSchema),
  });

  const fileRef = useRef();
  const [createOrganization, { isLoading }] = useCreateOrganizationMutation();
  const [updateOrganization, { isLoading: isUpdateLoading }] =
    useUpdateOrganizationMutation();
  const [uploadOrgLogo, { isLoading: isLogoUploading }] =
    useUploadSingleFileMutation();

  const [file, setFile] = useState(null);

  const handleLogoUpload = async () => {
    const fileUploadURL = await uploadOrgLogo(fileRef.current.files[0]);
    setValue("logo", fileUploadURL?.data?.data?.Location);
  };

  const handleClick = async (values) => {
    let resultAction;
    let fileUploadURL;
    if (values.isSplashScreen && !file && !values?.logo?.length) {
      setError("logo", {
        type: "required",
        message: "Logo is required",
      });
      return;
    }

    if (!values.isSplashScreen) {
      values.logo = null;
      values.speakOutSentence = null;
    }

    if (values?.isSplashScreen && file) {
      fileUploadURL = await uploadOrgLogo(file);
      values.logo = fileUploadURL?.data?.data?.Location;
    }

    if (edit) {
      resultAction = await updateOrganization({
        ...values,
        id: openRow.id,
      });
    } else {
      resultAction = await createOrganization({ ...values });
    }
    if (resultAction?.data?.success) {
      onClose();
      reset();
    }
  };

  useEffect(() => {
    if (edit && openRow) {
      setValue("status", openRow.status);
      setValue("name", openRow.name);
      setValue("description", openRow.description);
      setValue("address", openRow.address);
      setValue("city", openRow.city);
      setValue("state", openRow.state);
      setValue("country", openRow.country);
      setValue("zipCode", openRow.zipCode);
      setValue("contactNumber", openRow.contactNumber);
      setValue("email", openRow.email);
      setValue("website", openRow.website);
      setValue("type", openRow.type);
      setValue("isSplashScreen", openRow.isSplashScreen);
      // if (openRow.isSplashScreen) {
      setValue("logo", openRow.logo ?? "");
      setValue("speakOutSentence", openRow.speakOutSentence ?? "");
      // }
    } else {
      reset();
    }
  }, [edit, open, openRow, reset, setValue]);

  useEffect(() => {
    if (file) {
      clearErrors("logo");
    }
  }, [file, clearErrors]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">
                  {edit ? "Edit" : "Create"} Organization
                </Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register("createAccount")}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label="Create Organization Account"
                  />
                </FormControl>
                <TextField
                  name="name"
                  label={
                    <>
                      Name <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  {...register("name", { required: true })}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  inputProps={{ "data-testid": "orgName" }}
                />

                <TextField
                  multiline
                  maxRows={4}
                  name="description"
                  label="Description"
                  {...register("description")}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  inputProps={{ "data-testid": "orgDescription" }}
                />

                <TextField
                  name="address"
                  label="Address"
                  multiline
                  maxRows={4}
                  {...register("address")}
                  error={!!errors.address}
                  helperText={errors.address?.message}
                  inputProps={{ "data-testid": "orgAddress" }}
                />

                <TextField
                  name="city"
                  label={
                    <>
                      City <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  {...register("city", { required: true })}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                  inputProps={{ "data-testid": "orgCity" }}
                />

                <TextField
                  name="state"
                  label={
                    <>
                      State <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  {...register("state", { required: true })}
                  error={!!errors.state}
                  helperText={errors.state?.message}
                  inputProps={{ "data-testid": "orgState" }}
                />

                <FormControl fullWidth error={!!errors.country}>
                  <InputLabel id="country" error={!!errors.country}>
                    Country <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={watch("country") || ""}
                    label="Country"
                    error={!!errors.country}
                    labelId="country"
                    name="country"
                    inputProps={{ "data-testid": "orgCountry" }}
                    {...register("country", { required: true })}
                  >
                    {Object.values(countries)
                      ?.toSorted((a, b) => a?.name.localeCompare(b?.name))
                      ?.map((country) => (
                        <MenuItem key={country.name} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>{errors.country?.message}</FormHelperText>
                </FormControl>

                <Controller
                  name="zipCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="ZipCode"
                      {...field}
                      error={!!errors.zipCode} // Show error if validation fails
                      helperText={errors.zipCode?.message} // Display error message
                      inputProps={{
                        maxLength: 6, // Limit the input length to 6
                        "data-testid": "orgZipCode",
                        pattern: "[0-9]*", // Allow only numeric input
                        inputMode: "numeric", // Use numeric keyboard on mobile
                      }}
                    />
                  )}
                />

                <Controller
                  name="contactNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      // required={watch("createAccount") === true}
                      label={
                        <>
                          Contact Number{" "}
                          {watch("createAccount") === true ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : (
                            ""
                          )}
                        </>
                      }
                      error={!!errors.contactNumber} // Show error if validation fails
                      helperText={errors.contactNumber?.message} // Display error message
                      inputProps={{
                        maxLength: 10, // Limit the input length to 10
                        "data-testid": "orgContactNumber",
                        pattern: "[0-9]*", // Allow only numeric input
                        inputMode: "numeric", // Use numeric keyboard on mobile
                      }}
                    />
                  )}
                />

                <TextField
                  name="email"
                  label="Email"
                  {...register("email")} // No validation for "required"
                  error={!!errors.email} // Shows error if validation fails
                  helperText={errors.email?.message} // Error message handling
                  inputProps={{ "data-testid": "orgEmail" }}
                />

                <TextField
                  name="website"
                  label="Website"
                  {...register("website")}
                  error={!!errors.website}
                  helperText={errors.website?.message}
                  inputProps={{ "data-testid": "orgWebsite" }}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "data-testid": "isSplashScreen" }}
                      defaultChecked={edit && openRow?.isSplashScreen}
                      checked={watch("isSplashScreen")}
                      {...register("isSplashScreen", { required: false })}
                    />
                  }
                  label="Is Splash Screen"
                />

                {watch("isSplashScreen") && (
                  <>
                    <TextField
                      {...register("speakOutSentence", { required: true })}
                      label="Speak Out Sentence"
                      error={!!errors.speakOutSentence}
                      helperText={errors.speakOutSentence?.message}
                      inputProps={{ "data-testid": "speakOutSentence" }}
                    />
                    <Box>
                      <InputLabel
                        sx={{
                          marginBottom: 1,
                        }}
                        htmlFor="logo"
                        error={Boolean(errors.logo)}
                      >
                        Logo
                      </InputLabel>
                      {file || watch("logo") ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Avatar
                            src={
                              file instanceof File
                                ? URL.createObjectURL(file)
                                : watch("logo")
                            }
                            slotProps={{
                              img: {
                                sx: {
                                  objectFit: "contain",
                                },
                              },
                            }}
                            sx={{
                              width: 40,
                              height: 40,
                              marginRight: 1,
                              borderRadius: 0,
                            }}
                          />
                          <Button
                            variant="text"
                            onClick={() => {
                              setFile(null);
                              setValue("logo", "");
                            }}
                          >
                            Remove
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              fileRef.current.click();
                            }}
                            size="medium"
                            style={{ marginLeft: "-1px" }}
                          >
                            <CloudUploadOutlined />
                            <input
                              ref={fileRef}
                              type="file"
                              accept="*"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                setFile(e.target.files[0]);
                              }}
                              data-testid="logoInput"
                            />
                          </IconButton>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: 16,
                              color: "#95999C",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Choose a file
                          </Typography>
                        </Box>
                      )}
                      <FormHelperText error={Boolean(errors.logo)}>
                        {errors.logo?.message}
                      </FormHelperText>
                    </Box>
                  </>
                )}
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    error={Boolean(errors.type)}
                  >
                    Type <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register("type", { required: true })}
                    label="Type"
                    value={watch("type")}
                    error={!!errors.type}
                    inputProps={{ "data-testid": "orgType" }}
                  >
                    <MenuItem value="ngo">NGO</MenuItem>
                    <MenuItem value="company">CSR</MenuItem>
                    <MenuItem value="company">Philanthropy</MenuItem>
                  </Select>
                  <FormHelperText error={Boolean(errors.type)}>
                    {errors.type?.message}
                  </FormHelperText>
                </FormControl>

                {edit && (
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      error={Boolean(errors.status)}
                    >
                      Status
                    </InputLabel>
                    <Select
                      name="status"
                      {...register("status", { required: false })}
                      label="Status"
                      error={!!errors.status}
                      inputProps={{ "data-testid": "orgStatus" }}
                      value={watch("status")}
                      defaultValue={openRow.status}
                    >
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                      <MenuItem value="pending">Pending</MenuItem>
                    </Select>
                    <FormHelperText error={Boolean(errors.status)}>
                      {errors.status?.message}
                    </FormHelperText>
                  </FormControl>
                )}
                <LoadingButton
                  loading={isUpdateLoading || isLoading || isLogoUploading}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
