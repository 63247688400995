import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  Grid,

  IconButton,

} from "@mui/material";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { format } from "date-fns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DataGrid } from "@mui/x-data-grid";

import { CSVLink } from "react-csv"; // Import CSVLink for downloading data
import DownloadIcon from "@mui/icons-material/Download"; // Import MUI Download Icon

import Protected from "../protected/Protected";
import { useGetFeatureAnalyticsQuery } from "../redux/services/googleAnalytics.service";
import { selectAbility } from "../redux/slices/casl.slice";
import { useGetOrganizationsQuery } from "../redux/services/organization.service";

import { useGetEventPlacesQuery } from "../redux/services/device.service";


const FeatureAnalyticsPage = () => {
  // Get today's date in yyyy-MM-dd format
  const today = new Date();
  const formattedDate = format(today, "yyyy-MM-dd");

  // Initialize default values
  const defaultType = "docAiAnalytics";
  const defaultStartDate = formattedDate;
  const defaultEndDate = formattedDate;

  const ability = useSelector(selectAbility);
  const [openRow, setOpenRow] = useState(null);

  // Initialize useForm hook
  const {
    register,
    control,
    watch,
    setValue,
    reset, // Add reset function for resetting the form
    formState: { errors },

    getValues,

  } = useForm({
    defaultValues: {
      type: defaultType, // Default to "docAiAnalytics"
      startDate: defaultStartDate, // Default to today's date
      endDate: defaultEndDate, // Default to today's date
      search: "", // Ensure search field is part of form values
    },
  });

  // Watch form values for dynamic query params
  const { type, startDate, endDate, search } = watch();

  // Pagination State
  const [pageSize, setPageSize] = useState(10); // Set initial page size to 10
  const [page, setPage] = useState(0); // Initial page is 0

  // Local state to hold the feature analytics data
  const [data, setData] = useState([]);


  const [selectedEventPlace, setSelectedEventPlace] = useState("");
  const [filteredTags, setFilteredTags] = useState([]); // State to store filtered tags

  const {
    isLoading: isOrgLoading,
    isFetching: isOrgFetching,
    isError: isOrgError,
    data: organizations,
  } = useGetOrganizationsQuery(null, {
    // skip: user?.role?.name !== "admin",
  });

  const searchValue = getValues("search");

  const organizationId = watch("organizationId");

  // Fetch feature analytics based on selected filters and pagination
  const {
    isLoading: isFeatureAnalyticsLoading,
    isFetching: isFeatureAnalyticsFetching,
    data: featureAnalytics,
  } = useGetFeatureAnalyticsQuery(
    {
      type,
      startDate,
      endDate,
      page: page + 1, // API typically uses 1-based indexing for pages
      pageSize,

      search: searchValue, // Pass the search term to the API
      ...(organizationId && { organizationId }), // Only include if organizationId is set
      eventPlace: selectedEventPlace || undefined, // Include only if selectedEventPlace has a value
      tag: filteredTags.length > 0 ? filteredTags : undefined, // Include only if there are any tags selected
    },
    { skip: !type }, // Skip the query if type is not selected
  );


  const {
    data: eventPlaces,
    isSuccess: isSuccessEventPlaces,
    isLoading: isLoadingEventPlaces,
  } = useGetEventPlacesQuery({
    organizationId: watch("organizationId"),
  });

  // Update data state when the data has been fetched and avoid unnecessary updates
  useEffect(() => {
    if (
      !isFeatureAnalyticsLoading &&
      !isFeatureAnalyticsFetching &&
      featureAnalytics
    ) {
      setData(featureAnalytics.data || []);
    }
  }, [isFeatureAnalyticsLoading, isFeatureAnalyticsFetching, featureAnalytics]);

  // Reset the filter form to default values
  const handleResetFilter = () => {

    // Reset form values using react-hook-form's reset method
    reset({
      type: defaultType,
      startDate: defaultStartDate,
      endDate: defaultEndDate,

      search: "", // Reset search term
      organizationId: "", // Explicitly reset organizationId as well
      tag: "", // Reset tag field
    });

    setSelectedEventPlace(""); // Clear selected event place

    // Reset page to the first page
    setPage(0);
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  // Handle page size change
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0); // Reset to first page when page size changes
  };

  // Define the columns for the DataGrid
  const columns = [
    { field: "userId", headerName: "User ID", width: 200 },
    { field: "contactNumber", headerName: "Contact Number", width: 180 },
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "senderOrganization",
      headerName: "Sender Organization",
      width: 200,
    },
    {
      field: "recipientOrganization",
      headerName: "Recipient Organization",
      width: 250,
    },
    {
      field: "askQuestionCount",
      headerName: "Ask Question Count",
      width: 200,
      // Custom sort comparator to handle numerical sorting
      sortComparator: (v1, v2) => {
        const num1 = parseInt(v1, 10); // Convert v1 to an integer
        const num2 = parseInt(v2, 10); // Convert v2 to an integer
        return num1 - num2; // Compare the numbers
      },
    },
    {
      field: "conversationCount",
      headerName: "Conversation Count",
      width: 200,
    },
  ];

  // Filter out rows with missing or invalid data
  const validRows =
    data?.filter(
      (row) => row.userId && row.firstName && row.lastName && row.email, // Ensure rows have necessary fields
    ) || [];

  // Apply search functionality: filter rows based on the search term
  const filteredRows = validRows.filter((row) => {
    const searchLower = search.toLowerCase();
    return (
      row.userId?.toLowerCase().includes(searchLower) ||
      row.firstName?.toLowerCase().includes(searchLower) ||
      row.lastName?.toLowerCase().includes(searchLower) ||
      row.email?.toLowerCase().includes(searchLower)
    );
  });


  // Handle event place change
  const handleEventPlaceChange = (event) => {
    try {
      const selectedPlace = event.target.value;
      setSelectedEventPlace(selectedPlace);

      // Filter tags based on selected event place
      const tags =
        eventPlaces?.data
          .filter(
            (event) => event.eventPlace === selectedPlace && event.tag !== null,
          )
          .map((event) => event.tag) || [];

      setFilteredTags(tags);

      // Reset the tag field (clear the value)
      setValue("tag", ""); // This will clear the tag field
    } catch (error) {
      // Catch and log any errors
      console.error("Error while handling event place change:", error);
    }
  };

  // Handle row selection change
  const [selectionModel, setSelectionModel] = useState([]);
  const handleSelectionModelChange = (newSelection) => {
    setSelectionModel(newSelection);
  };

  // Check if all rows are selected
  // const allRowsSelected = selectionModel.length === filteredRows.length;


  return (
    <Protected>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Feature Analytics
          </Typography>
        </Stack>

        <Card>
          <Box sx={{ padding: 2 }}>
            <Stack direction="row" spacing={2} mb={4}>

              <Grid container spacing={2}>
                {/* Search Field */}
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    label="Search"
                    variant="outlined"
                    {...register(
                      "search",
                      // {
                      // onChange: (e) => setValue("search", e.target.value),
                      // }
                    )}
                    value={search}
                    sx={{ width: "100%" }}
                  />
                </Grid>

                {/* Organization Field */}
                {ability.can("get", "Organizations") && (
                  <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="org-select-label"
                        error={Boolean(errors.organizationId)}
                      >
                        Organization
                      </InputLabel>
                      <Select
                        {...register("organizationId", { required: true })}
                        labelId="org-select-label"
                        id="demo-simple-select"
                        inputProps={{ "data-testid": "organizationId" }}
                        label="Organization"
                        error={Boolean(errors.organizationId)}
                        value={watch("organizationId") || ""}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {isOrgLoading || isOrgFetching ? (
                          <MenuItem
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <CircularProgress size={20} />
                          </MenuItem>
                        ) : (
                          organizations?.data
                            ?.toSorted((a, b) =>
                              a?.name?.localeCompare(b?.name),
                            )
                            ?.map((org) => (
                              <MenuItem key={org?.id} value={org?.id}>
                                {org?.name}
                              </MenuItem>
                            ))
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {/* Type Field */}
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label-type">
                      Type
                    </InputLabel>
                    <Select
                      {...register("type")}
                      labelId="demo-simple-select-label-type"
                      label="Type"
                      error={Boolean(errors.type)}
                      value={type}
                    >
                      <MenuItem value="smartEyeAnalytics">
                        SmartEyeAnalytics
                      </MenuItem>
                      <MenuItem value="docAiAnalytics">docAiAnalytics</MenuItem>
                      <MenuItem value="aiChatAnalytics">
                        aiChatAnalytics
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Start Date Field */}
                <Grid item xs={12} sm={6} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label="Start Date"
                          value={field.value ? new Date(field.value) : null}
                          onChange={(date) => {
                            setValue(
                              "startDate",
                              date ? format(date, "yyyy-MM-dd") : "",
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(errors.startDate)}
                              helperText={
                                errors.startDate ? errors.startDate.message : ""
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                {/* End Date Field */}
                <Grid item xs={12} sm={6} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          label="End Date"
                          value={field.value ? new Date(field.value) : null}
                          onChange={(date) => {
                            setValue(
                              "endDate",
                              date ? format(date, "yyyy-MM-dd") : "",
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(errors.endDate)}
                              helperText={
                                errors.endDate ? errors.endDate.message : ""
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                {/* Event Place Field */}
                <Grid item xs={12} sm={6} md={3}>
                  {ability.can("get", "All-Event-Place") && (
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                      <InputLabel id="eventPlace-label">Event Place</InputLabel>
                      <Select
                        labelId="eventPlace-label"
                        id="demo-simple-select"
                        {...register("eventPlace")}
                        label="Event Place"
                        value={selectedEventPlace}
                        onChange={handleEventPlaceChange}
                        inputProps={{ "data-testid": "eventPlace" }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {isLoadingEventPlaces && (
                          <MenuItem>
                            <CircularProgress size={24} />
                          </MenuItem>
                        )}
                        {isSuccessEventPlaces &&
                          eventPlaces?.data?.map((eventPlace, index) => (
                            <MenuItem
                              key={`${eventPlace.eventPlace}-${index}`}
                              value={eventPlace.eventPlace}
                            >
                              {eventPlace.eventPlace}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>

                {/* Tag Field */}
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="tag-label">Tag</InputLabel>
                    <Select
                      labelId="tag-label"
                      id="tag-select"
                      {...register("tag")}
                      label="Tag"
                      value={watch("tag") ?? ""}
                      disabled={filteredTags.length === 0}
                      inputProps={{ "data-testid": "tag" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {filteredTags.length > 0 ? (
                        filteredTags.map((tag, index) => (
                          <MenuItem key={index} value={tag}>
                            {tag}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No tags available</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Reset Filters Button */}
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    mb={3}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Button
                      onClick={handleResetFilter}
                      variant="contained"
                      color="secondary"
                    >
                      Reset Filters
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Stack>

            {/* Table */}
            <Box sx={{ height: 500, width: "100%" }}>
              {filteredRows.length > 0 && (
                <CSVLink
                  data={filteredRows}
                  filename="feature_analytics.csv"
                  style={{ textDecoration: "none" }}
                >
                  <IconButton color="primary" aria-label="download">
                    <DownloadIcon />
                  </IconButton>
                </CSVLink>
              )}
              <DataGrid
                rows={filteredRows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 25]}
                pagination
                onPageChange={handlePageChange}
                page={page}
                onPageSizeChange={handlePageSizeChange}
                checkboxSelection
                selectionModel={selectionModel}
                onSelectionModelChange={handleSelectionModelChange}
                getRowId={(row) => row.userId}
              />
            </Box>
          </Box>
        </Card>
      </Container>
    </Protected>
  );
};

export default FeatureAnalyticsPage;
