import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify";
//
import AccountPopover from "./AccountPopover";
import {
  selectEnvironment,
  setSelectedEnvironment,
} from "../../../redux/slices/environment.slice";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  // const env = useSelector(selectEnvironment);

  // const dispatch = useDispatch();

  // const handleChange = (event) => {
  //   dispatch(setSelectedEnvironment(event.target.value));
  //   localStorage.setItem("env", event.target.value);
  //   window.location.reload();
  // };

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
          aria-label="Menu"
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }}>
          {/* {
            <FormControl sx={{ minWidth: 120 }} variant="standard">
            <InputLabel id="env">Environment</InputLabel>
            <Select
              id="demo-simple-select"
              value={env}
              label="Environment"
              labelId="env"
              name="env"
              onChange={handleChange}
              inputProps={{
                "data-testid": "env-select",
              }}
            >
              <MenuItem value="live">Live</MenuItem>
              <MenuItem value="beta">Beta</MenuItem>
            </Select>
          </FormControl>} */}
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <LanguagePopover />
          <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
