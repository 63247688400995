import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  TextField,
  IconButton,
  Box,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import {
  useChangeMobileNumberMutation,
  useGenerateOtpMutation,
} from "../../../redux/services/user.service";
import { ChangeMobileSchema } from "../../../forms-schema/form.schema";

ChangeMobileForm.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  openRow: PropTypes.object,
};

export default function ChangeMobileForm({ open, onClose, openRow }) {
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [generatedOtp, setGeneratedOtp] = useState(""); // New state to store generated OTP

  const [changeMobileNumber, { isLoading }] = useChangeMobileNumberMutation();
  const [genOtp, { isLoading: isOtpLoading }] = useGenerateOtpMutation();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(ChangeMobileSchema(isOtpSent)),
  });

  useEffect(() => {
    reset();
    setIsOtpSent(false);
    setGeneratedOtp(""); // Reset OTP on component open
  }, [open, reset]);

  const handleGenerateOtp = async (values) => {
    const resultAction = await genOtp({
      oldMobileNumber: openRow?.contactNumber,
      newMobileNumber: values.newMobileNumber,
    });

    if (resultAction?.data?.success) {
      setIsOtpSent(true);
      setGeneratedOtp(resultAction?.data?.data?.otp); // Store generated OTP
    }
  };

  const handleResendOtp = async () => {
    const resultAction = await genOtp({
      oldMobileNumber: openRow?.contactNumber,
      newMobileNumber: getValues("newMobileNumber"),
    });

    if (resultAction?.data?.success) {
      setGeneratedOtp(resultAction?.data?.otp); // Update OTP when resent
    }
  };

  const handleClick = async (values) => {
    const resultAction = await changeMobileNumber({
      ...values,
      otp: +values.otp,
      oldMobileNumber: openRow?.contactNumber,
    });
    if (resultAction?.data?.success) {
      onClose();
      reset();
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">
                  Change Mobile Number
                </Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form
              onSubmit={
                isOtpSent
                  ? handleSubmit(handleClick)
                  : handleSubmit(handleGenerateOtp)
              }
            >
              <Stack spacing={3}>
                <TextField
                  name="newMobileNumber"
                  label="New Mobile Number"
                  {...register("newMobileNumber", { required: true })}
                  error={!!errors.newMobileNumber}
                  helperText={errors.newMobileNumber?.message}
                  inputProps={{ "data-testid": "newMobileNumber" }}
                  disabled={isOtpSent}
                />

                {isOtpSent && (
                  <TextField
                    name="otp"
                    label="OTP"
                    {...register("otp")}
                    error={!!errors.otp}
                    helperText={errors.otp?.message}
                    inputProps={{ "data-testid": "otp" }}
                  />
                )}

                {isOtpSent && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ my: 1 }}
                  >
                    <LoadingButton
                      variant="text"
                      size="small"
                      onClick={handleResendOtp}
                      loading={isOtpLoading}
                    >
                      Resend OTP
                    </LoadingButton>
                  </Stack>
                )}

                {/* Display OTP below the OTP field in a read-only TextField */}
                {isOtpSent && generatedOtp && (
                  <TextField
                    label="Generated OTP"
                    value={generatedOtp} // Display the OTP here
                    // disabled
                    inputProps={{ "data-testid": "generatedOtp" }}
                  />
                )}

                {!isOtpSent && (
                  <LoadingButton
                    loading={isOtpLoading}
                    type="submit"
                    variant="contained"
                  >
                    Generate OTP
                  </LoadingButton>
                )}

                {isOtpSent && (
                  <LoadingButton
                    loading={isLoading}
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isOtpLoading}
                  >
                    Submit
                  </LoadingButton>
                )}
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
