import React from "react";
import { EditorProvider } from "@tiptap/react";
import PropTypes from "prop-types";
import extensions from "./TipTapExtensions";
import MenuBar from "./MenuBar";

TipTapEditor.propTypes = {
  content: PropTypes.string,
  onUpdate: PropTypes.func,
};

function TipTapEditor({ content, onUpdate }) {
  return (
    <div className="tiptap">
      <EditorProvider
        slotBefore={<MenuBar />}
        extensions={extensions}
        content={content}
        onUpdate={onUpdate}
        editorProps={{
          attributes: {
            "data-testid": "description-editor",
          },
        }}
      />
    </div>
  );
}

export default TipTapEditor;
