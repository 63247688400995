import { configureStore } from "@reduxjs/toolkit";
import { userApi } from "./services/user.service";
import authSlice, { authSliceMiddleware } from "./slices/auth.slice";
import { authApi } from "./services/auth.service";
import { organizationApi } from "./services/organization.service";
import { deviceApi } from "./services/device.service";
import { usageApi } from "./services/usage.service";
import { googleAnalyticsApi } from "./services/googleAnalytics.service";
import { supportedDeviceApi } from "./services/supportedDevice.service";
import { languageApi } from "./services/language.service";
import tableFilterSlice from "./slices/tableFilter.slice";
import { countryApi } from "./services/country.service";
import toastSlice from "./slices/toast.slice";
import { uploadApi } from "./services/upload.service";
import { orderApi } from "./services/order.service";
import { blogApi } from "./services/blog.service";
import { authUserApi } from "./services/authUser.service";
import { permissionApi } from "./services/permission.service";
import { roleApi } from "./services/role.service";
import { supportedLanguagesApi } from "./services/supportedLanguages.service";
import caslSlice from "./slices/casl.slice";
import { offerApi } from "./services/offer.service";
import environmentSlice from "./slices/environment.slice";
import dashboardSlice from "./slices/dashboard.slice";
import { columnAccessControlApi } from "./services/columnAccessControl.service";
import { plansApi } from "./services/plans.service";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    tableFilter: tableFilterSlice,
    toast: toastSlice,
    casl: caslSlice,
    environment: environmentSlice,
    dashboard: dashboardSlice,
    [usageApi.reducerPath]: usageApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [plansApi.reducerPath]: plansApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [offerApi.reducerPath]: offerApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [deviceApi.reducerPath]: deviceApi.reducer,
    [googleAnalyticsApi.reducerPath]: googleAnalyticsApi.reducer,
    [supportedDeviceApi.reducerPath]: supportedDeviceApi.reducer,
    [languageApi.reducerPath]: languageApi.reducer,
    [countryApi.reducerPath]: countryApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [authUserApi.reducerPath]: authUserApi.reducer,
    [permissionApi.reducerPath]: permissionApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
    [supportedLanguagesApi.reducerPath]: supportedLanguagesApi.reducer,
    [columnAccessControlApi.reducerPath]: columnAccessControlApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ["casl.ability"],
        // ignoreActions: [permissionApi.endpoints.getPermissions],
      },
    }).concat(
      authSliceMiddleware,
      userApi.middleware,
      authApi.middleware,
      offerApi.middleware,
      plansApi.middleware,
      organizationApi.middleware,
      deviceApi.middleware,
      usageApi.middleware,
      googleAnalyticsApi.middleware,
      supportedDeviceApi.middleware,
      languageApi.middleware,
      countryApi.middleware,
      uploadApi.middleware,
      orderApi.middleware,
      blogApi.middleware,
      authUserApi.middleware,
      permissionApi.middleware,
      roleApi.middleware,
      supportedLanguagesApi.middleware,
      columnAccessControlApi.middleware,
    ),
});
