import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useSelector } from "react-redux";
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  CircularProgress,
} from "@mui/material";
// components
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import Protected from "../protected/Protected";
import {
  useGetLanguagesQuery,
  useGetWordsOfLanguageQuery,
} from "../redux/services/language.service";
import AddLanguageForm from "../sections/@dashboard/words/languages.form";
import AddWordsForm from "../sections/@dashboard/words/words.form";
import CustomTable from "../components/data-table/CustomDataTable";
import { selectAbility } from "../redux/slices/casl.slice";

export default function WordsPage() {
  const ability = useSelector(selectAbility);

  const {
    isLoading: isLanguagesLoading,
    isError: isLanguagesError,
    data: languages,
  } = useGetLanguagesQuery();

  const [selectedLanguage, setSelectedLanguage] = useState("hi");

  const [openLanguageForm, setOpenLanguageForm] = useState(false);

  const [openWordForm, setOpenWordForm] = useState(false);

  const handleOpenLanguageForm = () => {
    setOpenLanguageForm(true);
    setOpenWordForm(false);
  };

  const handleOpenWordForm = () => {
    setOpenWordForm(true);
    setOpenLanguageForm(false);
  };

  return (
    <Protected>
      <Helmet>
        <title> Words </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Words
          </Typography>
          <Container sx={{ display: "flex", justifyContent: "flex-end" }}>
            {ability.can("post", "English-Words") && (
              <Button
                variant="contained"
                onClick={handleOpenWordForm}
                startIcon={<Iconify icon="eva:plus-fill" />}
                sx={{ mr: 2 }}
              >
                New Words
              </Button>
            )}
            {ability.can("post", "Language") && (
              <Button
                variant="contained"
                onClick={handleOpenLanguageForm}
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Language
              </Button>
            )}
          </Container>
          {ability.can("post", "Language") && (
            <AddLanguageForm
              open={openLanguageForm}
              onClose={() => {
                setOpenLanguageForm(false);
              }}
              setOpen={setOpenLanguageForm}
            />
          )}
          {ability.can("post", "English-Words") && (
            <AddWordsForm
              open={openWordForm}
              onClose={() => {
                setOpenWordForm(false);
              }}
              setOpen={setOpenWordForm}
            />
          )}
        </Stack>

        <Card>
          <Scrollbar>
            <CustomTable
              tableName="Words"
              searchLabel="Search Word..."
              useDataQuery={useGetWordsOfLanguageQuery}
              dataQueryOptions={{
                skip: !selectedLanguage,
              }}
              defaultQueryParams={{
                languageCode: selectedLanguage,
              }}
              canSelect={false}
              extraHeaderField={
                ability.can("get", "Languages") && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginLeft: 2,
                    }}
                  >
                    <FormControl sx={{ minWidth: 200 }}>
                      <InputLabel id="demo-simple-select-label">
                        Select Language
                      </InputLabel>
                      <Select
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                        sx={{ textTransform: "capitalize" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Language"
                        defaultValue={"hi"}
                        disabled={isLanguagesError}
                      >
                        {isLanguagesLoading && (
                          <MenuItem
                            sx={{
                              textTransform: "capitalize",
                              width: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            value={""}
                          >
                            <CircularProgress size={20} />
                          </MenuItem>
                        )}
                        {!isLanguagesLoading &&
                          !isLanguagesError &&
                          languages?.data
                            ?.filter((lang) => lang.code !== "en")
                            .map((lang) => (
                              <MenuItem
                                sx={{
                                  textTransform: "capitalize",
                                }}
                                key={lang.code}
                                value={lang.code}
                              >
                                {lang.name}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Box>
                )
              }
            />
          </Scrollbar>
        </Card>
      </Container>
    </Protected>
  );
}
