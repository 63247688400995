import { createApi } from '@reduxjs/toolkit/query/react'
import { extendedBaseQuery } from '../basequery'

export const usageApi = createApi({
  reducerPath: 'usageApi',
  baseQuery: extendedBaseQuery,
  endpoints: builder => ({
    getUsage: builder.query({
      query: () => ({
        url: 'usage/analytics',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetUsageQuery } = usageApi
