import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useSelector } from "react-redux";
// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
// components
import { useForm } from "react-hook-form";
import { Edit } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Scrollbar from "../components/scrollbar";
// sections
import Protected from "../protected/Protected";
import CustomTable from "../components/data-table/CustomDataTable";
import { selectAbility } from "../redux/slices/casl.slice";
import {
  useGetColumnsQuery,
  useGetEntitiesQuery,
} from "../redux/services/columnAccessControl.service";
import ColumnAccessControlForm from "../sections/@dashboard/column-access-control/column-access-control.form";
import { useGetRolesQuery } from "../redux/services/role.service";
import Iconify from "../components/iconify";

export default function ColumnAccessControlPage() {
  const ability = useSelector(selectAbility);

  const {
    isLoading: isEntitiesLoading,
    isError: isEntitiesError,
    data: entities,
  } = useGetEntitiesQuery();

  const {
    isLoading: isRolesLoading,
    isError: isRolesError,
    data: roles,
  } = useGetRolesQuery();

  const [openColumnForm, setOpenColumnForm] = useState(false);

  const [openRow, setOpenRow] = useState(null);

  const [edit, setEdit] = useState(false);

  const [entityName, setEntityName] = useState("");

  const [roleId, setRoleId] = useState("");

  return (
    <Protected>
      <Helmet>
        <title>Column Access Control</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Column Access Control
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            {ability.can("post", "Column-Access-Control") && (
              <Button
                variant="contained"
                onClick={() => {
                  setOpenColumnForm(true);
                }}
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Column
              </Button>
            )}
          </Box>
          {(ability.can("patch", "Column-Access-Control") ||
            ability.can("post", "Column-Access-Control")) && (
            <ColumnAccessControlForm
              open={openColumnForm}
              onClose={() => {
                setOpenColumnForm(false);
                setOpenRow(null);
                setEdit(false);
              }}
              edit={edit}
              openRow={openRow}
            />
          )}
        </Stack>

        <Card>
          <Scrollbar>
            <CustomTable
              tableName="Column Access Control"
              searchLabel="Search Column..."
              useDataQuery={useGetColumnsQuery}
              defaultQueryParams={{
                entityName,
                roleId,
              }}
              resetParentForm={() => {
                setEntityName("");
                setRoleId("");
              }}
              canSelect={false}
              extraHeaderField={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    width: "100%",
                    marginLeft: 2,
                  }}
                >
                  <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel id="entity-select-label">
                      Select Entity
                    </InputLabel>
                    <Select
                      sx={{ textTransform: "capitalize" }}
                      labelId="entity-select-label"
                      label="Select Entity"
                      disabled={isEntitiesError}
                      value={entityName}
                      onChange={(e) => {
                        setEntityName(e.target.value);
                      }}
                      inputProps={{ "data-testid": "columnAccessEntityName" }}
                    >
                      {/* <MenuItem value={""}>
                        <em>None</em>
                      </MenuItem> */}
                      {isEntitiesLoading && (
                        <MenuItem
                          sx={{
                            textTransform: "capitalize",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          value={""}
                        >
                          <CircularProgress size={20} />
                        </MenuItem>
                      )}
                      {!isEntitiesLoading &&
                        !isEntitiesError &&
                        entities?.data
                          ?.toSorted((a, b) => a?.name.localeCompare(b?.name))
                          ?.map((entity) => (
                            <MenuItem
                              sx={{
                                textTransform: "capitalize",
                              }}
                              key={entity.name}
                              value={entity.name}
                            >
                              {entity.name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel id="roleId">Role</InputLabel>
                    <Select
                      label="Role"
                      labelId="roleId"
                      inputProps={{ "data-testid": "columnAccessRoleId" }}
                      value={roleId}
                      onChange={(e) => {
                        setRoleId(e.target.value);
                      }}
                      disabled={isRolesError}
                    >
                      <MenuItem value={""}>
                        <em>None</em>
                      </MenuItem>
                      {isRolesLoading && (
                        <MenuItem
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          disabled
                        >
                          <CircularProgress size={20} />
                        </MenuItem>
                      )}
                      {!isRolesLoading &&
                        !isRolesError &&
                        roles?.data
                          ?.toSorted((a, b) => a?.name.localeCompare(b?.name))
                          ?.map((role) => (
                            <MenuItem key={role?.id} value={role?.id}>
                              {role?.name}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl>
                </Box>
              }
              actions={
                ability.can("patch", "Column-Access-Control")
                  ? {
                      field: "actions",
                      headerName: "Action",
                      type: "actions",
                      getActions: ({ row }) => [
                        <GridActionsCellItem
                          key="edit"
                          icon={<Edit />}
                          label="Edit"
                          showInMenu
                          onClick={() => {
                            setOpenRow(row);
                            setOpenColumnForm(true);
                            setEdit(true);
                          }}
                        />,
                      ],
                    }
                  : null
              }
            />
          </Scrollbar>
        </Card>
      </Container>
    </Protected>
  );
}
