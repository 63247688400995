import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { alpha, styled, useMediaQuery } from '@mui/material'
import { fShortenNumber } from '../../../utils/formatNumber'

// ----------------------------------------------------------------------
const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}))

export default function AppWidgetNew ({ title, total, icon, color = 'primary', sx, ...other }) {
  
  const mediumDisp = useMediaQuery(theme => theme.breakpoints.up('md'))
  const extraLargeDisp = useMediaQuery(theme => theme.breakpoints.up('xl'))
  const isLarge = mediumDisp && !extraLargeDisp

  return (
    <Card
      component={Stack}
      spacing={2}
      direction='row'
      sx={{
        px: 3,
        py: 5,
        borderRadius: 2,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: isLarge ? 'center' : 'flex-start',
        flexDirection: isLarge ? 'column' : 'row',
        ...sx,
      }}
      data-testid='card'
      {...other}
    >
      {/* {icon && <Box sx={{ width: 64, height: 64 }}>{icon}</Box>} */}
      <StyledIcon
        sx={{
          // color: (theme) => theme.palette[color].dark,
          backgroundImage: theme =>
            `linear-gradient(330deg, ${alpha(theme.palette[color]?.dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.4
            )} 100%)`,
          margin: 0,
          height: 64,
        }}
      >
        {/* <Iconify icon={icon} width={24} height={24} /> */}
        {icon}
      </StyledIcon>

      <Stack
        spacing={0.5}
        sx={{
          textAlign: isLarge ? 'center' : 'left',
          marginLeft: isLarge ? '0 !important' : '16px !important',
          marginTop: isLarge ? '8px !important' : '0 !important'
        }}
      >
        <Typography
          variant='h4'
          sx={{
            textTransform: 'uppercase',
          }}
          data-testid={`${title}-number`}
        >
          {fShortenNumber(total)}
        </Typography>

        <Typography variant='subtitle2' sx={{ color: 'text.disabled' }}>
          {title}
        </Typography>
      </Stack>
    </Card>
  )
}

AppWidgetNew.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.number,
}
