import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Autocomplete,
  Chip,
  Divider,
  Box,
} from "@mui/material";
// components
import {
  Assistant,
  CameraEnhance,
  CurrencyRupeeOutlined,
  DocumentScanner,
  Person,
  SearchOutlined,
  Sync,
  TextFieldsOutlined,
  VerifiedUser,
  Visibility,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  useGetGoogleAnalyticsByRangeQuery,
  useGetGoogleAnalyticsQuery,
} from "../redux/services/googleAnalytics.service";
// sections
import {
  LanguageChart,
  UsageChart,
  SubscribedChart,
  AppWidgetNew,
  SignUpUserChart,
} from "../sections/@dashboard/app";
import { useGetOrganizationsQuery } from "../redux/services/organization.service";
import { selectAbility } from "../redux/slices/casl.slice";
import {
  resetDashboardFilters,
  selectDashboardFilters,
  setDashboardFilters,
} from "../redux/slices/dashboard.slice";
import { useGetEventPlacesQuery } from "../redux/services/device.service";

export default function DashboardAppPage() {
  const theme = useTheme();

  const ability = useSelector(selectAbility);

  const [duration, setDuration] = useState("weekly");

  const [refresh, setRefresh] = useState(false);

  const [custom, setCustom] = useState(false);

  const [graphAllData, setGraphAllData] = useState({});

  const [graph1Data, setGraph1Data] = useState([]);

  const [graph2Data, setGraph2Data] = useState([]);

  const [graph3Data, setGraph3Data] = useState([]);

  const [graph4Data, setGraph4Data] = useState([]);

  const [graph5Data, setGraph5Data] = useState([]);

  const [startDate, setStartDate] = useState(
    dayjs(new Date()?.toISOString()?.split("T")?.[0])?.subtract(6, "day"),
  );

  const [endDate, setEndDate] = useState(
    dayjs(new Date()?.toISOString()?.split("T")?.[0]),
  );

  const [customStartDate, setCustomStartDate] = useState(
    startDate?.format("YYYY-MM-DD") || "",
  );

  const [customEndDate, setCustomEndDate] = useState(
    endDate?.format("YYYY-MM-DD") || "",
  );

  // const [eevntPlaces, setEeventPlaces] = useState([]);
  const [selectedEventPlace, setSelectedEventPlace] = useState("");
  const [filteredTags, setFilteredTags] = useState([]); // State to store filtered tags

  const dashboardFilter = useSelector(selectDashboardFilters);

  const dispatch = useDispatch();

  const { register, handleSubmit, watch, setValue, reset } = useForm();

  const {
    isLoading: isLoadingOrgs,
    isSuccess: isSuccessOrgs,
    isError: isErrorOrgs,
    data: organizationsData,
  } = useGetOrganizationsQuery(null, {
    skip: !ability.can("get", "Organizations"),
  });

  const {
    data: graphData,
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetGoogleAnalyticsQuery(
    { refresh, ...dashboardFilter },
    {
      skip: custom,
    },
  );

  const {
    data: graphDataByRange,
    isSuccess: isSuccessByRange,
    isLoading: isLoadingByRange,
    isError: isErrorByRange,
    error: errorByRange,
    isFetching: isFetchingByRange,
    // refetch,
  } = useGetGoogleAnalyticsByRangeQuery(
    {
      startDate: customStartDate,
      endDate: customEndDate,
      ...dashboardFilter,
    },
    {
      skip: !custom,
    },
  );

  const {
    data: eventPlaces,
    isSuccess: isSuccessEventPlaces,
    isLoading: isLoadingEventPlaces,
  } = useGetEventPlacesQuery({
    organizationId: watch("organizationId"),
  });

  const handleEventPlaceChange = (event) => {
    try {
      const selectedPlace = event.target.value;
      setSelectedEventPlace(selectedPlace);

      // Filter tags based on selected event place
      const tags =
        eventPlaces?.data
          .filter(
            (event) => event.eventPlace === selectedPlace && event.tag !== null,
          )
          .map((event) => event.tag) || [];

      setFilteredTags(tags);

      // Reset the tag field (clear the value)
      setValue("tag", ""); // This will clear the tag field
    } catch (error) {
      // Catch and log any errors
      console.error("Error while handling event place change:", error);
    }
  };

  useEffect(() => {
    setValue("organizationId", dashboardFilter?.organizationId ?? "");
    setValue("distributorId", dashboardFilter?.distributorId ?? "");
    setValue("eventPlace", dashboardFilter?.eventPlace ?? "");
    setValue("tag", dashboardFilter?.tag ?? "");
    setValue("deviceCreationDates", dashboardFilter?.deviceCreationDates ?? []);
    setValue("cameraType", dashboardFilter?.cameraType ?? "");
    setValue("userType", dashboardFilter?.userType ?? "");
  }, [dashboardFilter, setValue]);

  useEffect(() => {
    if (!custom && isSuccess && graphData?.success) {
      setGraphAllData(graphData?.data);
      setGraph1Data(graphData?.data?.graph1?.[duration]);
      setGraph2Data(graphData?.data?.graph2?.[duration]);
      setGraph3Data(graphData?.data?.graph3?.[duration]);
      setGraph4Data(graphData?.data?.graph4);
      setGraph5Data(graphData?.data?.graph5?.[duration]);
    } else if (custom && isSuccessByRange && graphDataByRange?.success) {
      setGraphAllData(graphDataByRange?.data);
      setGraph1Data(graphDataByRange?.data?.graph1);
      setGraph2Data(graphDataByRange?.data?.graph2);
      setGraph3Data(graphDataByRange?.data?.graph3);
      setGraph4Data(graphDataByRange?.data?.graph4);
      setGraph5Data(graphDataByRange?.data?.graph5);
    }
  }, [
    graphData,
    duration,
    isSuccess,
    custom,
    graphDataByRange,
    isSuccessByRange,
  ]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const fetchData = () => {
    setRefresh(true);
  };

  const onSubmit = (data) => {
    dispatch(
      setDashboardFilters({
        ...data,
      }),
    );

    setCustomStartDate(startDate?.format("YYYY-MM-DD"));
    setCustomEndDate(endDate?.format("YYYY-MM-DD"));
  };

  const handleResetFilters = () => {
    dispatch(resetDashboardFilters());
    reset({
      organizationId: "",
      distributorId: "",
      eventPlace: "",
      tag: "",
      deviceCreationDates: [],
      cameraType: "",
      userType: "",
    });
    setSelectedEventPlace("");
    setFilteredTags([]);
    setCustomStartDate(startDate?.format("YYYY-MM-DD"));
    setCustomEndDate(endDate?.format("YYYY-MM-DD"));
  };

  const userType = watch("userType");
  const organizationId = watch("organizationId");

  // Condition to hide organization dropdown if userType is "freeUsers"
  const shouldHideOrganization = userType === "freeUsers";

  // Condition to remove "Free Users" from userType dropdown if an organization is selected
  const shouldShowFreeUsers = organizationId === ""; // Show Free Users only when no organization is selected

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container mb={5}>
          <Grid xl={4}>
            <Typography variant="h4">Hi, Welcome back</Typography>
          </Grid>
          <Grid xl={8} display={"flex"} justifyContent={"flex-end"}>
            <Button
              style={{ marginRight: theme.spacing(1) }}
              variant={
                !custom && duration === "weekly" ? "contained" : "outlined"
              }
              type="button"
              id="weekly"
              onClick={() => {
                setDuration("weekly");
                setCustom(false);
              }}
            >
              1W
            </Button>
            <Button
              style={{ marginRight: theme.spacing(1) }}
              variant={
                !custom && duration === "monthly" ? "contained" : "outlined"
              }
              type="button"
              id="monthly"
              onClick={() => {
                setDuration("monthly");
                setCustom(false);
              }}
            >
              1M
            </Button>
            <Button
              style={{ marginRight: theme.spacing(1) }}
              variant={
                !custom && duration === "yearly" ? "contained" : "outlined"
              }
              type="button"
              id="yearly"
              onClick={() => {
                setDuration("yearly");
                setCustom(false);
              }}
            >
              1Y
            </Button>
            <Button
              style={{ marginRight: theme.spacing(1) }}
              variant={custom ? "contained" : "outlined"}
              type="button"
              id="all"
              onClick={() => {
                setDuration("weekly");
                setCustom(true);
              }}
            >
              Custom
            </Button>
          </Grid>
        </Grid>

        {custom && (
          <>
            <Grid
              container
              sx={{
                marginBottom: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              md={6}
              spacing={3}
            >
              <Grid item md={6}>
                <DatePicker
                  maxDate={dayjs(endDate).subtract(6, "day")}
                  // {...register("startDate")}
                  label="Start Date"
                  format="DD-MM-YYYY"
                  disableFuture
                  value={startDate}
                  onChange={handleStartDateChange}
                  // renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item md={6}>
                <DatePicker
                  minDate={dayjs(startDate).add(6, "day")}
                  // {...register("endDate")}
                  label="End Date"
                  format="DD-MM-YYYY"
                  disableFuture
                  value={endDate}
                  onChange={handleEndDateChange}
                  // renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>

            <Divider
              sx={{
                marginBottom: 2,
              }}
            />
          </>
        )}

        <form>
          <Grid container spacing={2} mb={2}>
            {!shouldHideOrganization && ability.can("get", "Organizations") && (
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="organization-label">
                    Select Organization
                  </InputLabel>
                  <Select
                    labelId="organization-label"
                    id="demo-simple-select"
                    {...register("organizationId")}
                    label="Select Organization"
                    value={organizationId ?? ""}
                    inputProps={{
                      "data-testid": "organizationId",
                    }}
                    onChange={(e) => {
                      setValue("organizationId", e.target.value);
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {!isLoadingOrgs &&
                      isSuccessOrgs &&
                      !isErrorOrgs &&
                      organizationsData?.data
                        ?.toSorted((a, b) => a?.name?.localeCompare(b?.name))
                        ?.map((org) => (
                          <MenuItem key={org.id} value={org.id}>
                            {org.name}
                          </MenuItem>
                        ))}
                    {isLoadingOrgs && (
                      <MenuItem>
                        <CircularProgress />
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {/* UserType Organization */}
            {ability.can("Is", "organization") && (
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="userType-label">User Type</InputLabel>
                  <Select
                    labelId="userType-label"
                    id="demo-simple-select"
                    {...register("userType")}
                    label="User Type"
                    value={watch("userType") ?? ""}
                    inputProps={{ "data-testid": "userType" }}
                  >
                    <MenuItem value="all">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value="device">Device Users</MenuItem>
                    <MenuItem value="subscription">Premium Users</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            {ability.can("get", "All-Event-Place") && (
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="eventPlace-label">Event Place</InputLabel>
                  <Select
                    labelId="eventPlace-label"
                    id="demo-simple-select"
                    {...register("eventPlace")} // Register eventPlace
                    label="Event Place"
                    value={selectedEventPlace}
                    onChange={handleEventPlaceChange}
                    inputProps={{ "data-testid": "eventPlace" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {isLoadingEventPlaces && (
                      <MenuItem>
                        <CircularProgress size={24} />
                      </MenuItem>
                    )}
                    {isSuccessEventPlaces &&
                      eventPlaces?.data?.map((eventPlace, index) => (
                        <MenuItem
                          key={`${eventPlace.eventPlace}-${index}`}
                          value={eventPlace.eventPlace}
                        >
                          {eventPlace.eventPlace}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="tag-label">Tag</InputLabel>
                <Select
                  labelId="tag-label"
                  id="tag-select"
                  {...register("tag")} // Register tag
                  label="Tag"
                  value={watch("tag") ?? ""}
                  disabled={filteredTags.length === 0} // Disable tag select if no tags are available
                  inputProps={{ "data-testid": "tag" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {filteredTags.length > 0 ? (
                    filteredTags.map((tag, index) => (
                      <MenuItem key={index} value={tag}>
                        {tag}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No tags available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            {(ability.can("Is", "admin") || ability.can("Is", "support")) && (
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="cameraType-label">Camera Type</InputLabel>
                  <Select
                    labelId="cameraType-label"
                    id="demo-simple-select"
                    {...register("cameraType")}
                    label="Camera Type"
                    value={watch("cameraType") ?? ""}
                    inputProps={{ "data-testid": "cameraType" }}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value="HARDWARE">Mobile</MenuItem>
                    <MenuItem value="USB">Smarton Device</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            {(ability.can("Is", "admin") || ability.can("Is", "support")) && (
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="userType-label">User Type</InputLabel>
                  <Select
                    labelId="userType-label"
                    id="demo-simple-select"
                    {...register("userType")}
                    label="User Type"
                    value={userType ?? ""}
                    inputProps={{ "data-testid": "userType" }}
                  >
                    <MenuItem value="all">
                      <em>All</em>
                    </MenuItem>
                    {shouldShowFreeUsers && (
                      <MenuItem value="freeUsers">Free Users</MenuItem>
                    )}
                    <MenuItem value="subscription">Premium Users</MenuItem>
                    <MenuItem value="device">Device Users</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>

          <Box
            sx={{
              marginBottom: 2,
              gap: 2,
              display: "flex",
            }}
          >
            <LoadingButton
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </LoadingButton>
            <Button
              variant="outlined"
              color="error"
              onClick={handleResetFilters}
            >
              Reset
            </Button>
          </Box>
        </form>

        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            alignItems: "stretch",
          }}
          data-testid="dashboard-app-page"
        >
          {(isFetching ||
            isLoading ||
            isLoadingByRange ||
            isFetchingByRange) && (
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress data-testid="graph-loading" />
            </Grid>
          )}

          {isError && (
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" color="error">
                No Data Found, Please Try Again
              </Typography>
            </Grid>
          )}

          {isErrorByRange && (
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" color="error">
                {errorByRange.error}
              </Typography>
            </Grid>
          )}

          {!isError && !isErrorByRange && (
            <>
              <Grid container lg={12} sx={{ marginTop: 2 }} spacing={1}>
                {graphAllData?.totalDevices > -1 && (
                  <Grid item xs={12} sm={6} md={4} data-testid="count-card">
                    <AppWidgetNew
                      title="Total Devices"
                      total={graphAllData?.totalDevices || 0}
                      icon={<Person />}
                    />
                  </Grid>
                )}

                {graph2Data?.totalActiveUsers > -1 && (
                  <Grid item xs={12} sm={6} md={4} data-testid="count-card">
                    <AppWidgetNew
                      title="Total Active Users Count"
                      total={graph2Data?.totalActiveUsers}
                      icon={<Person />}
                      sx={{ height: "100%" }}
                    />
                  </Grid>
                )}
                {graphAllData?.totalUsers > -1 && (
                  <Grid item xs={12} sm={6} md={4} data-testid="count-card">
                    <AppWidgetNew
                      title="Total Users"
                      total={graphAllData?.totalUsers || 0}
                      icon={<Person />}
                    />
                  </Grid>
                )}

                {graphAllData?.activeUsers > -1 && (
                  <Grid item xs={12} sm={6} md={4} data-testid="count-card">
                    <AppWidgetNew
                      title="Active Users"
                      total={graphAllData?.activeUsers || 0}
                      icon={<VerifiedUser />}
                    />
                  </Grid>
                )}

                {/* {graph2Data?.totalImagesCount > -1 && (
                  <Grid item xs={12} sm={6} md={4} data-testid="count-card">
                    <AppWidgetNew
                      title="Total Images Captured"
                      total={graph2Data?.totalImagesCount}
                      color="success"
                      icon={<CameraEnhance />}
                      sx={{ height: "100%" }}
                    />
                  </Grid>
                )} */}

                {graph2Data?.totalObjectCount > -1 && (
                  <Grid item xs={12} sm={6} md={4} data-testid="count-card">
                    <AppWidgetNew
                      title="Total Object Detected"
                      total={graph2Data?.totalObjectCount}
                      color="warning"
                      icon={<SearchOutlined />}
                    />
                  </Grid>
                )}

                {graph2Data?.totalWordCount > -1 && (
                  <Grid item xs={12} sm={6} md={4} data-testid="count-card">
                    <AppWidgetNew
                      title="Total Words Read"
                      total={graph2Data?.totalWordCount}
                      color="info"
                      icon={<TextFieldsOutlined />}
                    />
                  </Grid>
                )}

                {graph2Data?.totalCurrencyCount > -1 && (
                  <Grid item xs={12} sm={6} md={4} data-testid="count-card">
                    <AppWidgetNew
                      title="Total Currency Detected"
                      total={graph2Data?.totalCurrencyCount}
                      color="success"
                      icon={<CurrencyRupeeOutlined />}
                    />
                  </Grid>
                )}

                {graph2Data?.totalDocAICount > -1 && (
                  <Grid item xs={12} sm={6} md={4} data-testid="count-card">
                    <AppWidgetNew
                      title="Total DocAI Count"
                      total={graph2Data?.totalDocAICount}
                      color="info"
                      icon={<DocumentScanner />}
                      sx={{ height: "100%" }}
                    />
                  </Grid>
                )}

                {graph2Data?.totalSmartEyeCount > -1 && (
                  <Grid item xs={12} sm={6} md={4} data-testid="count-card">
                    <AppWidgetNew
                      title="Total SmartEye Count"
                      total={graph2Data?.totalSmartEyeCount}
                      color="warning"
                      icon={<Visibility />}
                      sx={{ height: "100%" }}
                    />
                  </Grid>
                )}

                {graph2Data?.totalAIChatCount > -1 && (
                  <Grid item xs={12} sm={6} md={4} data-testid="count-card">
                    <AppWidgetNew
                      title="Total AI Tutor Count"
                      total={graph2Data?.totalAIChatCount}
                      icon={<Assistant />}
                      sx={{ height: "100%" }}
                    />
                  </Grid>
                )}
              </Grid>

              {graph1Data?.length > 0 && (
                <Grid
                  item
                  xs={12}
                  md={8}
                  lg={8}
                  sx={{
                    height: "100%",
                  }}
                >
                  <UsageChart chartData={graph1Data} duration={duration} />
                </Grid>
              )}

              {graph3Data && Object.entries(graph3Data)?.length > 0 && (
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  sx={{
                    height: "100%",
                  }}
                >
                  <LanguageChart
                    chartData={Object.entries(graph3Data)?.map(
                      ([label, val]) => ({
                        label,
                        value: val?.totalCount,
                      }),
                    )}
                    duration={duration}
                  />
                </Grid>
              )}

              {(ability.can("Is", "admin") || ability.can("Is", "support")) &&
                graph4Data &&
                Object.entries(graph4Data)?.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    sx={{
                      height: "100%",
                    }}
                  >
                    <SubscribedChart
                      chartData={graph4Data}
                      duration={duration}
                    />
                  </Grid>
                )}

              {(ability.can("Is", "admin") || ability.can("Is", "support")) &&
                graph5Data?.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    md={8}
                    lg={8}
                    sx={{
                      height: "100%",
                    }}
                  >
                    <SignUpUserChart
                      chartData={graph5Data}
                      duration={duration}
                    />
                  </Grid>
                )}
            </>
          )}
        </Grid>
      </Container>
    </LocalizationProvider>
  );
}
