import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

CustomDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.any,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  isLoading: PropTypes.bool,
  sx: PropTypes.object,
  cancelButton: PropTypes.bool,
  confirmTextColor: PropTypes.string,
};

function CustomDialog({
  open,
  onClose,
  onConfirm,
  title = "Title",
  description = "Description",
  confirmText = "Confirm",
  confirmTextColor = "primary",
  cancelText = "Cancel",
  cancelButton = true,
  isLoading = false,
  sx,
  ...props
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        ...sx,
      }}
      {...props}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelButton && (
          <LoadingButton onClick={onClose} color="primary">
            {cancelText}
          </LoadingButton>
        )}
        <LoadingButton
          onClick={onConfirm}
          autoFocus
          color={confirmTextColor}
          loading={isLoading}
        >
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDialog;
