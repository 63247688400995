//----------------------------------------------------------------------
import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Container,
  Typography,
  Button,
  MenuItem,
  Stack,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  Grid,
} from "@mui/material";

import { Edit, LinkOff } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { GridActionsCellItem } from "@mui/x-data-grid";
import CustomTable from "../components/data-table/CustomDataTable";
import {
  useDelinkUserMutation,
  useGetUsersQuery,
} from "../redux/services/user.service";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";

import Protected from "../protected/Protected";
import LinkCodeForm from "../sections/@dashboard/user/linkCode.form";
import BulkAddUserForm from "../sections/@dashboard/user/bulkAddUser.form";
import UserForm from "../sections/@dashboard/user/user.form";
import useResponsive from "../hooks/useResponsive";
import { useGetOrganizationsQuery } from "../redux/services/organization.service";
import { selectAbility } from "../redux/slices/casl.slice";
import ChangeMobileForm from "../sections/@dashboard/user/changeMobile.form";
import CustomDeleteConfirmation from "../components/dialog/CustomDeleteConfirmation";
import { useGetEventPlacesQuery } from "../redux/services/device.service";

export default function UserPage() {
  const ability = useSelector(selectAbility);

  const isTablet = useResponsive("down", "lg");

  const [openRow, setOpenRow] = useState(null);

  const [openLinkCodeForm, setOpenLinkCodeForm] = useState(false);

  const [openAddBulkUserForm, setOpenAddBulkUserForm] = useState(false);

  const [openUserForm, setOpenUserForm] = useState(false);

  const [openChangeMobileForm, setOpenChangeMobileForm] = useState(false);

  const [openDelinkConfirmation, setOpenDelinkConfirmation] = useState(false);

  const navigate = useNavigate();

  const [delink, { isLoading: unlinkCodeLoading }] = useDelinkUserMutation();

  const [selectedEventPlace, setSelectedEventPlace] = useState("");
  const [filteredTags, setFilteredTags] = useState([]); // State to store filtered tags

  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      organizationId: "",
      distributorId: "",
    },
  });

  const {
    isLoading: isOrgLoading,
    isFetching: isOrgFetching,
    data: organizations,
  } = useGetOrganizationsQuery(null, {});

  const {
    data: eventPlaces,
    isSuccess: isSuccessEventPlaces,
    isLoading: isLoadingEventPlaces,
  } = useGetEventPlacesQuery({

    organizationId: localStorage.getItem("organizationId"),

  });

  const handleLinkCode = () => {
    setOpenLinkCodeForm(true);
  };

  const handleResetFilter = () => {

    setValue("userType", "");
    setValue("organizationId", "");
    setSelectedEventPlace("");
    setValue("tag", "");
    setValue("deployDates", []);
  };

  const handleEventPlaceChange = (event) => {
    try {
      const selectedPlace = event.target.value;
      setSelectedEventPlace(selectedPlace);

      // Filter tags based on selected event place
      const tags =
        eventPlaces?.data
          .filter(
            (event) => event.eventPlace === selectedPlace && event.tag !== null,
          )
          .map((event) => event.tag) || [];

      setFilteredTags(tags);

      // Reset the tag field (clear the value)
      setValue("tag", ""); // This will clear the tag field
    } catch (error) {
      // Catch and log any errors
      console.error("Error while handling event place change:", error);
    }
  };

  return (
    <Protected>
      <Helmet>
        <title>User</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            User
          </Typography>
          <Box display="flex" alignItems="center">
            {ability.can("post", "Bulk-User") && (
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => setOpenAddBulkUserForm(true)}
              >
                Add Bulk User
              </Button>
            )}
          </Box>
        </Stack>

        {ability.can("post", "Link-User-Code") && (
          <LinkCodeForm
            open={openLinkCodeForm}
            onClose={() => setOpenLinkCodeForm(false)}
            openRow={openRow}
          />
        )}

        {ability.can("post", "Bulk-User") && (
          <BulkAddUserForm
            open={openAddBulkUserForm}
            onClose={() => setOpenAddBulkUserForm(false)}
          />
        )}

        {ability.can("patch", "User") && (
          <UserForm
            open={openUserForm}
            onClose={() => setOpenUserForm(false)}
            openRow={openRow}
            edit
          />
        )}

        {ability.can("post", "Change-Mobile-Number") && (
          <ChangeMobileForm
            open={openChangeMobileForm}
            onClose={() => setOpenChangeMobileForm(false)}
            openRow={openRow}
          />
        )}

        <Card>
          <Scrollbar>
            <CustomTable
              canSelect
              useDataQuery={useGetUsersQuery}
              searchLabel="Search User..."
              tableName="User"
              defaultQueryParams={{
                userType: watch("userType"),
                organizationId: watch("organizationId"),
                eventPlace: selectedEventPlace,
                tag: watch("tag"),
                distributorId: watch("distributorId"),
              }}
              restrictDownloadCols={["otp", "isSubscribe"]}
              searchCols={[
                "contactNumber",
                "name",
                "deviceCode",
                "recipientOrganizationName",
                "senderOrganizationName",
              ]}
              resetParentForm={handleResetFilter}
              actions={
                ability.can("patch", "User") ||
                ability.can("get", "User-Google-Analytics") ||
                ability.can("post", "Link-User-Code") ||
                ability.can("post", "Deink-User-Code") ||
                ability.can("post", "Change-Mobile-Number")
                  ? {
                      field: "actions",
                      headerName: "",
                      type: "actions",
                      getActions: ({ row }) =>
                        [
                          ability.can("patch", "User") ? (
                            <GridActionsCellItem
                              key={0}
                              icon={<Edit />}
                              label="Edit"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setOpenUserForm(true);
                              }}
                            />
                          ) : null,
                          ability.can("get", "User-Google-Analytics") ? (
                            <GridActionsCellItem
                              key={1}
                              icon={
                                <Iconify
                                  icon="eva:eye-fill"
                                  sx={{ color: "primary.main" }}
                                />
                              }
                              sx={{
                                color: "primary.main",
                              }}
                              label="Usage"
                              showInMenu
                              onClick={() => {
                                navigate(`/dashboard/user/${row?.id}`);
                              }}
                            />
                          ) : null,
                          ability.can("post", "Link-User-Code") &&
                          !row?.isCodeVerified ? (
                            <GridActionsCellItem
                              key={2}
                              icon={
                                <Iconify
                                  icon="eva:link-2-fill"
                                  sx={{ color: "success.main" }}
                                />
                              }
                              sx={{
                                color: "success.main",
                              }}
                              label="Link Code"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                handleLinkCode();
                              }}
                            />
                          ) : null,
                          ability.can("post", "Delink-User-Code") &&
                          row?.isCodeVerified ? (
                            <GridActionsCellItem
                              key={3}
                              icon={
                                unlinkCodeLoading ? (
                                  <CircularProgress
                                    size={20}
                                    sx={{
                                      color: "warning.main",
                                    }}
                                  />
                                ) : (
                                  <LinkOff
                                    sx={{
                                      color: "warning.main",
                                    }}
                                  />
                                )
                              }
                              sx={{
                                color: "warning.main",
                              }}
                              label="Unlink Code"
                              showInMenu
                              onClick={async () => {
                                setOpenRow(row);
                                setOpenDelinkConfirmation(true);
                              }}
                            />
                          ) : null,
                          ability.can("post", "Change-Mobile-Number") ? (
                            <GridActionsCellItem
                              key={4}
                              icon={
                                <Iconify
                                  icon="eva:phone-fill"
                                  sx={{ color: "info.main" }}
                                />
                              }
                              sx={{
                                color: "info.main",
                              }}
                              label="Change Mobile"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setOpenChangeMobileForm(true);
                              }}
                            />
                          ) : null,
                        ].filter((action) => action !== null),
                    }
                  : null
              }
              extraHeaderField={
                <Box
                  sx={{

                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginLeft: 1,
                  }}
                >
                  <Grid container spacing={1} flexBasis="100%">
                    {/* UserType Organization */}
                    {(ability.can("Is", "organization") ||
                      ability.can("Is", "admin") ||
                      ability.can("Is", "support")) && (
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel id="userType-label">User Type</InputLabel>
                          <Select
                            labelId="userType-label"
                            id="userType-select"
                            {...register("userType")}
                            label="User Type"
                            value={watch("userType") ?? ""}
                            inputProps={{ "data-testid": "userType" }}
                          >
                            <MenuItem value="all">
                              <em>All</em>
                            </MenuItem>
                            <MenuItem value="device">Device Users</MenuItem>
                            <MenuItem value="subscription">
                              Premium Users
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    {/* Organization Select */}
                    {ability.can("get", "Organizations") && (
                      <Grid item xs={12} sm={3}>
                        <FormControl
                          fullWidth
                          error={Boolean(errors.organizationId)}
                        >
                          <InputLabel id="org-select-label">
                            Organization
                          </InputLabel>
                          <Select
                            {...register("organizationId", { required: true })}
                            labelId="org-select-label"
                            id="org-select"
                            inputProps={{ "data-testid": "organizationId" }}
                            value={watch("organizationId")}
                            error={Boolean(errors.organizationId)}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {isOrgLoading || isOrgFetching ? (
                              <MenuItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <CircularProgress size={20} />
                              </MenuItem>
                            ) : (
                              organizations?.data
                                ?.toSorted((a, b) =>
                                  a?.name?.localeCompare(b?.name),
                                )
                                ?.map((org) => (
                                  <MenuItem key={org?.id} value={org?.id}>
                                    {org?.name}
                                  </MenuItem>
                                ))
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    {/* Event Place Select */}
                    {ability.can("get", "All-Event-Place") && (
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel id="eventPlace-label">
                            Event Place
                          </InputLabel>
                          <Select
                            labelId="eventPlace-label"
                            id="eventPlace-select"
                            {...register("eventPlace")}
                            label="Event Place"
                            value={selectedEventPlace}
                            onChange={handleEventPlaceChange}
                            inputProps={{ "data-testid": "eventPlace" }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {isLoadingEventPlaces && (
                              <MenuItem>
                                <CircularProgress size={24} />
                              </MenuItem>
                            )}
                            {isSuccessEventPlaces &&
                              eventPlaces?.data?.map((eventPlace, index) => (
                                <MenuItem
                                  key={`${eventPlace.eventPlace}-${index}`}
                                  value={eventPlace.eventPlace}
                                >
                                  {eventPlace.eventPlace}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                 
                    {/* Tag Select */}
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <InputLabel id="tag-label">Tag</InputLabel>
                        <Select
                          labelId="tag-label"
                          id="tag-select"
                          {...register("tag")} // Register tag
                          label="Tag"
                          value={watch("tag") ?? ""}
                          disabled={filteredTags.length === 0} // Disable tag select if no tags are available
                          inputProps={{ "data-testid": "tag" }}

                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>

                          {filteredTags.length > 0 ? (
                            filteredTags.map((tag, index) => (
                              <MenuItem key={index} value={tag}>
                                {tag}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem disabled>No tags available</MenuItem>
                          )}
                          
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              }
            />
          </Scrollbar>
        </Card>
      </Container>

      {ability.can("post", "Delink-User-Code") && (
        <CustomDeleteConfirmation
          open={openDelinkConfirmation}
          onClose={() => setOpenDelinkConfirmation(false)}
          row={openRow}
          nameKey="deviceCode"
          nameTitle={"Device Code"}
          title="Unlink User"
          description={`Are you sure you want to unlink user with device code "${openRow?.deviceCode}" and contact number ${openRow?.contactNumber}?`}
          confirmText="Unlink"
          confirmTextColor="error"
          isLoading={unlinkCodeLoading}
          deleteFunc={delink}
        />
      )}
    </Protected>
  );
}
