import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useSelector } from "react-redux";
// @mui
import { Card, Stack, Button, Container, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import CustomTable from "../components/data-table/CustomDataTable";
import DistributorForm from "../sections/@dashboard/distributor/distributor.form";

import { useGetDistributorsQuery } from "../redux/services/authUser.service";
// components
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import Protected from "../protected/Protected";
import { selectAbility } from "../redux/slices/casl.slice";
import {selectUser} from "../redux/slices/auth.slice";

export default function DistributorPage() {
  const ability = useSelector(selectAbility);

  const user = useSelector(selectUser);

  const [openDistributorForm, setOpenDistributorForm] = useState(false);

  const [edit, setEdit] = useState(false);

  const [openRow, setOpenRow] = useState({});

  const handleCloseForm = () => {
    setOpenDistributorForm(false);
    setEdit(false);
    setOpenRow(null);
  };

  return (
    <Protected>
      <Helmet>
        <title> Distributor </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Distributors
          </Typography>
          {ability.can("post", "Distributor") && (
            <Button
              variant="contained"
              onClick={setOpenDistributorForm}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Distributor
            </Button>
          )}
          {(ability.can("patch", "Distributor") ||
            ability.can("post", "Distributor")) && (
            <DistributorForm
              open={openDistributorForm}
              onClose={handleCloseForm}
              edit={edit}
              openRow={openRow}
            />
          )}
        </Stack>

        <Card>
          <Scrollbar>
            <CustomTable
              canSelect
              useDataQuery={useGetDistributorsQuery}
              tableName="Distributors"
              searchLabel="Search Distributor..."
              searchCols={["name", "email", "contactNumber"]}
              actions={
                ability.can("patch", "Distributor")
                  ? {
                      field: "actions",
                      headerName: "Actions",
                      type: "actions",
                      getActions: ({ row }) =>
                        [
                          ability.can("patch", "Distributor") &&
                          (ability.can("Is", "admin") ||
                            row?.parentDistributorId === user?.id) ? (
                            <GridActionsCellItem
                              key="edit"
                              icon={<Edit />}
                              label="Edit"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setEdit(true);
                                setOpenDistributorForm(true);
                              }}
                            />
                          ) : null,
                        ].filter((action) => action !== null),
                    }
                  : null
              }
            />
          </Scrollbar>
        </Card>
      </Container>
    </Protected>
  );
}
