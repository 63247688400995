import React, { useEffect, useState } from 'react'
import { Button, Card, CardHeader } from '@mui/material'
import ReactApexChart from 'react-apexcharts'
import ApexCharts from 'apexcharts'
import PropTypes from 'prop-types'
import './UsageChart.css'
import moment from 'moment'

SignUpUserChart.propTypes = {
  duration: PropTypes.string,
  chartData: PropTypes.array,
}

export default function SignUpUserChart ({ duration, chartData }) {
  const [totalUsers, setTotalUsers] = useState(0)
  const seriesData = [
    {
      name: 'New Users',
      data: chartData?.map(i => i?.signUpUserCount) || [],
      // data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ]
  const chartOptions = {
    series: seriesData,
    chart: {
      type: 'line',
      height: '500',
      zoom: {
        enabled: false,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'straight',
    },
    colors: ['rgb(255, 193, 7)'],
    labels: chartData?.map(i => {
      const date = new Date(i?.date?.split('T')?.[0])
      if (duration === 'monthly') {
        return moment(date).format('DD/MM')
      }
      if (duration === 'yearly') {
        return `${i?.month?.split(' ')[0].slice(0, 3)  } ${  i?.month?.split(' ')[1]}`
      }
      return moment(date).format('DD/MM/YYYY')
    }),
    // labels: [
    //   '2021-09-19',
    //   '2021-09-20',
    //   '2021-09-21',
    //   '2021-09-22',
    //   '2021-09-23',
    //   '2021-09-24',
    //   '2021-09-25',
    //   '2021-09-26',
    //   '2021-09-27',
    // ],
    // markers: {
    //   size: 0,
    //   style: 'hollow',
    // },
    // toolbar:{
    //   show: false
    // },
    dataLabels: {
      enabled: false,
    },
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shadeIntensity: 1,
    //     opacityFrom: 0.7,
    //     opacityTo: 0.9,
    //     stops: [0, 100],
    //   },
    // },
  }

  useEffect(() => {
    const total = chartData?.reduce((acc, curr) => acc + curr?.signUpUserCount, 0)
    setTotalUsers(total)
  }, [chartData])

  return (
    <Card sx={{ height: '450px' }}>
      <CardHeader title={`New Users (${totalUsers})`} />
      <ReactApexChart options={chartOptions} series={seriesData} type='line' height={398} />
    </Card>
  )
}
