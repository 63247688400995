import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useSelector } from "react-redux";
// @mui
import { Card, Stack, Button, Container, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";

// components
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
import Protected from "../protected/Protected";
// sections
import CustomTable from "../components/data-table/CustomDataTable";
import {
  useDeleteSupportedLanguageMutation,
  useGetSupportedLanguagesQuery,
} from "../redux/services/supportedLanguages.service";
import SupportedLanguagesForm from "../sections/@dashboard/supported-languages/supportedLanguages.form";
import { selectAbility } from "../redux/slices/casl.slice";
import CustomDeleteConfirmation from "../components/dialog/CustomDeleteConfirmation";
// ----------------------------------------------------------------------

export default function SupportedLanguagesPage() {
  const ability = useSelector(selectAbility);

  const [deleteSupportedLanguages, { isLoading: isDeleting }] =
    useDeleteSupportedLanguageMutation();

  const [openSupportedLanguageForm, setOpenSupportedLanguageForm] =
    useState(false);

  const [openRow, setOpenRow] = useState(null);

  const [edit, setEdit] = useState(false);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleCloseFilter = () => {
    setOpenSupportedLanguageForm(false);
    setEdit(false);
  };

  return (
    <Protected>
      <Helmet>
        <title> Supported Languages</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Supported Languages
          </Typography>
          {ability.can("post", "Supported-Language") && (
            <Button
              variant="contained"
              onClick={setOpenSupportedLanguageForm}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Supported Language
            </Button>
          )}

          {(ability.can("post", "Supported-Language") ||
            ability.can("patch", "Supported-Language")) && (
            <SupportedLanguagesForm
              open={openSupportedLanguageForm}
              onClose={handleCloseFilter}
              edit={edit}
              openRow={openRow}
            />
          )}
        </Stack>

        <Card>
          <Scrollbar>
            <CustomTable
              canSelect
              useDataQuery={useGetSupportedLanguagesQuery}
              tableName="SupportedLanguages"
              searchLabel="Search Language..."
              searchCols={["languageName", "languageCode"]}
              actions={
                // using GridActionCellItem
                ability.can("patch", "Supported-Language") ||
                ability.can("delete", "Supported-Language")
                  ? {
                      field: "actions",
                      headerName: "Action",
                      type: "actions",
                      getActions: ({ row }) =>
                        [
                          ability.can("patch", "Supported-Language") ? (
                            <GridActionsCellItem
                              key="edit"
                              icon={<Edit />}
                              label="Edit"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setEdit(true);
                                setOpenSupportedLanguageForm(true);
                              }}
                            />
                          ) : null,
                          ability.can("delete", "Supported-Language") ? (
                            <GridActionsCellItem
                              key="delete"
                              sx={{
                                color: "error.main",
                              }}
                              icon={
                                <Iconify
                                  color="error.main"
                                  icon="eva:trash-2-outline"
                                />
                              }
                              label="Delete"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setOpenDeleteConfirmation(true);
                              }}
                            />
                          ) : null,
                        ].filter((item) => item),
                    }
                  : null
              }
            />
          </Scrollbar>
        </Card>
      </Container>

      {ability.can("delete", "Supported-Language") && (
        <CustomDeleteConfirmation
          open={openDeleteConfirmation}
          onClose={() => setOpenDeleteConfirmation(false)}
          tableName="Supported Language"
          nameKey="languageName"
          nameTitle="name"
          deleteFunc={deleteSupportedLanguages}
          row={openRow}
          isLoading={isDeleting}
        />
      )}
    </Protected>
  );
}
