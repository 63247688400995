import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBaseQuery } from "../basequery";

export const supportedLanguagesApi = createApi({
  reducerPath: "supportedLanguagesApi",
  baseQuery: extendedBaseQuery,
  tagTypes: ["SupportedLanguage"],
  endpoints: (builder) => ({
    getSupportedLanguages: builder.query({
      query: (params) => {
        const query = {};
        if (params?.skip) query.skip = params.skip;
        if (params?.limit) query.limit = params.limit;
        if (params?.search) query.search = params.search;
        if (params?.filter) query.filter = params.filter;
        if (params?.sortBy) query.sortBy = params.sortBy;
        if (params?.orderBy) query.orderBy = params.orderBy;

        return {
          url: `supported-languages?${new URLSearchParams(query).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["SupportedLanguage"],
    }),
    createSupportedLanguage: builder.mutation({
      query: (body) => ({
        url: "supported-languages",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SupportedLanguage"],
    }),
    updateSupportedLanguage: builder.mutation({
      query: (body) => ({
        url: `supported-languages/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["SupportedLanguage"],
    }),
    deleteSupportedLanguage: builder.mutation({
      query: (id) => ({
        url: `supported-languages/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SupportedLanguage"],
    }),
  }),
});

export const {
  useGetSupportedLanguagesQuery,
  useCreateSupportedLanguageMutation,
  useUpdateSupportedLanguageMutation,
  useDeleteSupportedLanguageMutation,
} = supportedLanguagesApi;
