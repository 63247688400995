import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { ActivatePlansFormSchema } from "../../../forms-schema/form.schema";
import Scrollbar from "../../../components/scrollbar/Scrollbar";
import {
  useActivatePlanMutation,
  useGetPlansQuery,
} from "../../../redux/services/plans.service";

export default function ActivatePlans({ open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(ActivatePlansFormSchema),
  });

  const {
    isLoading: isPlansLoading,
    isFetching: isPlansFetching,
    data: plans,
  } = useGetPlansQuery(null, {});

  const [activatePlan, { isLoading }] = useActivatePlanMutation();

  const handleClick = async (values) => {
    console.log("Plans Submit Form:", values?.plans);
    setLoading(true);
    try {
      const resultAction = await activatePlan({
        planId: values.plans,
      });

      if (resultAction?.data?.success) {
        reset();
        onClose();
      }
    } catch (error) {
      // Catch and handle any errors during the process
      console.error("Error occurred during Activating Plan:", error);

      // Display error using notistack
      enqueueSnackbar("ERROR!! Activating Plan.", {
        variant: "error", // Set the snackbar type to 'error'
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } finally {
      setLoading(false); // Ensure loading state is turned off in all cases
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">Activate Plan</Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <Stack spacing={3}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  error={Boolean(errors.plans)}
                >
                  Plans
                </InputLabel>
                <Select
                  {...register("plans", { required: true })}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Plans"
                  error={Boolean(errors.plans)}
                >
                  {isPlansLoading || isPlansFetching ? (
                    <MenuItem
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size={20} />
                    </MenuItem>
                  ) : (
                    plans?.data
                      ?.toSorted((a, b) => a?.name?.localeCompare(b?.name))
                      ?.map((plan) => (
                        <MenuItem key={plan?.id} value={plan?.id}>
                          {plan?.name}
                        </MenuItem>
                      ))
                  )}
                </Select>
                <FormHelperText error={Boolean(errors.plans)}>
                  {errors.plans?.message}
                </FormHelperText>
              </FormControl>
              <LoadingButton
                loading={loading || isLoading}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit(handleClick)}
              >
                Activate
              </LoadingButton>
            </Stack>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
