import { useEffect } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Button,
  Drawer,
  Divider,
  Typography,
  TextField,
  IconButton,
  Container,
  Box,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";

import { Add, Close, Delete } from "@mui/icons-material";
// components
import Scrollbar from "../../../components/scrollbar";
import { useAddEnglishWordsMutation } from "../../../redux/services/language.service";
import { WordSchema } from "../../../forms-schema/form.schema";

AddWordsForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
};

export default function AddWordsForm({ open, onClose }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      englishWords: [],
    },
    resolver: yupResolver(WordSchema),
  });

  useEffect(() => {
    reset({
      englishWords: [""],
    });
    // eslint-disable-next-line
  }, [open]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "englishWords",
  });

  useEffect(() => {
    append("");
  }, [append]);

  const [addWords, { isLoading }] = useAddEnglishWordsMutation();

  const addWordHandler = () => {
    if (!Object.keys(errors).length) {
      append("");
    }
  };

  const handleClick = async (values) => {
    const resultAction = await addWords({ ...values });
    if (resultAction?.data?.success) {
      onClose();
      reset();
    }
  };

  const handleDeleteWord = (index) => {
    if (fields.length === 1) return;
    remove(index);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 400, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">Create Words</Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                {fields.map((item, index) => (
                  <Container
                    key={item.id}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      px: "0 !important",
                    }}
                  >
                    <TextField
                      label="English Word"
                      variant="outlined"
                      {...register(`englishWords.${index}`, {
                        required: "English word is required",
                      })}
                      error={!!errors?.englishWords?.[index]?.message}
                      helperText={errors?.englishWords?.[index]?.message}
                      fullWidth
                      inputProps={{ "data-testid": `englishWord.${index}` }}
                    />
                    <IconButton
                      onClick={() => handleDeleteWord(index)}
                      data-testid={`delete.${index}`}
                    >
                      <Delete width={20} height={20} />
                    </IconButton>
                  </Container>
                ))}
                <Stack direction="row">
                  <Button type="button" variant="text" onClick={addWordHandler}>
                    <Add />
                    Add Word
                  </Button>
                </Stack>
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
