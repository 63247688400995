import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBaseQuery } from "../basequery";

export const authUserApi = createApi({
  reducerPath: "authUserApi",
  baseQuery: extendedBaseQuery,
  tagTypes: ["AuthUser"],
  endpoints: (builder) => ({
    getAuthUsers: builder.query({
      query: (params) => {
        const query = {};
        if (params?.skip) query.skip = params.skip;
        if (params?.limit) query.limit = params.limit;
        if (params?.search) query.search = params.search;
        if (params?.filter) query.filter = params.filter;
        if (params?.sortBy) query.sortBy = params.sortBy;
        if (params?.orderBy) query.orderBy = params.orderBy;

        return {
          url: `authUser?${new URLSearchParams(query).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["AuthUser"],
    }),
    getAuthUser: builder.query({
      query: (id) => ({
        url: `authUser/${id}`,
        method: "GET",
      }),
      providesTags: ["AuthUser"],
    }),
    createAuthUser: builder.mutation({
      query: (body) => ({
        url: "authUser",
        method: "POST",
        body,
      }),
      invalidatesTags: ["AuthUser"],
    }),
    updateAuthUser: builder.mutation({
      query: (body) => ({
        url: `authUser/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["AuthUser"],
    }),
    deleteAuthUser: builder.mutation({
      query: (id) => ({
        url: `authUser/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AuthUser"],
    }),
    getDistributors: builder.query({
      query: (params) => {
        const userId = JSON.parse(localStorage.getItem("user"))?.id;

        const query = {};
        if (params?.skip) query.skip = params.skip;
        if (params?.limit) query.limit = params.limit;

        query.id = userId;

        return {
          url: `authUser/get-distributors?${new URLSearchParams(
            query,
          ).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["AuthUser"],
    }),
    createSubDistributor: builder.mutation({
      query: (body) => ({
        url: "authUser/create-sub-distributor",
        method: "POST",
        body,
      }),
      invalidatesTags: ["AuthUser"],
    }),
    getParentDistributor: builder.query({
      query: () => ({
        url: `authUser/get-parent-distributors`,
        method: "GET",
      }),
    }),
    updateDistributor: builder.mutation({
      query: (body) => ({
        url: `authUser/update-distributor/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["AuthUser"],
    }),
  }),
});

export const {
  useGetAuthUsersQuery,
  useGetAuthUserQuery,
  useCreateAuthUserMutation,
  useUpdateAuthUserMutation,
  useDeleteAuthUserMutation,
  useGetDistributorsQuery,
  useCreateSubDistributorMutation,
  useGetParentDistributorQuery,
  useUpdateDistributorMutation,
} = authUserApi;
