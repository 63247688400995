import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import {
  useCreateRoleMutation,
  useUpdateRoleMutation,
} from "../../../redux/services/role.service";
import Scrollbar from "../../../components/scrollbar";
import { RoleSchema } from "../../../forms-schema/form.schema";

RoleForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  openRow: PropTypes.object,
};

export default function RoleForm({ open, onClose, edit, openRow }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(RoleSchema),
  });

  const [createRole, { isLoading }] = useCreateRoleMutation();
  const [updateRole, { isLoading: isUpdateLoading }] = useUpdateRoleMutation();

  const handleClick = async (values) => {
    let resultAction;
    if (edit) {
      resultAction = await updateRole({ ...values, id: openRow.id });
    } else {
      resultAction = await createRole(values);
    }
    if (resultAction?.data?.success) {
      onClose();
      reset();
    }
  };

  useEffect(() => {
    if (edit) {
      setValue("name", openRow.name);
    } else {
      reset();
    }
  }, [edit, reset, openRow, setValue, open]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">
                  {edit ? "Edit" : "Create"} Role
                </Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                <TextField
                  name="name"
                  label="Role Name"
                  {...register("name", { required: true })}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  inputProps={{ "data-testid": "roleName" }}
                />
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  loading={edit ? isUpdateLoading : isLoading}
                  data-testid="submitBtn"
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
