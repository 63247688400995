import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBaseQuery } from "../basequery";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: extendedBaseQuery,
  tagTypes: ["User"],
  endpoints: (builder) =>   ({
    getUsers: builder.query({
      query: (params) => {
        const query = {};
        if (params?.skip) query.skip = params.skip;
        if (params?.limit) query.limit = params.limit;
        if (params?.search) query.search = params.search;
        if (params?.filter) query.filter = params.filter;
        if (params?.sortBy) query.sortBy = params.sortBy;
        if (params?.orderBy) query.orderBy = params.orderBy;

        if (params?.userType) query.userType = params.userType;
        if (params?.organizationId)
          query.organizationId = params.organizationId;
        if (params?.distributorId) query.distributorId = params.distributorId;
        if (params?.eventPlace) query.eventPlace = params.eventPlace;
        if (params?.tag) query.tag = params.tag;
        return {
          url: `user/all${
            Object.keys(query).length > 0 ? "?" : ""
          }${new URLSearchParams(query)}`,
          method: "GET",
        };
      },
      providesTags: ["User"],
    }),
    getUser: builder.query({
      query: (id) => `user/${id}`,
      providesTags: ["User"],
    }),
    createUser: builder.mutation({
      query: (body) => ({
        url: "user",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    updateUser: builder.mutation({
      query: (body) => ({
        url: `user/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["User"],
    }),
    linkUser: builder.mutation({
      query: (body) => ({
        url: "user/link-user-device",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    delinkUser: builder.mutation({
      query: (id) => ({
        url: `user/delink/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),
    bulkUserCreate: builder.mutation({
      query: (body) => ({
        url: "user/bulk-link-user-device",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    changeMobileNumber: builder.mutation({
      query: (body) => ({
        url: "user/update-mobile-number",
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    generateOtp: builder.mutation({
      query: (body) => ({
        url: "user/change-mobile-number",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useLinkUserMutation,
  useDelinkUserMutation,
  useBulkUserCreateMutation,
  useChangeMobileNumberMutation,
  useGenerateOtpMutation,
} = userApi;
