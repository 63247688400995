import ForgotPasswordForm from "../sections/auth/forgot-password/forgotPassword.form";
import AuthLayout from "../layouts/auth/AuthLayout";

export default function ForgotPasswordPage() {
  return (
    <AuthLayout
      formHeading="Forgot Password"
      title="Forgot Password | Sunbots"
    >
      <ForgotPasswordForm />
    </AuthLayout>
  );
}
