import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBaseQuery } from "../basequery";

export const uploadApi = createApi({
  reducerPath: "uploadApi",
  baseQuery: extendedBaseQuery,
  endpoints: (builder) => ({
    uploadSingleFile: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: "uploads/file",
          method: "POST",
          body: formData,
        };
      },
    }),
    uploadMultipleFiles: builder.mutation({
      query: (files) => {
        const formData = new FormData();
        files.forEach((file) => {
          formData.append("files", file);
        });
        return {
          url: "uploads/multiple",
          method: "POST",
          body: formData,
        };
      },
    }),
  }),
});

export const { useUploadSingleFileMutation, useUploadMultipleFilesMutation } =
  uploadApi;
