import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";

// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { useLoginMutation } from "../../../redux/services/auth.service";
import Iconify from "../../../components/iconify";
import { LoginSchema } from "../../../forms-schema/form.schema";
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [login, { isLoading }] = useLoginMutation();
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const handleClick = async (values) => {
    try {
      const resultAction = await login(values);
      unwrapResult(resultAction);
      if (resultAction?.data?.success) {
        if (resultAction?.data?.data?.isVerified) {
          enqueueSnackbar("Login successfully", { variant: "success" });
        } else {
          enqueueSnackbar("Please change your auto generated password", {
            variant: "warning",
          });
        }
        if (resultAction?.data?.data?.roles?.[0]?.name === "superadmin") {
          navigate("/dashboard/permission", { replace: true });
        } else {
          navigate("/dashboard/app", { replace: true });
        }
      }
    } catch (err) {
      enqueueSnackbar(err?.error ?? err?.data?.message, { variant: "error" });
      console.log("Failed to login: ", err);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleClick)}>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          {...register("email", { required: true })}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        />

        <TextField
          name="password"
          label="Password"
          {...register("password", { required: true })}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 1 }}
      >
        <Button
          variant="text"
          size="small"
          onClick={() => navigate("/forgot-password")}
        >
          Forgot password?
        </Button>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isLoading}
      >
        Login
      </LoadingButton>
    </form>
  );
}
