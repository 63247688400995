import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    filters: {
      organizationId: "",
      distributorId: "",
      eventPlace: "",
      tag: "",
      deviceCreationDates: [],
      cameraType: "",
      userType: "",
    },
  },
  reducers: {
    setDashboardFilters: (state, action) => {
      state.filters = action.payload;
    },
    setOrganizationFilter: (state, action) => {
      state.filters.organizationId = action.payload;
    },
    resetDashboardFilters: (state) => {
      state.filters = {
        organizationId: "",
        distributorId: "",
        eventPlace: "",
        tag: "",
        deviceCreationDates: [],
        cameraType: "",
        userType: "",
      };
    },
  },
});

export const {
  setDashboardFilters,
  setOrganizationFilter,
  resetDashboardFilters,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const selectDashboardFilters = (state) => state.dashboard.filters;
