import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useSelector } from "react-redux";
// @mui
import { Card, Stack, Button, Container, Typography } from "@mui/material";

// redux
import { Edit } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  useDeleteSupportedDeviceMutation,
  useGetSupportedDevicesQuery,
} from "../redux/services/supportedDevice.service";

// components
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
import Protected from "../protected/Protected";
// sections
import SupportedDeviceForm from "../sections/@dashboard/supported-device/supportedDevice.form";
import CustomTable from "../components/data-table/CustomDataTable";
import { selectAbility } from "../redux/slices/casl.slice";
import CustomDeleteConfirmation from "../components/dialog/CustomDeleteConfirmation";

export default function SupportedDevicePage() {
  const ability = useSelector(selectAbility);

  const {
    data: supportedDevices,
    isLoading: isSupportedDevicesLoading,
    isFetching: isSupportedDevicesFetching,
  } = useGetSupportedDevicesQuery();

  const [deleteSupportedDevice, { isLoading: isDeleting }] =
    useDeleteSupportedDeviceMutation();

  const [openSupportedDeviceForm, setOpenSupportedDeviceForm] = useState(false);

  const [edit, setEdit] = useState(false);

  const [openRow, setOpenRow] = useState({});

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleCloseFilter = () => {
    setOpenSupportedDeviceForm(false);
    setEdit(false);
  };

  return (
    <Protected>
      <Helmet>
        <title> Supported Devices </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Supported Device
          </Typography>
          {ability.can("post", "Supported-Device") && (
            <Button
              variant="contained"
              onClick={setOpenSupportedDeviceForm}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Supported Devices
            </Button>
          )}
          {(ability.can("post", "Supported-Device") ||
            ability.can("patch", "Supported-Device")) && (
            <SupportedDeviceForm
              open={openSupportedDeviceForm}
              onClose={handleCloseFilter}
              edit={edit}
              openRow={openRow}
              deviceOptions={supportedDevices?.data}
              isLoading={
                isSupportedDevicesLoading || isSupportedDevicesFetching
              }
            />
          )}
        </Stack>

        <Card>
          <Scrollbar>
            <CustomTable
              canSelect
              useDataQuery={useGetSupportedDevicesQuery}
              tableName="Supported Devices"
              searchLabel="Search Device..."
              searchCols={["deviceName"]}
              actions={
                ability.can("patch", "Supported-Device") ||
                ability.can("delete", "Supported-Device")
                  ? {
                      field: "actions",
                      headerName: "Action",
                      type: "actions",
                      getActions: ({ row }) =>
                        [
                          ability.can("patch", "Supported-Device") ? (
                            <GridActionsCellItem
                              key="edit"
                              icon={<Edit />}
                              label="Edit"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setEdit(true);
                                setOpenSupportedDeviceForm(true);
                              }}
                            />
                          ) : null,
                          ability.can("delete", "Supported-Device") ? (
                            <GridActionsCellItem
                              key="delete"
                              sx={{
                                color: "error.main",
                              }}
                              icon={
                                <Iconify
                                  color="error.main"
                                  icon="eva:trash-2-outline"
                                />
                              }
                              label="Delete"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setOpenDeleteConfirmation(true);
                              }}
                            />
                          ) : null,
                        ].filter((action) => action),
                    }
                  : null
              }
            />
          </Scrollbar>
        </Card>
      </Container>

      {ability.can("delete", "Supported-Device") && (
        <CustomDeleteConfirmation
          open={openDeleteConfirmation}
          onClose={() => setOpenDeleteConfirmation(false)}
          tableName="Supported device"
          deleteFunc={deleteSupportedDevice}
          nameKey="deviceName"
          nameTitle="name"
          row={openRow}
          isLoading={isDeleting}
        />
      )}
    </Protected>
  );
}
