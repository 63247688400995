import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  IconButton,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import {
  useCreateOfferMutation,
  useUpdateOfferMutation,
} from "../../../redux/services/offer.service";
import Scrollbar from "../../../components/scrollbar";
import { OfferSchema } from "../../../forms-schema/form.schema";

OfferForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  openRow: PropTypes.object,
};

export default function OfferForm({ open, onClose, edit, openRow }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(OfferSchema),
  });

  const [createOffer, { isLoading }] = useCreateOfferMutation();
  const [updateOffer, { isLoading: isUpdateLoading }] =
    useUpdateOfferMutation();
  const handleClick = async (values) => {
    let resultAction;
    if (edit) {
      resultAction = await updateOffer({ ...values, id: openRow.id });
    } else {
      resultAction = await createOffer(values);
    }
    if (resultAction?.data?.success) {
      onClose();
      reset();
    }
  };

  useEffect(() => {
    if (edit) {
      setValue("name", openRow.name);
      setValue("description", openRow.description);
      setValue("discountAmount", openRow.discountAmount);
      setValue("code", openRow.code);
      setValue("status", openRow.status);
    } else {
      reset();
    }
  }, [edit, reset, openRow, setValue, open]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">
                  {edit ? "Edit" : "Create"} Offer
                </Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                <TextField
                  name="name"
                  label="Offer Name"
                  {...register("name", { required: true })}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                />
                <TextField
                  minRows={4}
                  name="description"
                  {...register("description", { required: true })}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  label="Description"
                />
                <TextField
                  name="discountAmount"
                  label="Offer Amount"
                  {...register("discountAmount", { required: true })}
                  error={Boolean(errors.discountAmount)}
                  helperText={errors.discountAmount?.message}
                />
                <TextField
                  name="code"
                  label="Code"
                  {...register("code", { required: true })}
                  error={Boolean(errors.code)}
                  helperText={errors.code?.message}
                />
                {edit ? (
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      Status
                    </InputLabel>
                    <Select
                      name="status"
                      {...register("status", { required: false })}
                      label="Status"
                      defaultValue={openRow?.status}
                      value={watch("status")}
                    >
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                      <MenuItem value="pending">Pending</MenuItem>
                    </Select>
                  </FormControl>
                ) : null}
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  loading={edit ? isUpdateLoading : isLoading}
                  data-testid="submitBtn"
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
