import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useUpdatePlanMutation } from "../../../redux/services/plans.service";
import { planSchema } from "../../../forms-schema/form.schema";
import Scrollbar from "../../../components/scrollbar/Scrollbar";

PlansForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  openRow: PropTypes.object,
};

export default function PlansForm({ open, onClose, edit, openRow }) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(planSchema),
  });

  useEffect(() => {
    reset();
    if (edit) {
      setValue("isFree", openRow?.isFree);
      setValue("smartEyeLimit", openRow?.smartEyeLimit);
      setValue("docAiLimit", openRow?.docAiLimit);
      setValue("aiChatLimit", openRow?.aiChatLimit);
      setValue("durationDays", openRow?.durationDays);
    }
  }, [edit, openRow, setValue, open, reset]);

  const isFree = watch("isFree");

  const [updatePlan, { isLoading: isUpdateLoading }] = useUpdatePlanMutation();

  const handleClick = async (values) => {
    console.log("Plans Submit Form:", values);
    setLoading(true);
    try {
      const resultAction = await updatePlan({
        ...values,
        id: openRow.id,
      });

      if (resultAction?.data?.success) {
        reset();
        onClose();
      }
    } catch (error) {
      // Catch and handle any errors during the process
      console.error("Error upadting Plan:", error);

      // Display error using notistack
      enqueueSnackbar("ERROR!! Updating Plan.", {
        variant: "error", // Set the snackbar type to 'error'
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      setLoading(false); // Ensure loading state is turned off in all cases
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">
                  {edit ? "Edit" : "Create"} Plan
                </Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register("isFree", { required: true })} // Changed field name to "isFree"
                        error={Boolean(errors.isFree)} // Error handling for "isFree" checkbox
                      />
                    }
                    label="isFree" // Label updated to "isFree"
                  />
                </FormGroup>

                {/* smart-eye limit */}
                <TextField
                  type="number"
                  label={
                    <>
                      Smart-Eye Limit <span style={{ color: "red" }}>*</span>{" "}
                      {/* Adding red star */}
                    </>
                  }
                  {...register("smartEyeLimit", {
                    required: true,
                    valueAsNumber: true, // Ensure the value is treated as a number
                  })}
                  error={Boolean(errors.smartEyeLimit)}
                  helperText={
                    errors.smartEyeLimit && errors.smartEyeLimit.message
                  } // Adjusted for better error message handling
                />

                {/* ai-chat limit */}
                <TextField
                  type="number"
                  name="aiChatLimit"
                  label={
                    <>
                      Ai-Chat Limit <span style={{ color: "red" }}>*</span>{" "}
                      {/* Adding red star */}
                    </>
                  }
                  {...register("aiChatLimit", {
                    required: true,
                    valueAsNumber: true, // Ensure the value is treated as a number
                  })}
                  error={Boolean(errors.aiChatLimit)}
                  helperText={errors.aiChatLimit && errors.aiChatLimit.message} // Adjusted for better error message handling
                />
                {/* docAi limit */}
                <TextField
                  type="number"
                  name="docAiLimit"
                  label={
                    <>
                      Doc-Ai Limit <span style={{ color: "red" }}>*</span>{" "}
                      {/* Adding red star */}
                    </>
                  }
                  {...register("docAiLimit", {
                    required: true,
                    valueAsNumber: true, // Ensure the value is treated as a number
                  })}
                  error={Boolean(errors.docAiLimit)}
                  helperText={errors.docAiLimit && errors.docAiLimit.message} // Adjusted for better error message handling
                />

                {/* DurationDays */}
                <TextField
                  type="number"
                  name="durationDays"
                  label={
                    <>
                      Duration Days
                      {isFree && <span style={{ color: "red" }}>*</span>}
                    </>
                  }
                  {...register("durationDays", {
                    setValueAs: (value) =>
                      value === "" ? undefined : Number(value),
                    validate: (value) =>
                      value === undefined || // Allow empty values
                      !Number.isNaN(value) || // Proper NaN check for numbers
                      "Please enter a valid number",
                  })}
                  error={Boolean(errors.durationDays)}
                  helperText={errors.durationDays?.message || ""}
                />

                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  loading={isUpdateLoading}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
