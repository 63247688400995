import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  IconButton,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { countries } from "countries-list";
import { Close } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import { AuthUserSchema } from "../../../forms-schema/form.schema";
import {
  useCreateAuthUserMutation,
  useGetDistributorsQuery,
  useUpdateAuthUserMutation,
} from "../../../redux/services/authUser.service";
import { useGetRolesQuery } from "../../../redux/services/role.service";
import { useGetOrganizationsQuery } from "../../../redux/services/organization.service";

AuthUserForm.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  openRow: PropTypes.object,
};

export default function AuthUserForm({ open, onClose, edit, openRow }) {
  const {
    isLoading: isRolesLoading,
    isFetching: isRolesFetching,
    data: roles,
  } = useGetRolesQuery();

  const {
    isLoading: isOrgLoading,
    isFetching: isOrgFetching,
    data: organizations,
  } = useGetOrganizationsQuery();

  const {
    isLoading: isDistributorLoading,
    isFetching: isDistributorFetching,
    data: distributors,
  } = useGetDistributorsQuery();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(AuthUserSchema(roles?.data)),
  });

  const [createAuthUser, { isLoading }] = useCreateAuthUserMutation();
  const [updateAuthUser, { isLoading: isUpdateLoading }] =
    useUpdateAuthUserMutation();

  const handleClick = async (values) => {
    let resultAction;
    if (!values.isSubDistributor) {
      values.distributorId = null;
    }
    if (edit) {
      resultAction = await updateAuthUser({
        ...values,
        parentDistributorId: values.distributorId,
        id: openRow.id,
      });
    } else {
      resultAction = await createAuthUser({ ...values });
    }
    if (resultAction?.data?.success) {
      onClose();
      reset();
    }
  };

  useEffect(() => {
    if (edit) {
      setValue("contactNumber", openRow.contactNumber);
      setValue("firstName", openRow.firstName);
      setValue("lastName", openRow.lastName);
      setValue("email", openRow.email);
      setValue("dateOfBirth", openRow.dateOfBirth);
      setValue("gender", openRow.gender);
      setValue("address", openRow.address);
      setValue("city", openRow.city);
      setValue("state", openRow.state);
      setValue("country", openRow.country);
      setValue("zipCode", openRow.zipCode);
      setValue("companyName", openRow.companyName);
      setValue("taxId", openRow.taxId);
      setValue(
        "roleIds",
        openRow.roles?.map((role) => role?.id),
      );
      setValue("organizationId", openRow.organizationId);
      setValue("isSubDistributor", !!openRow?.parentDistributorId);
      setValue("distributorId", openRow.parentDistributorId);
      setValue("purchasePrice", openRow?.purchasePrice);
    } else {
      reset();
    }
  }, [edit, open, openRow, reset, setValue]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">
                  {edit ? "Edit" : "Create"} Auth User
                </Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                <TextField
                  name="contactNumber"
                  label="Contact Number"
                  {...register("contactNumber", { required: true })}
                  error={!!errors.contactNumber}
                  helperText={errors.contactNumber?.message}
                  inputProps={{ "data-testid": "authUserContactNumber" }}
                />

                <TextField
                  name="firstName"
                  label="First Name"
                  {...register("firstName", { required: true })}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  inputProps={{ "data-testid": "authUserFirstName" }}
                />

                <TextField
                  name="lastName"
                  label="Last Name"
                  {...register("lastName", { required: true })}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  inputProps={{ "data-testid": "authUserLastName" }}
                />

                <TextField
                  name="email"
                  label="Email"
                  {...register("email", { required: true })}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  inputProps={{ "data-testid": "authUserEmail" }}
                />

                <TextField
                  type="date"
                  name="dateOfBirth"
                  label="Date of Birth"
                  {...register("dateOfBirth", { required: true })}
                  error={!!errors.dateOfBirth}
                  helperText={errors.dateOfBirth?.message}
                  inputProps={{ "data-testid": "authUserDateOfBirth" }}
                  InputLabelProps={{ shrink: true }}
                />

                <FormControl fullWidth error={!!errors.gender}>
                  <InputLabel id="gender" error={!!errors.gender}>
                    Gender
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={watch("gender") || ""}
                    label="Gender"
                    error={!!errors.gender}
                    labelId="gender"
                    name="gender"
                    {...register("gender", { required: true })}
                    inputProps={{ "data-testid": "authUserGender" }}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                  <FormHelperText error={!!errors.gender}>
                    {errors.gender?.message}
                  </FormHelperText>
                </FormControl>

                <TextField
                  name="address"
                  label="Address"
                  {...register("address", { required: true })}
                  error={!!errors.address}
                  helperText={errors.address?.message}
                  inputProps={{ "data-testid": "authUserAddress" }}
                />

                <TextField
                  name="city"
                  label="City"
                  {...register("city", { required: true })}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                  inputProps={{ "data-testid": "authUserCity" }}
                />

                <TextField
                  name="state"
                  label="State"
                  {...register("state", { required: true })}
                  error={!!errors.state}
                  helperText={errors.state?.message}
                  inputProps={{ "data-testid": "authUserState" }}
                />

                <FormControl fullWidth error={!!errors.country}>
                  <InputLabel id="country" error={!!errors.country}>
                    Country
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={watch("country") || ""}
                    label="Country"
                    error={!!errors.country}
                    labelId="country"
                    name="country"
                    {...register("country", { required: true })}
                    inputProps={{ "data-testid": "authUserCountry" }}
                  >
                    {Object.values(countries)
                      ?.toSorted((a, b) => a?.name.localeCompare(b?.name))
                      ?.map((country) => (
                        <MenuItem key={country.name} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>{errors.country?.message}</FormHelperText>
                </FormControl>

                <TextField
                  name="zipCode"
                  label="Zip Code"
                  {...register("zipCode", { required: true })}
                  error={!!errors.zipCode}
                  helperText={errors.zipCode?.message}
                  inputProps={{ "data-testid": "authUserZipCode" }}
                />

                <TextField
                  name="companyName"
                  label="Company Name"
                  {...register("companyName")}
                  error={!!errors.companyName}
                  helperText={errors.companyName?.message}
                  inputProps={{ "data-testid": "authUserCompanyName" }}
                />

                <TextField
                  name="taxId"
                  label="Tax ID"
                  {...register("taxId")}
                  error={!!errors.taxId}
                  helperText={errors.taxId?.message}
                  inputProps={{ "data-testid": "authUserTaxId" }}
                />

                <FormControl fullWidth error={!!errors.roleIds}>
                  <InputLabel id="roleIds" error={!!errors.roleIds}>
                    Role
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    multiple
                    value={watch("roleIds") || []}
                    label="Role"
                    error={!!errors.roleIds}
                    labelId="roleIds"
                    name="roleIds"
                    {...register("roleIds", { required: true })}
                    renderValue={(selected) =>
                      selected
                        ?.map(
                          (value) =>
                            roles?.data?.find((role) => role?.id === value)
                              ?.name,
                        )
                        ?.join(", ")
                    }
                    data-testid="authUserRoleIdSelect"
                    inputProps={{ "data-testid": "authUserRoleId" }}
                  >
                    {(isRolesLoading || isRolesFetching) && (
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        disabled
                      >
                        <CircularProgress size={20} />
                      </MenuItem>
                    )}
                    {!isRolesLoading &&
                      !isRolesFetching &&
                      roles?.data
                        ?.toSorted((a, b) => a?.name.localeCompare(b?.name))
                        ?.map((role) => (
                          <MenuItem key={role?.id} value={role?.id}>
                            <Checkbox
                              checked={watch("roleIds")?.indexOf(role?.id) > -1}
                            />
                            {role?.name}
                          </MenuItem>
                        ))}
                  </Select>
                  <FormHelperText>{errors.roleIds?.message}</FormHelperText>
                </FormControl>

                {watch("roleIds")?.includes(
                  roles?.data?.find((role) => role?.name === "distributor")?.id,
                ) && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...register("isSubDistributor")}
                          defaultChecked={watch("isSubDistributor")}
                          inputProps={{
                            "data-testid": "authUserIsSubDistributor",
                          }}
                        />
                      }
                      label="Is Sub Distributor"
                    />
                    <TextField
                      type="number"
                      name="purchasePrice"
                      label="Purchase Price"
                      {...register("purchasePrice")}
                      error={!!errors.purchasePrice}
                      helperText={errors.purchasePrice?.message}
                      inputProps={{ "data-testid": "distributorPurchasePrice" }}
                    />
                  </>
                )}

                {watch("roleIds")?.includes(
                  roles?.data?.find((role) => role?.name === "distributor")?.id,
                ) &&
                  watch("isSubDistributor") && (
                    <FormControl fullWidth error={!!errors.distributorId}>
                      <InputLabel
                        id="distributorId"
                        error={!!errors.distributorId}
                      >
                        Parent Distributor
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        value={watch("distributorId") || ""}
                        label="Parent Distributor"
                        error={!!errors.distributorId}
                        labelId="distributorId"
                        name="distributorId"
                        {...register("distributorId", { required: true })}
                        inputProps={{ "data-testid": "authUserDistributorId" }}
                      >
                        {(isDistributorLoading || isDistributorFetching) && (
                          <MenuItem
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            disabled
                          >
                            <CircularProgress size={20} />
                          </MenuItem>
                        )}
                        {!isDistributorLoading &&
                          !isDistributorFetching &&
                          distributors?.data
                            ?.toSorted((a, b) =>
                              a?.firstName?.localeCompare(b?.firstName),
                            )
                            ?.map((distributor) => (
                              <MenuItem
                                key={distributor?.id}
                                value={distributor?.id}
                              >
                                {distributor?.firstName} {distributor?.lastName}
                              </MenuItem>
                            ))}
                      </Select>
                      <FormHelperText>
                        {errors.distributorId?.message}
                      </FormHelperText>
                    </FormControl>
                  )}

                {watch("roleIds")?.includes(
                  roles?.data?.find((role) => role?.name === "organization")?.id,
                ) && (
                  <FormControl fullWidth error={!!errors.organizationId}>
                    <InputLabel
                      id="organizationId"
                      error={!!errors.organizationId}
                    >
                      Organization
                    </InputLabel>
                    <Select
                      id="demo-simple-select"
                      value={watch("organizationId") || ""}
                      label="Organization"
                      error={!!errors.organizationId}
                      labelId="organizationId"
                      name="organizationId"
                      {...register("organizationId", { required: true })}
                      inputProps={{ "data-testid": "authUserOrganizationId" }}
                    >
                      {(isOrgLoading || isOrgFetching) && (
                        <MenuItem
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          disabled
                        >
                          <CircularProgress size={20} />
                        </MenuItem>
                      )}
                      {!isOrgLoading &&
                        !isOrgFetching &&
                        organizations?.data
                          ?.toSorted((a, b) => a?.name.localeCompare(b?.name))
                          ?.map((org) => (
                            <MenuItem key={org?.id} value={org?.id}>
                              {org?.name}
                            </MenuItem>
                          ))}
                    </Select>
                    <FormHelperText>
                      {errors.organizationId?.message}
                    </FormHelperText>
                  </FormControl>
                )}

                <LoadingButton
                  loading={edit ? isUpdateLoading : isLoading}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
