import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  IconButton,
  CircularProgress,
  Button,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import {
  useChangePriorityMutation,
  useGetBlogsQuery,
} from "../../../redux/services/blog.service";
import { PrioritySchema } from "../../../forms-schema/form.schema";

PriorityForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  openRow: PropTypes.object,
  rows: PropTypes.array,
  loading: PropTypes.bool,
};

const TYPES = {
  SMARTON: "smarton",
  SUNBOTS: "sunbots",
};

export default function PriorityForm({ open, onClose }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(PrioritySchema),
  });

  const [type, setType] = useState(TYPES.SMARTON);

  useEffect(() => {
    reset();
  }, [open, reset]);

  const {
    isLoading: loading,
    isFetching: fetching,
    error,
    data,
  } = useGetBlogsQuery({
    filter: JSON.stringify({
      filters: [
        {
          field: "type",
          operator: "equals",
          value: type,
        },
      ],
      mainCondition: "and",
    }),
  });

  const [changePriority, { isLoading }] = useChangePriorityMutation();

  const handleClick = async (values) => {
    

    const prepareValues = values?.priority
      ?.filter((item) => item !== "")
      ?.map((item, index) => ({
        id: item,
        pinnedOrder: index + 1,
      }));
    const resultAction = await changePriority({
      type,
      blogsPinnedOrders: prepareValues,
    });

    if (resultAction?.data?.success) {
      onClose();
      reset();
    }
  };

  useEffect(() => {
    if (!loading && !fetching && !error) {
      const priorityOrder = data?.data
        ?.filter((item) => item?.isPinned)
        ?.toSorted((a, b) => a?.priority - b?.priority)
        ?.map((item) => item?.id);

      reset({
        priority: priorityOrder,
      });
    }
  }, [loading, fetching, error, reset, data]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">Change Priority</Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Box
                sx={{
                  display: "flex",
                  mt: 0,
                  mb: 2,
                }}
              >
                <Button
                  variant={type === TYPES.SMARTON ? "contained" : "outlined"}
                  onClick={() => {
                    setType(TYPES.SMARTON);
                  }}
                  aria-label={type === TYPES.SMARTON ? "and selected" : ""}
                >
                  Smarton
                </Button>
                <Button
                  variant={type === TYPES.SUNBOTS ? "contained" : "outlined"}
                  onClick={() => {
                    setType(TYPES.SUNBOTS);
                  }}
                  sx={{
                    ml: 2,
                  }}
                  aria-label={type === TYPES.SUNBOTS ? "and selected" : ""}
                >
                  Sunbots
                </Button>
              </Box>
              {(loading || fetching) && (
                <CircularProgress
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              )}
              {!loading && !fetching && error && (
                <Typography variant="body2" color="error">
                  {error?.message}
                </Typography>
              )}
              {!loading && !fetching && (
                <Stack spacing={3}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      error={Boolean(errors.priority?.[0])}
                    >
                      Priority 1
                    </InputLabel>
                    <Select
                      {...register("priority[0]", { required: true })}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Priority 1"
                      role="combobox"
                      name="priority[0]"
                      inputProps={{ "data-testid": "priority0" }}
                      value={watch("priority[0]") ?? ""}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {data?.data?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={Boolean(errors.priority?.[0])}>
                      {errors.priority?.[0]?.message}
                    </FormHelperText>
                  </FormControl>

                  {/* create two more priority */}
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      error={Boolean(errors.priority?.[1])}
                    >
                      Priority 2
                    </InputLabel>
                    <Select
                      {...register("priority[1]", { required: true })}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Priority 2"
                      role="combobox"
                      name="priority[1]"
                      inputProps={{ "data-testid": "priority1" }}
                      value={watch("priority[1]") ?? ""}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {data?.data?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={Boolean(errors.priority?.[1])}>
                      {errors.priority?.[1]?.message}
                    </FormHelperText>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      error={Boolean(errors.priority?.[2])}
                    >
                      Priority 3
                    </InputLabel>
                    <Select
                      {...register("priority[2]", { required: true })}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Priority 3"
                      role="combobox"
                      name="priority[2]"
                      inputProps={{ "data-testid": "priority2" }}
                      value={watch("priority[2]") ?? ""}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {data?.data?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.title}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={Boolean(errors.priority?.[2])}>
                      {errors.priority?.[2]?.message}
                    </FormHelperText>
                  </FormControl>

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    loading={isLoading}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              )}
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
