import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import Scrollbar from "../../../components/scrollbar/Scrollbar";
import { AddPlansFormSchema } from "../../../forms-schema/form.schema";
import { useCreatePlanMutation } from "../../../redux/services/plans.service";
import { useGetCountriesQuery } from "../../../redux/services/country.service";

export default function AddPlansForm({ open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(AddPlansFormSchema),
  });

  // Reset the Errors and FormFields when open or closed the Form drawer
  useEffect(() => {
    reset();
  }, [open, reset]);

  const [createPlan, { isLoading: isLoadingCreate }] = useCreatePlanMutation();

  const isFree = watch("isFree");

  const {
    isLoading: isCountriesLoading,
    isFetching: isCountriesFetching,
    data: countries,
  } = useGetCountriesQuery(null, {});

  const handleClick = async (values) => {
    console.log("Plans Submit Form:", values);
    setLoading(true);
    try {
      const resultAction = await createPlan({
        ...values,
      });

      if (resultAction?.data?.success) {
        reset();
        onClose();
      }
    } catch (error) {
      // Catch and handle any errors during the process
      console.error("Error occurred during Activating Plan:", error);

      // Display error using notistack
      enqueueSnackbar("ERROR!! Activating Plan.", {
        variant: "error", // Set the snackbar type to 'error'
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      setLoading(false); // Ensure loading state is turned off in all cases
    }
  };

  console.log(errors.description);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">Add Plan</Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>

            <Stack spacing={3}>
              {/* Checkbox True */}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register("isFree", { required: true })} // Changed field name to "isFree"
                      error={Boolean(errors.isFree)} // Error handling for "isFree" checkbox
                    />
                  }
                  label="isFree" // Label updated to "isFree"
                />
              </FormGroup>

              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  error={Boolean(errors.type)}
                >
                  Plan Type
                  <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  {...register("type", { required: true })}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Plans"
                  error={Boolean(errors.type)}
                >
                  <MenuItem value="RAZORPAY">RAZORPAY</MenuItem>
                  <MenuItem value="Custom">Custom</MenuItem>
                </Select>
                <FormHelperText error={Boolean(errors.type)}>
                  {errors.type?.message}
                </FormHelperText>
              </FormControl>

              {/* Plan Name */}
              <TextField
                name="name"
                type="string"
                label={
                  <>
                    Plan Name <span style={{ color: "red" }}>*</span>{" "}
                    {/* Adding red star */}
                  </>
                }
                {...register("name", { required: true })}
                error={Boolean(errors.name)}
                helperText={errors.name && errors.name.message} // Adjusted to properly show the error message
              />
              {/* Country */}
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  error={Boolean(errors.countryCode)}
                >
                  Country <span style={{ color: "red" }}>*</span>{" "}
                </InputLabel>
                <Select
                  {...register("countryCode", { required: true })}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Country"
                  error={Boolean(errors.countryCode)}
                >
                  {isCountriesLoading || isCountriesFetching ? (
                    <MenuItem disabled>
                      <CircularProgress size={24} />
                    </MenuItem>
                  ) : (
                    // Map over countries data when it's available
                    countries?.data.map((con) => (
                      <MenuItem key={con.id} value={con.countryMobileCode}>
                        {con.countryName}
                      </MenuItem>
                    ))
                  )}
                </Select>
                <FormHelperText error={Boolean(errors.countryCode)}>
                  {errors.countryCode?.message}
                </FormHelperText>
              </FormControl>

              {/* Plan Period */}
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  error={Boolean(errors.period)}
                >
                  Period <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  {...register("period", { required: true })}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Period"
                  error={Boolean(errors.period)}
                >
                  <MenuItem value="Daily">Daily</MenuItem>
                  <MenuItem value="Weekly">Weekly</MenuItem>
                  <MenuItem value="Monthly">Monthly</MenuItem>
                  <MenuItem value="Yearly">Yearly</MenuItem>
                </Select>
                <FormHelperText error={Boolean(errors.period)}>
                  {errors.period?.message}
                </FormHelperText>
              </FormControl>

              {/* Interval */}
              <TextField
                type="number"
                label={
                  <>
                    Interval <span style={{ color: "red" }}>*</span>{" "}
                    {/* Adding red star */}
                  </>
                }
                {...register("interval", {
                  required: true,
                  valueAsNumber: true, // Ensure the value is treated as a number
                })}
                error={Boolean(errors.interval)}
                helperText={errors.interval && errors.interval.message} // Adjusted for better error message handling
              />

              {/* Amount */}
              <TextField
                type="number"
                label={
                  <>
                    Amount <span style={{ color: "red" }}>*</span>{" "}
                    {/* Adding red star */}
                  </>
                }
                {...register("amount", {
                  required: true,
                  valueAsNumber: true, // Ensure the value is treated as a number
                })}
                error={Boolean(errors.amount)}
                helperText={errors.amount && errors.amount.message} // Adjusted for better error message handling
              />

              {/* Currency */}
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  error={Boolean(errors.currency)}
                >
                  Currency <span style={{ color: "red" }}>*</span>{" "}
                  {/* Adding red star */}
                </InputLabel>
                <Select
                  {...register("currency", { required: true })}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Currency"
                  error={Boolean(errors.currency)}
                >
                  <MenuItem value="INR">INR - Indian Rupees</MenuItem>
                  <MenuItem value="USD">USD - United States Dollar</MenuItem>
                </Select>
                <FormHelperText error={Boolean(errors.currency)}>
                  {errors.currency?.message} {/* Show error message */}
                </FormHelperText>
              </FormControl>

              {/* smart-eye limit */}
              <TextField
                type="number"
                label={
                  <>
                    Smart-Eye Limit <span style={{ color: "red" }}>*</span>{" "}
                    {/* Adding red star */}
                  </>
                }
                {...register("smartEyeLimit", {
                  required: true,
                  valueAsNumber: true, // Ensure the value is treated as a number
                })}
                error={Boolean(errors.smartEyeLimit)}
                helperText={
                  errors.smartEyeLimit && errors.smartEyeLimit.message
                } // Adjusted for better error message handling
              />

              {/* ai-chat limit */}
              <TextField
                type="number"
                name="aiChatLimit"
                label={
                  <>
                    Ai-Chat Limit <span style={{ color: "red" }}>*</span>{" "}
                    {/* Adding red star */}
                  </>
                }
                {...register("aiChatLimit", {
                  required: true,
                  valueAsNumber: true, // Ensure the value is treated as a number
                })}
                error={Boolean(errors.aiChatLimit)}
                helperText={errors.aiChatLimit && errors.aiChatLimit.message} // Adjusted for better error message handling
              />

              {/* docAi limit */}
              <TextField
                type="number"
                name="docAiLimit"
                label={
                  <>
                    Doc-Ai Limit <span style={{ color: "red" }}>*</span>{" "}
                    {/* Adding red star */}
                  </>
                }
                {...register("docAiLimit", {
                  required: true,
                  valueAsNumber: true, // Ensure the value is treated as a number
                })}
                error={Boolean(errors.docAiLimit)}
                helperText={errors.docAiLimit && errors.docAiLimit.message} // Adjusted for better error message handling
              />

              {/* Description */}
              <TextField
                type="string"
                name="description"
                label="Description"
                {...register("description", {})}
                error={Boolean(errors.description)} // Highlights the field if there is an error
                helperText={errors.description?.message || ""} // Ensure only the error message string is passed
              />

              {/* DurationDays */}
              <TextField
                type="number"
                name="durationDays"
                label={
                  <>
                    Duration Days
                    {isFree && <span style={{ color: "red" }}>*</span>}
                  </>
                }
                {...register("durationDays", {
                  setValueAs: (value) =>
                    value === "" ? undefined : Number(value),
                  validate: (value) =>
                    value === undefined || // Allow empty values
                    !Number.isNaN(value) || // Proper NaN check for numbers
                    "Please enter a valid number",
                })}
                error={Boolean(errors.durationDays)}
                helperText={errors.durationDays?.message || ""}
              />

              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={isLoadingCreate}
                onClick={handleSubmit(handleClick)}
              >
                Add
              </LoadingButton>
            </Stack>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
