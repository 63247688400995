import { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Grid,
  Stack,
  Button,
  Drawer,
  Divider,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  CircularProgress,
  IconButton,
  Box,
  Autocomplete,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import { useGetOrganizationsQuery } from "../../../redux/services/organization.service";
import Scrollbar from "../../../components/scrollbar";
import {
  useCreateDeviceMutation,
  useGetEventPlacesQuery,
} from "../../../redux/services/device.service";
import Iconify from "../../../components/iconify/Iconify";
import { DevicesSchema } from "../../../forms-schema/form.schema";

DeviceForm.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  onDownload: PropTypes.func,
};

export default function DeviceForm({ open, onClose, edit, onDownload }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(DevicesSchema),
  });

  const {
    isLoading: isOrgLoading,
    isFetching: isOrgFetching,
    data: organizations,
  } = useGetOrganizationsQuery();
  const [createDevice, { isLoading }] = useCreateDeviceMutation();

  const senderOrganization = watch("senderOrganization");
  const {
    data: eventPlaces,
    isSuccess: isSucessEventPlaces,
    isLoading: isLoadingEventPlaces,
  } = useGetEventPlacesQuery({
    organizationId: senderOrganization,
  });

  const [selectedEventPlace, setSelectedEventPlace] = useState("");
  const [filteredTags, setFilteredTags] = useState([]);

  const handleEventPlaceChange = (event) => {
    const selectedPlace = event.target.value;
    setSelectedEventPlace(selectedPlace);
    setValue("tag", ""); // Reset tag
    setFilteredTags([]);

    if (selectedPlace) {
      const tags =
        eventPlaces?.data
          .filter(
            (event) => event.eventPlace === selectedPlace && event.tag !== null,
          )
          .map((event) => event.tag) || [];
      setFilteredTags(tags);
    }
    setValue("eventPlace", selectedPlace);
  };

  const handleTagChange = (e) => {
    setValue("tag", e.target.value);
  };

  const [showDownload, setShowDownload] = useState(false);
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    reset(); // Reset form on open
    setShowDownload(false);
    setSelectedEventPlace("");
    setFilteredTags([]);
  }, [open, reset]);

  const handleClick = async (values) => {
    const resultAction = await createDevice(values);

    if (resultAction?.data?.success) {
      reset(); // Reset form fields using `reset()` from react-hook-form
      setSelectedEventPlace(""); // Clear state variables
      setFilteredTags([]); // Clear filtered tags
      setShowDownload(true);
      setDownloadData(resultAction?.data?.data?.map((item) => item.code));
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: 360, border: "none", overflow: "hidden" },
      }}
    >
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3 }}>
          <div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 0.5,
              }}
            >
              <Typography variant="subtitle1">
                {edit ? "Edit" : "Create"} Device
              </Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
            <Divider />
          </div>
          <form onSubmit={handleSubmit(handleClick)}>
            <Stack spacing={3}>
              <TextField
                type="number"
                name="numberOfDevices"
                label="Total Devices to Create"
                {...register("numberOfDevices", { required: true })}
                error={Boolean(errors.numberOfDevices)}
                helperText={
                  errors.numberOfDevices && errors.numberOfDevices.message
                }
              />
              <FormControl fullWidth>
                <InputLabel
                  id="sender-organization-label"
                  error={Boolean(errors.senderOrganization)}
                >
                  Sender Organization
                </InputLabel>
                <Select
                  {...register("senderOrganization", { required: true })}
                  labelId="sender-organization-label"
                  id="demo-simple-select"
                  inputProps={{ "data-testid": "senderOrganization" }}
                  label="Sender Organization"
                  error={Boolean(errors.senderOrganization)}
                  data-testid="senderOrganizationSelect"
                  value={senderOrganization}
                  onChange={(e) => {
                    setValue("senderOrganization", e.target.value);
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {(isOrgLoading || isOrgFetching) && (
                    <MenuItem
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      disabled
                    >
                      <CircularProgress size={20} />
                    </MenuItem>
                  )}
                  {!isOrgLoading &&
                    !isOrgFetching &&
                    organizations?.data
                      ?.toSorted((a, b) => a?.name.localeCompare(b?.name))
                      ?.map((org) => (
                        <MenuItem key={org?.id} value={org?.id}>
                          {org?.name}
                        </MenuItem>
                      ))}
                </Select>
                <FormHelperText
                  error={Boolean(errors.senderOrganization)}
                  data-testid="senderOrganizationErr"
                >
                  {errors.senderOrganization?.message}
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel
                  id="recipient-organization-label"
                  error={Boolean(errors.recipientOrganization)}
                >
                  Recipient Organization
                </InputLabel>
                <Select
                  {...register("recipientOrganization", { required: true })}
                  labelId="recipient-organization-label"
                  id="demo-simple-select"
                  inputProps={{ "data-testid": "recipientOrganization" }}
                  label="Recipient Organization"
                  error={Boolean(errors.recipientOrganization)}
                  value={watch("recipientOrganization")}
                  onChange={(e) => {
                    setValue("recipientOrganization", e.target.value);
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {(isOrgLoading || isOrgFetching) && (
                    <MenuItem
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      disabled
                    >
                      <CircularProgress size={20} />
                    </MenuItem>
                  )}
                  {!isOrgLoading &&
                    !isOrgFetching &&
                    organizations?.data
                      ?.toSorted((a, b) => a?.name.localeCompare(b?.name))
                      ?.map((org) => (
                        <MenuItem key={org.id} value={org.id}>
                          {org.name}
                        </MenuItem>
                      ))}
                </Select>
                <FormHelperText
                  error={Boolean(errors.recipientOrganization)}
                  data-testid="recipientOrganizationErr"
                >
                  {errors.recipientOrganization?.message}
                </FormHelperText>
              </FormControl>

              {/* EventPlace Autocomplete */}
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  freeSolo
                  id="eventPlace-autocomplete"
                  options={
                    isLoadingEventPlaces
                      ? []
                      : eventPlaces?.data?.map(
                          (eventPlace) => eventPlace.eventPlace,
                        ) || []
                  }
                  getOptionLabel={(option) => option || ""} // Ensure proper label
                  fullWidth
                  value={selectedEventPlace || ""}
                  onChange={(event, newValue) =>
                    handleEventPlaceChange({ target: { value: newValue } })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...register("eventPlace", { required: true })}
                      {...params}
                      label="Event Place"
                      fullWidth
                      error={Boolean(errors.eventPlace)}
                      helperText={
                        errors.eventPlace && errors.eventPlace.message
                      }
                      inputProps={{
                        ...params.inputProps,
                        "data-testid": "eventPlace",
                      }}
                    />
                  )}
                  renderNoOptions={() => (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ p: 2, textAlign: "center" }}
                    >
                      No Event Places Found
                    </Typography>
                  )}
                />
              </Box>

              {/* Tag Autocomplete */}
              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  freeSolo
                  id="tag-autocomplete"
                  options={filteredTags.length > 0 ? filteredTags : []}
                  value={watch("tag") || ""}
                  onChange={(event, newValue) =>
                    handleTagChange({ target: { value: newValue } })
                  }
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...register("tag")}
                      {...params}
                      label="Tag"
                      fullWidth
                      error={Boolean(errors.tag)}
                      helperText={errors.tag && errors.tag.message}
                      inputProps={{
                        ...params.inputProps,
                        "data-testid": "tag",
                      }}
                    />
                  )}
                />
              </Box>

              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={isLoading}
              >
                Submit
              </LoadingButton>

              {showDownload && (
                <Button
                  onClick={() => {
                    onDownload(downloadData);
                  }}
                >
                  <Iconify icon={`eva:download-fill`} />
                  Download
                </Button>
              )}
            </Stack>
          </form>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
}
