import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Box,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

// components
import { countries } from "countries-list";
import { Close } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import { DistributorSchema } from "../../../forms-schema/form.schema";
import {
  useCreateSubDistributorMutation,
  useUpdateDistributorMutation,
} from "../../../redux/services/authUser.service";
import { selectUser } from "../../../redux/slices/auth.slice";

DistributorForm.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  openRow: PropTypes.object,
};

export default function DistributorForm({ open, onClose, edit, openRow }) {
  const user = useSelector(selectUser);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(DistributorSchema),
  });

  const [createDistributor, { isLoading }] = useCreateSubDistributorMutation();
  const [updateDistributor, { isLoading: isUpdateLoading }] =
    useUpdateDistributorMutation();

  const handleClick = async (values) => {
    let resultAction;
    if (edit) {
      resultAction = await updateDistributor({
        ...values,
        id: openRow.id,
      });
    } else {
      resultAction = await createDistributor({
        ...values,
        distributorId: user?.id,
      });
    }
    if (resultAction?.data?.success) {
      onClose();
      reset();
    }
  };

  useEffect(() => {
    if (edit) {
      setValue("contactNumber", openRow.contactNumber);
      setValue("firstName", openRow.firstName);
      setValue("lastName", openRow.lastName);
      setValue("email", openRow.email);
      setValue("dateOfBirth", openRow.dateOfBirth);
      setValue("gender", openRow.gender);
      setValue("address", openRow.address);
      setValue("city", openRow.city);
      setValue("state", openRow.state);
      setValue("country", openRow.country);
      setValue("zipCode", openRow.zipCode);
      setValue("companyName", openRow.companyName);
      setValue("taxId", openRow.taxId);
      setValue("purchasePrice", openRow.purchasePrice);
    } else {
      reset();
    }
  }, [edit, open, openRow, reset, setValue]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">
                  {edit ? "Edit" : "Create"} Distributor
                </Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                <TextField
                  name="contactNumber"
                  label="Contact Number"
                  {...register("contactNumber", { required: true })}
                  error={!!errors.contactNumber}
                  helperText={errors.contactNumber?.message}
                  inputProps={{ "data-testid": "distributorContactNumber" }}
                />

                <TextField
                  name="firstName"
                  label="First Name"
                  {...register("firstName", { required: true })}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  inputProps={{ "data-testid": "distributorFirstName" }}
                />

                <TextField
                  name="lastName"
                  label="Last Name"
                  {...register("lastName", { required: true })}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  inputProps={{ "data-testid": "distributorLastName" }}
                />

                <TextField
                  name="email"
                  label="Email"
                  {...register("email", { required: true })}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  inputProps={{ "data-testid": "distributorEmail" }}
                />

                <TextField
                  type="date"
                  name="dateOfBirth"
                  label="Date of Birth"
                  {...register("dateOfBirth", { required: true })}
                  error={!!errors.dateOfBirth}
                  helperText={errors.dateOfBirth?.message}
                  inputProps={{ "data-testid": "distributorDateOfBirth" }}
                  InputLabelProps={{ shrink: true }}
                />

                <FormControl fullWidth error={!!errors.gender}>
                  <InputLabel id="gender" error={!!errors.gender}>
                    Gender
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={watch("gender") || ""}
                    label="Gender"
                    error={!!errors.gender}
                    labelId="gender"
                    name="gender"
                    {...register("gender", { required: true })}
                    inputProps={{ "data-testid": "distributorGender" }}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                  <FormHelperText error={!!errors.gender}>
                    {errors.gender?.message}
                  </FormHelperText>
                </FormControl>

                <TextField
                  name="address"
                  label="Address"
                  {...register("address", { required: true })}
                  error={!!errors.address}
                  helperText={errors.address?.message}
                  inputProps={{ "data-testid": "distributorAddress" }}
                />

                <TextField
                  name="city"
                  label="City"
                  {...register("city", { required: true })}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                  inputProps={{ "data-testid": "distributorCity" }}
                />

                <TextField
                  name="state"
                  label="State"
                  {...register("state", { required: true })}
                  error={!!errors.state}
                  helperText={errors.state?.message}
                  inputProps={{ "data-testid": "distributorState" }}
                />

                <FormControl fullWidth error={!!errors.country}>
                  <InputLabel id="country" error={!!errors.country}>
                    Country
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={watch("country") || ""}
                    label="Country"
                    error={!!errors.country}
                    labelId="country"
                    name="country"
                    {...register("country", { required: true })}
                    inputProps={{ "data-testid": "distributorCountry" }}
                  >
                    {Object.values(countries)
                      ?.toSorted((a, b) => a?.name.localeCompare(b?.name))
                      ?.map((country) => (
                        <MenuItem key={country.name} value={country.name}>
                          {country.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>{errors.country?.message}</FormHelperText>
                </FormControl>

                <TextField
                  name="zipCode"
                  label="Zip Code"
                  {...register("zipCode", { required: true })}
                  error={!!errors.zipCode}
                  helperText={errors.zipCode?.message}
                  inputProps={{ "data-testid": "distributorZipCode" }}
                />

                <TextField
                  name="companyName"
                  label="Company Name"
                  {...register("companyName")}
                  error={!!errors.companyName}
                  helperText={errors.companyName?.message}
                  inputProps={{ "data-testid": "distributorCompanyName" }}
                />

                <TextField
                  name="taxId"
                  label="Tax ID"
                  {...register("taxId")}
                  error={!!errors.taxId}
                  helperText={errors.taxId?.message}
                  inputProps={{ "data-testid": "distributorTaxId" }}
                />

                <TextField
                  type="number"
                  name="purchasePrice"
                  label="Purchase Price"
                  {...register("purchasePrice")}
                  error={!!errors.purchasePrice}
                  helperText={errors.purchasePrice?.message}
                  inputProps={{ "data-testid": "distributorPurchasePrice" }}
                />

                <LoadingButton
                  loading={edit ? isUpdateLoading : isLoading}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
