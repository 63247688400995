import { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @mui
import { Button, CircularProgress, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
//
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import {
  Abc,
  AdminPanelSettings,
  Domain,
  GroupAdd,
  KeyOutlined,
  LocalOffer,
  Lock,
  Public,
  SecurityUpdateGood,
  Translate,
  ViewColumn,
} from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { login } from "../../redux/slices/auth.slice";
import Header from "./header";
import Nav from "./nav";
import { resetFilterState } from "../../redux/slices/tableFilter.slice";
import { useGetPermissionsByUserIdQuery } from "../../redux/services/permission.service";
import abilityBuilder, { can, rules } from "../../casl/abilityBuilder";
import { resetDashboardFilters } from "../../redux/slices/dashboard.slice";
import { selectAbility, updateRules } from "../../redux/slices/casl.slice";
import SvgColor from "../../components/svg-color";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------
let prevMainRoute = "";

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const location = useLocation();
  const ability = useSelector(selectAbility);
  const [allNavMenus, setAllNavMenus] = useState([]);

  const { isLoading, isFetching, isError, error, refetch, data } =
    useGetPermissionsByUserIdQuery(
      {
        userId: user?.id,
      },
      {
        skip: !user?.id || !user?.isVerified,
      },
    );

  useEffect(() => {
    if (user && token) {
      dispatch(login({ data: user, token }));
      // user?.roles?.forEach((role) => {
      //   can("Is", role.name);
      // });
      const userRoles = user?.roles?.map((role) => ({
        action: "Is",
        subject: role.name,
      }));
      dispatch(
        updateRules({
          rules: userRoles,
        }),
      );
    }
  }, [user, token, dispatch]);

  useEffect(() => {
    const routeArr = location.pathname.split("/").slice(2);
    const currMainRoute = routeArr[0];
    if (prevMainRoute !== currMainRoute) {
      prevMainRoute = currMainRoute;
      dispatch(resetFilterState());
    }
    if (currMainRoute !== "app") {
      dispatch(resetDashboardFilters());
    }
  }, [location.pathname, navigate, dispatch]);

  useEffect(() => {
    setAllNavMenus([
      ability.can("get", "Organization-Google-Analytics") && {
        title: "dashboard",
        path: "/dashboard/app",
        icon: icon("ic_analytics"),
        navVisible: true,
      },
      ability.can("get", "Feature-Analytics") && {
        title: "Feature Analytics",
        path: "/dashboard/feature-analytics",
        icon: <AutoGraphIcon />,
        navVisible: true,
      },

      ability.can("get", "plans") && {
        title: "Plans",
        path: "/dashboard/plans",
        icon: <CalendarMonthIcon />,
        navVisible: true,
      },
      ability.can("get", "Users") && {
        title: "user",
        path: "/dashboard/user",
        icon: icon("ic_user"),
        navVisible: true,
      },
      ability.can("get", "Organizations") && {
        title: "organization",
        path: "/dashboard/organization",
        icon: <Domain sx={{ width: 1, height: 1 }} />,
        navVisible: true,
      },
      ability.can("get", "Devices") && {
        title: "device",
        path: "/dashboard/device",
        icon: icon("ic_device"),
        navVisible: true,
      },
      (ability.can("Is", "admin") || ability.can("Is", "support")) && {
        title: "Supported Device",
        path: "/dashboard/supported-device",
        icon: <SecurityUpdateGood sx={{ width: 1, height: 1 }} />,
        navVisible: true,
      },
      ability.can("get", "Distributor-Orders") && {
        title: "Order",
        path: "/dashboard/order",
        icon: icon("ic_cart"),
        navVisible: true,
      },
      ability.can("get", "Offers") && {
        title: "offer",
        path: "/dashboard/offer",
        icon: <LocalOffer sx={{ width: 1, height: 1 }} />,
        navVisible: true,
      },
      ability.can("get", "Supported-Languages") && {
        title: "Supported Language",
        path: "/dashboard/language",
        icon: <Translate />,
        navVisible: true,
      },
      ability.can("get", "Words-By-Language-Code") && {
        title: "Words",
        path: "/dashboard/words",
        icon: <Abc />,
        navVisible: true,
      },
      ability.can("get", "Countries") && {
        title: "Country",
        path: "/dashboard/country",
        icon: <Public sx={{ width: 1, height: 1 }} />,
        navVisible: true,
      },
      ability.can("post", "Blog") && {
        title: "Blog",
        path: "/dashboard/blog",
        icon: icon("ic_blog"),
        navVisible: true,
      },
      ability.can("get", "Roles") && {
        title: "Role",
        path: "/dashboard/roles",
        icon: <KeyOutlined sx={{ width: 1, height: 1 }} />,
        navVisible: true,
      },
      ability.can("get", "AuthUsers") && {
        title: "Auth User",
        path: "/dashboard/authUser",
        icon: <AdminPanelSettings sx={{ width: 1, height: 1 }} />,
        navVisible: true,
      },
      ability.can("get", "Distributors") && {
        title: "Distributor",
        path: "/dashboard/distributor",
        icon: <GroupAdd sx={{ width: 1, height: 1 }} />,
        navVisible: true,
      },
      ability.can("get", "Permissions") && {
        title: "Permission",
        path: "/dashboard/permission",
        icon: <Lock sx={{ width: 1, height: 1 }} />,
        navVisible: true,
      },
      ability.can("get", "Column-Access-Control") && {
        title: "Column Access Control",
        path: "/dashboard/column-access-control",
        icon: <ViewColumn />,
        navVisible: true,
      },
      ability.can("get", "User-Google-Analytics") && {
        path: "user/:id",
        navVisible: false,
      },
    ]);
  }, [ability, data]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!user?.isVerified) {
    return <Navigate to="/verify-user" />;
  }

  // if (
  //   ability.can("Is", "superadmin") &&
  //   location.pathname !== "/dashboard/permission"
  // ) {
  //   return <Navigate to="/dashboard/permission" />;
  // }

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      {!isLoading && !isFetching && !isError && (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          allNavMenus={allNavMenus}
        />
      )}

      <Main>
        {!isLoading && !isFetching && !isError && <Outlet />}

        {(isLoading || isFetching) && !isError && (
          <CircularProgress
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}

        {isError && (
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              gap: 2,
            }}
          >
            <Typography variant="h6" color="error">
              {error?.data?.messgae ||
                error?.message ||
                error?.error ||
                "Something went wrong!"}
            </Typography>
            <Button variant="contained" color="primary" onClick={refetch}>
              Reload
            </Button>
          </Container>
        )}
      </Main>
    </StyledRoot>
  );
}
