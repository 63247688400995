import { useEffect } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
// @mui
import { Box, Card, CardHeader } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
// components
import { useChart } from "../../../components/chart";

// ----------------------------------------------------------------------

SubscibedChart.propTypes = {
  // title: PropTypes.string,
  // subheader: PropTypes.string,
  chartData: PropTypes.object,
  duration: PropTypes.string,
};

export default function SubscibedChart({ chartData }) {
  // const chartLabels = chartData[duration].map((i) => i.label);

  // const chartSeries = chartData[duration].map((i) => i.value);

  const chartOptions = useChart({
    chart: {
      type: "donut",
    },
    labels: [
      "Premium Users",
      "Free Users",
      "Device + Premium Users",
      "Device Users",
    ],
    responsive: [
      {
        breakpoint: 360,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              formatter: (val) => {
                if (val.split(" ")[0] === "Total") return val.split(" ")[0];
                return `${val}`;
              },
            },
          },
          size: "75%",
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "left",
    },
    // colors: ['#D1E9FC', '#D0F2FF', '#FFF7CD', '#E9FCD4'],
  });

  return (
    <Card sx={{ height: "450px" }}>
      <CardHeader title="User Types" />
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart
          type="donut"
          series={[
            chartData?.totalUsersWithMobileAndSubscription || 0,
            chartData?.totalUsersWithMobileAndNotSubscription || 0,
            chartData?.totalUsersWithDeviceAndSubscription || 0,
            chartData?.totalUsersWithDeviceAndNotSubscription || 0,
          ]}
          // series={[{ data: chartData.map(i => i?.value) || [] }]}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
