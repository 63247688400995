import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { Mutex } from "async-mutex";
import { login, logout } from "./slices/auth.slice";
import { setToast } from "./slices/toast.slice";
import { matchUrlWithPathList } from "../utils/matchUrlPath";

// export const baseQuery = (baseUrl) =>
//   fetchBaseQuery({
//     baseUrl,
//     mode: "cors",
//     prepareHeaders: (headers, { getState }) => {
//       const { accessToken, uniqueId, id } = getState()?.auth?.user;
//       if (accessToken) {
//         headers.set("authorization", `Bearer ${accessToken}`);
//       }
//       if (uniqueId) {
//         headers.set("uniqueId", uniqueId);
//       }
//       if (id) {
//         headers.set("userId", id);
//       }
//       return headers;
//     },
//   });

const excludePaths = [
  {
    route: "/api/v1/auth/refreshToken",
    method: "POST",
  },
  {
    route: "/api/v1/auth/login",
    method: "POST",
  },
  {
    route: "/api/v1/auth/forgotPassword",
    method: "POST",
  },
  {
    route: "/api/v1/auth/resetPassword",
    method: "POST",
  },
  {
    route: "/api/v1/auth/change-generated-password",
    method: "POST",
  },
  {
    route: "/api/v1/permission/user/:userId",
    method: "GET",
  },
];
const mutex = new Mutex();
export const extendedBaseQuery = async (args, api, extraOptions) => {
  // const localEnvironment = localStorage.getItem("env");

  // Update base URL based on selected environment
  // const baseUrl =
  //   localEnvironment === "beta"
  //     ? process.env.REACT_APP_BETA_API_URL
  //     : process.env.REACT_APP_API_URL;

  const baseUrl = process.env.REACT_APP_API_URL;

  const modifiedBaseQuery = fetchBaseQuery({
    baseUrl,
    mode: "cors",
    prepareHeaders: (headers, { getState }) => {
      const { accessToken, uniqueId, id } = getState()?.auth?.user;
      if (accessToken) {
        headers.set("authorization", `Bearer ${accessToken}`);
      }
      if (uniqueId) {
        headers.set("uniqueId", uniqueId);
      }
      if (id) {
        headers.set("userId", id);
      }
      return headers;
    },
  });

  let preparedArgs;
  if (typeof args === "object") {
    preparedArgs = args;
  } else if (typeof args === "string") {
    preparedArgs = {
      url: args,
      method: "GET",
    };
  }

  if (
    !matchUrlWithPathList(preparedArgs, excludePaths) &&
    !matchUrlWithPathList(preparedArgs, api.getState().casl.routes)
  ) {
    // api.dispatch(
    //   setToast({
    //     variant: "error",
    //     message: "You are not authorized to perform this action",
    //     open: true,
    //   }),
    // );
    return {
      error: {
        status: 410,
        data: {
          message: "You are not authorized to perform this action",
          statusCode: 410,
          error: "Forbidden",
        },
      },
      meta: {
        request: {},
        response: {},
      },
    };
  }

  try {
    await mutex.waitForUnlock();

    let result = await modifiedBaseQuery(args, api, extraOptions);

    // if (
    //   !matchUrlWithPathList(args, excludePaths) ||
    //   matchUrlWithPathList(args, api.getState().casl.routes)
    // ) {
    //   return {
    //     error: {
    //       status: 403,
    //       data: { message: "You are not authorized to perform this action" },
    //     },
    //   };
    // }

    if (result.error) {
      if (result.error.status === 401) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          try {
            const userData = api.getState().auth?.user;
            const formData = {
              id: userData?.id,
              refreshToken: userData?.refreshToken,
            };
            const refreshResult = await modifiedBaseQuery(
              { url: "/auth/refreshToken", method: "POST", body: formData },
              api,
              extraOptions,
            );
            if (refreshResult?.data?.success) {
              // store the new token
              await api.dispatch(
                login({
                  data: {
                    ...refreshResult?.data?.data,
                    accessToken: refreshResult?.data?.data?.token?.accessToken,
                    refreshToken:
                      refreshResult?.data?.data?.token?.refreshToken,
                  },
                  token: refreshResult?.data?.data?.token?.accessToken,
                }),
              );
              // retry the initial query
              result = await modifiedBaseQuery(args, api, extraOptions);
            } else {
              localStorage.clear();
              api.dispatch(logout());
            }
          } finally {
            release();
          }
        } else {
          await mutex.waitForUnlock();
          result = await modifiedBaseQuery(args, api, extraOptions);
        }
      } else {
        api.dispatch(
          setToast({
            variant: "error",
            message: result?.error?.data?.message ?? result?.error?.error,
            open: true,
          }),
        );
      }
    }

    if (
      result?.meta &&
      (result?.meta?.response?.status === 201 ||
        result?.meta?.response?.status === 204)
    ) {
      api.dispatch(
        setToast({
          variant: "success",
          message: result?.data?.message,
          open: true,
        }),
      );
    }

    return result;
  } catch (error) {
    api.dispatch(
      setToast({
        variant: "error",
        message: "Please login again!",
        open: true,
      }),
    );
    return error;
  }
};
