import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Container, Typography, Button, Stack } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { Edit, Visibility } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  useDeleteOrganizationMutation,
  useGetOrganizationsQuery,
} from "../redux/services/organization.service";
import CustomTable from "../components/data-table/CustomDataTable";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";

import Protected from "../protected/Protected";
import OrganizationForm from "../sections/@dashboard/organization/organization.form";
import { selectAbility } from "../redux/slices/casl.slice";
import CustomDeleteConfirmation from "../components/dialog/CustomDeleteConfirmation";
import { selectUser } from "../redux/slices/auth.slice";
import { setOrganizationFilter } from "../redux/slices/dashboard.slice";

export default function OrganizationPage() {
  const [deleteOrganization, { isLoading: isDeleting }] =
    useDeleteOrganizationMutation();

  const ability = useSelector(selectAbility);

  const user = useSelector(selectUser);

  const dispatch = useDispatch();

  const [openRow, setOpenRow] = useState(null);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const navigate = useNavigate();

  const [edit, setEdit] = useState(false);

  const [openOrganizationForm, setOpenOrganizationForm] = useState(false);

  const handleUsageClick = (ev, row) => {
    dispatch(setOrganizationFilter(row?.id));
    navigate(`/dashboard/app`);
  };

  const handleCloseOrganizationForm = () => {
    setOpenOrganizationForm(false);
    setEdit(false);
    setOpenRow(null);
  };

  return (
    <Protected>
      <Helmet>
        <title>Organization</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Organization
          </Typography>
          {ability.can("post", "Organization") && (
            <Button
              variant="contained"
              onClick={() => {
                setOpenOrganizationForm(true);
                setEdit(false);
                setOpenRow(null);
              }}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Organization
            </Button>
          )}

          {(ability.can("post", "Organization") ||
            ability.can("patch", "Organization")) && (
            <OrganizationForm
              open={openOrganizationForm}
              onClose={handleCloseOrganizationForm}
              edit={edit}
              openRow={openRow}
            />
          )}
        </Stack>

        <Card>
          <Scrollbar>
            <CustomTable
              canSelect
              useDataQuery={useGetOrganizationsQuery}
              tableName="Organization"
              searchLabel="Search Organization..."
              searchCols={["name"]}
              defaultQueryParams={{
                isOrganizationDeviceInfo: true,
              }}
              actions={
                ability.can("patch", "Organization") ||
                ability.can("get", "Organization-Google-Analytics") ||
                ability.can("delete", "Organization")
                  ? {
                      field: "actions",
                      headerName: "",
                      type: "actions",
                      getActions: ({ row }) =>
                        [
                          ability.can(
                            "get",
                            "Organization-Google-Analytics",
                          ) ? (
                            <GridActionsCellItem
                              key="usage"
                              sx={{
                                color: "primary.main",
                              }}
                              icon={
                                <Visibility
                                  sx={{
                                    color: "primary.main",
                                  }}
                                />
                              }
                              label="Show Usage"
                              onClick={(ev) => handleUsageClick(ev, row)}
                              showInMenu
                            />
                          ) : null,
                          ability.can("patch", "Organization") ? (
                            <GridActionsCellItem
                              key="edit"
                              icon={<Edit />}
                              label="Edit"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setEdit(true);
                                setOpenOrganizationForm(true);
                              }}
                              disabled={
                                !user?.roles
                                  ?.map((role) => role?.name)
                                  ?.includes("admin") &&
                                (!row?.createdBy ||
                                  !user?.id === row?.createdBy)
                              }
                            />
                          ) : null,
                          ability.can("delete", "Organization") ? (
                            <GridActionsCellItem
                              key="delete"
                              sx={{
                                color: "error.main",
                              }}
                              icon={
                                <Iconify
                                  color="error.main"
                                  icon="eva:trash-2-outline"
                                />
                              }
                              label="Delete"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setOpenDeleteConfirmation(true);
                              }}
                            />
                          ) : null,
                        ].filter((action) => action !== null),
                    }
                  : null
              }
              // defaultVisibleCols={[
              //   "name",
              //   "type",
              //   "totalCodes",
              //   "activeCodes",
              //   "city",
              //   "state",
              //   "country",
              // ]}
            />
          </Scrollbar>
        </Card>
      </Container>

      {ability.can("delete", "Organization") && (
        <CustomDeleteConfirmation
          open={openDeleteConfirmation}
          onClose={() => setOpenDeleteConfirmation(false)}
          tableName="Organization"
          deleteFunc={deleteOrganization}
          row={openRow}
          isLoading={isDeleting}
        />
      )}
    </Protected>
  );
}
