import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Link,
  Drawer,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";

// hooks
import { Close } from "@mui/icons-material";
import useResponsive from "../../../hooks/useResponsive";
// components
import Logo from "../../../components/logo";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
import { selectUser } from "../../../redux/slices/auth.slice";
import { trimEmail } from "../../../utils/formatString";
//

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
  allNavMenus: PropTypes.array,
};

export default function Nav({ openNav, onCloseNav, allNavMenus }) {
  const { pathname } = useLocation();
  const [navMenus, setNavMenus] = useState([]);
  const isDesktop = useResponsive("up", "lg");
  const user = useSelector(selectUser);
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const newNavMenus = allNavMenus.filter((menu) => menu?.navVisible);
    // Update only if there is a change
    setNavMenus(newNavMenus);
  }, [allNavMenus]);

  const displayName =
    (user?.firstName && `${user?.firstName} ${user?.lastName}`) ??
    trimEmail(user?.email) ??
    "Guest";

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 3,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Logo />
        {!isDesktop && (
          <IconButton onClick={onCloseNav} sx={{ ml: "auto" }}>
            <Close />
          </IconButton>
        )}
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={user?.avatarURL} alt="photoURL">
              {displayName?.[0]?.toUpperCase()}
            </Avatar>

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {displayName}
              </Typography>

              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {user?.roles?.map((role) => role?.name).join(", ")}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navMenus} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          // ModalProps={{
          //   keepMounted: true,
          // }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
