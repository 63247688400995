import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import { UserSchema } from "../../../forms-schema/form.schema";
import { useUpdateUserMutation } from "../../../redux/services/user.service";

UserForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  openRow: PropTypes.object,
};

export default function UserForm({ open, onClose, edit, openRow }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(UserSchema),
  });

  const [updateUser, { isLoading: isUpdateLoading }] = useUpdateUserMutation();

  const handleClick = async (values) => {
    const resultAction = await updateUser({ ...values, id: openRow.id });
    if (resultAction?.data?.success) {
      reset();
      onClose();
    }
  };

  useEffect(() => {
    reset();
    if (edit) {
      setValue("firstName", openRow?.firstName);
      setValue("lastName", openRow?.lastName);
      setValue("email", openRow?.email);
    }
  }, [edit, openRow, setValue, open, reset]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">
                  {edit ? "Edit" : "Create"} User
                </Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                <TextField
                  name="firstName"
                  label="First Name"
                  {...register("firstName", { required: true })}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  inputProps={{
                    "data-testid": "firstName",
                  }}
                />

                <TextField
                  name="lastName"
                  label="Last Name"
                  {...register("lastName", { required: true })}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  inputProps={{
                    "data-testid": "lastName",
                  }}
                />

                <TextField
                  name="email"
                  label="Email"
                  {...register("email", { required: true })}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  inputProps={{
                    "data-testid": "email",
                  }}
                />

                {/* <FormControl fullWidth variant='outlined' error={Boolean(errors.userType)}>
                  <InputLabel id='demo-simple-select-label' error={!!errors.userType}>
                    User Type
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='User Type'
                    // onChange={handleChange}
                    value={watch('userType')}
                    {...register('userType', { required: true })}
                    error={!!errors.userType}
                  >
                    <MenuItem value='admin'>Admin</MenuItem>
                    <MenuItem value='user'>User</MenuItem>
                  </Select>
                </FormControl> */}

                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  loading={isUpdateLoading}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
