import { useCallback, useState } from "react";
import "./tiptapStyles.scss";
import { useCurrentEditor } from "@tiptap/react";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  AlignHorizontalCenter,
  AlignHorizontalLeft,
  AlignHorizontalRight,
  CallSplit,
  Code,
  Delete,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatClear,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  FormatStrikethrough,
  FormatUnderlined,
  HorizontalRule,
  Image,
  KeyboardReturn,
  LayersClear,
  LegendToggle,
  Link,
  LinkOff,
  Merge,
  PhonelinkErase,
  Redo,
  SubdirectoryArrowRight,
  Subject,
  TableBar,
  TableChart,
  Terminal,
  ToggleOn,
  Undo,
  YouTube,
} from "@mui/icons-material";
import CustomDialog from "../dialog/CustomDialog";

const MenuBar = () => {
  const { editor } = useCurrentEditor();

  const [color, setColor] = useState("#000000");

  const [openLinkForm, setOpenLinkForm] = useState(null);

  const [url, setUrl] = useState("");

  const [youtubeUrl, setYoutubeUrl] = useState("");

  const [width, setWidth] = useState("");

  const [height, setHeight] = useState("");

  const [openYoutubeForm, setOpenYoutubeForm] = useState(null);

  const [imageUri, setImageUri] = useState("");

  const [openImageForm, setOpenImageForm] = useState(null);

  const setLink = useCallback(() => {
    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }
    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
    setOpenLinkForm(null);
    setUrl("");
  }, [editor, url]);

  if (!editor) {
    return null;
  }

  const handleColorChange = (e) => {
    setColor(e.target.value);
    editor.chain().focus().setColor(e.target.value).run();
  };

  const btnCategorieWise = {
    actionChange: {
      label: "Action",
      element: [
        {
          label: "undo",
          onClick: () => editor.chain().focus().undo().run(),
          disabled: !editor.can().chain().focus().undo().run(),
          icon: <Undo />,
          tooltip: "Undo",
        },
        {
          label: "redo",
          onClick: () => editor.chain().focus().redo().run(),
          disabled: !editor.can().chain().focus().redo().run(),
          icon: <Redo />,
          tooltip: "Redo",
        },
      ],
    },
    clear: {
      label: "Clear",
      element: [
        {
          label: "clear marks",
          onClick: () => editor.chain().focus().unsetAllMarks().run(),
          icon: <FormatClear />,
          tooltip: "Clear Marks",
        },
        {
          label: "clear nodes",
          onClick: () => editor.chain().focus().clearNodes().run(),
          icon: <LayersClear />,
          tooltip: "Clear Nodes",
        },
      ],
    },
    textStyles: {
      label: "Text Styles",
      element: [
        {
          label: "bold",
          onClick: () => editor.chain().focus().toggleBold().run(),
          isActive: editor.isActive("bold"),
          icon: <FormatBold />,
          tooltip: "Bold",
        },
        {
          label: "italic",
          onClick: () => editor.chain().focus().toggleItalic().run(),
          isActive: editor.isActive("italic"),
          icon: <FormatItalic />,
          tooltip: "Italic",
        },
        {
          label: "strike",
          onClick: () => editor.chain().focus().toggleStrike().run(),
          isActive: editor.isActive("strike"),
          icon: <FormatStrikethrough />,
          tooltip: "Strike",
        },
        {
          label: "code",
          onClick: () => editor.chain().focus().toggleCode().run(),
          isActive: editor.isActive("code"),
          icon: <Code />,
          tooltip: "Code",
        },
        {
          label: "H1",
          onClick: () =>
            editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: editor.isActive("heading", { level: 1 }),
          icon: "H1",
        },
        {
          label: "H2",
          onClick: () =>
            editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: editor.isActive("heading", { level: 2 }),
          icon: "H2",
        },
        {
          label: "H3",
          onClick: () =>
            editor.chain().focus().toggleHeading({ level: 3 }).run(),
          isActive: editor.isActive("heading", { level: 3 }),
          icon: "H3",
        },
        {
          label: "H4",
          onClick: () =>
            editor.chain().focus().toggleHeading({ level: 4 }).run(),
          isActive: editor.isActive("heading", { level: 4 }),
          icon: "H4",
        },
        {
          label: "H5",
          onClick: () =>
            editor.chain().focus().toggleHeading({ level: 5 }).run(),
          isActive: editor.isActive("heading", { level: 5 }),
          icon: "H5",
        },
        {
          label: "H6",
          onClick: () =>
            editor.chain().focus().toggleHeading({ level: 6 }).run(),
          isActive: editor.isActive("heading", { level: 6 }),
          icon: "H6",
        },
        {
          label: "Paragraph",
          onClick: () => editor.chain().focus().setParagraph().run(),
          isActive: editor.isActive("paragraph"),
          icon: <Subject />,
        },
        {
          label: "Underline",
          onClick: () => editor.chain().focus().toggle().run(),
          isActive: editor.isActive("underline"),
          icon: <FormatUnderlined />,
        },
        {
          label: "Link",
          onClick: (event) => {
            setOpenLinkForm(event.currentTarget);
            const previousUrl = editor.getAttributes("link").href;
            setUrl(previousUrl);
          },
          // editor
          //   .chain()
          //   .focus()
          //   .extendMarkRange("link")
          //   .setLink({ href: "https://example.com" })
          //   .run(),
          isActive: editor.isActive("link"),
          icon: <Link />,
        },
        {
          label: "Unlink",
          onClick: () => editor.chain().focus().unsetLink().run(),
          isActive: editor.isActive("link"),
          icon: <LinkOff />,
        },
        {
          label: "text color",
          onClick: () => editor.chain().focus().setTextColor("#ff0000").run(),
          isActive: editor.isActive("textColor", { color: "#ff0000" }),
        },
      ],
    },
    textBlocks: {
      label: "Blocks",
      element: [
        {
          label: "bullet list",
          onClick: () => editor.chain().focus().toggleBulletList().run(),
          isActive: editor.isActive("bulletList"),
          icon: <FormatListBulleted />,
        },
        {
          label: "ordered list",
          onClick: () => editor.chain().focus().toggleOrderedList().run(),
          isActive: editor.isActive("orderedList"),
          icon: <FormatListNumbered />,
        },
        {
          label: "code block",
          onClick: () => editor.chain().focus().toggleCodeBlock().run(),
          isActive: editor.isActive("codeBlock"),
          icon: <Terminal />,
        },
        {
          label: "blockquote",
          onClick: () => editor.chain().focus().toggleBlockquote().run(),
          isActive: editor.isActive("blockquote"),
          icon: <FormatQuote />,
        },
      ],
    },
    textFormatting: {
      label: "Formatting",
      element: [
        {
          label: "Align left",
          onClick: () => editor.chain().focus().setTextAlign("left").run(),
          isActive: editor.isActive({ textAlign: "left" }),
          icon: <FormatAlignLeft />,
        },
        {
          label: "Align center",
          onClick: () => editor.chain().focus().setTextAlign("center").run(),
          isActive: editor.isActive({ textAlign: "center" }),
          icon: <FormatAlignCenter />,
        },
        {
          label: "Align right",
          onClick: () => editor.chain().focus().setTextAlign("right").run(),
          isActive: editor.isActive({ textAlign: "right" }),
          icon: <FormatAlignRight />,
        },
        {
          label: "Align justify",
          onClick: () => editor.chain().focus().setTextAlign("justify").run(),
          isActive: editor.isActive({ textAlign: "justify" }),
          icon: <FormatAlignJustify />,
        },
      ],
    },
    tables: {
      label: "Table",
      element: [
        {
          label: "Insert Table",
          onClick: () =>
            editor
              .chain()
              .focus()
              .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
              .run(),
          icon: <TableChart />,
        },
        {
          label: "Add Column Before",
          onClick: () => editor.chain().focus().addColumnBefore().run(),
          icon: "+C",
        },
        {
          label: "Add Column After",
          onClick: () => editor.chain().focus().addColumnAfter().run(),
          icon: "C+",
        },
        {
          label: "Delete Column",
          onClick: () => editor.chain().focus().deleteColumn().run(),
          icon: "-C",
        },
        {
          label: "Add Row Before",
          onClick: () => editor.chain().focus().addRowBefore().run(),
          icon: "+R",
        },
        {
          label: "Add Row After",
          onClick: () => editor.chain().focus().addRowAfter().run(),
          icon: "R+",
        },
        {
          label: "Delete Row",
          onClick: () => editor.chain().focus().deleteRow().run(),
          icon: "-R",
        },
        {
          label: "Delete Table",
          onClick: () => editor.chain().focus().deleteTable().run(),
          icon: <Delete />,
        },
        {
          label: "Merge Cells",
          onClick: () => editor.chain().focus().mergeCells().run(),
          icon: <Merge />,
        },
        {
          label: "Split Cell",
          onClick: () => editor.chain().focus().splitCell().run(),
          icon: <CallSplit />,
        },
        {
          label: "Toggle Header Column",
          onClick: () => editor.chain().focus().toggleHeaderColumn().run(),
          icon: (
            <LegendToggle
              sx={{
                transform: "rotate(90deg)",
              }}
            />
          ),
        },
        {
          label: "Toggle Header Row",
          onClick: () => editor.chain().focus().toggleHeaderRow().run(),
          icon: <LegendToggle />,
        },
        {
          label: "Toggle Header Cell",
          onClick: () => editor.chain().focus().toggleHeaderCell().run(),
          icon: <ToggleOn />,
        },
        // {
        //   label: "Merge Or Split",
        //   onClick: () => editor.chain().focus().mergeOrSplit().run(),
        //   icon: <Merge />,
        // },
        // {
        //   label: "setCellAttribute",
        //   onClick: () =>
        //     editor.chain().focus().setCellAttribute("colspan", 2).run(),
        // },
        // {
        //   label: "fixTables",
        //   onClick: () => editor.chain().focus().fixTables().run(),
        // },
        // {
        //   label: "goToNextCell",
        //   onClick: () => editor.chain().focus().goToNextCell().run(),
        // },
        // {
        //   label: "goToPreviousCell",
        //   onClick: () => editor.chain().focus().goToPreviousCell().run(),
        // },
      ],
    },
    utility: {
      label: "Utility",
      element: [
        {
          label: "horizontal rule",
          onClick: () => editor.chain().focus().setHorizontalRule().run(),
          icon: <HorizontalRule />,
        },
        {
          label: "hard break",
          onClick: () => editor.chain().focus().setHardBreak().run(),
          icon: <SubdirectoryArrowRight />,
        },
      ],
    },
    assets: {
      label: "Assets",
      element: [
        {
          label: "Insert Youtube Video",
          onClick: () => {
            setOpenYoutubeForm(true);
          },
          icon: <YouTube />,
        },
        {
          label: "Insert Image",
          onClick: (e) => {
            setOpenImageForm(e.currentTarget);
          },
          icon: <Image />,
        },
      ],
    },
  };

  return (
    <div className="menubar">
      {Object.keys(btnCategorieWise).map((key, index) => (
        <div key={index}>
          <h3
            style={{
              color: "gray",
              margin: "0.5rem",
            }}
          >
            {btnCategorieWise[key].label}
          </h3>
          {btnCategorieWise[key].element.map((btn, index) => (
            <Tooltip key={index} title={btn.label}>
              {btn.label === "text color" ? (
                <Button
                  onClick={handleColorChange}
                  sx={{
                    margin: "0.5rem",
                    fontSize: "1.5rem",
                    height: "40px",
                    width: "40px",
                  }}
                >
                  <input
                    type="color"
                    value={color}
                    onClick={handleColorChange}
                    onChange={handleColorChange}
                  />
                </Button>
              ) : (
                <IconButton
                  type="button"
                  onClick={btn.onClick}
                  className={btn.isActive ? "is-active" : ""}
                  disabled={btn.disabled}
                  sx={{
                    margin: "0.5rem",
                    fontSize: "1.5rem",
                    height: "40px",
                    width: "40px",
                    "&:disabled": {
                      color: "rgba(0, 0, 0, 0.26)!important",
                    },
                  }}
                >
                  {btn.icon}
                </IconButton>
              )}
            </Tooltip>
          ))}
        </div>
      ))}
      <Popover
        open={Boolean(openLinkForm)}
        onClose={() => setOpenLinkForm(false)}
        anchorEl={openLinkForm}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 2,
            width: 200,
          },
        }}
      >
        <TextField
          label="URL"
          variant="outlined"
          fullWidth
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <Button
          onClick={setLink}
          sx={{
            marginTop: 1,
          }}
        >
          Apply
        </Button>
      </Popover>

      <Popover
        open={Boolean(openImageForm)}
        onClose={() => setOpenImageForm(null)}
        anchorEl={openImageForm}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 2,
            width: 200,
          },
        }}
      >
        <TextField
          label="URL"
          variant="outlined"
          fullWidth
          value={imageUri}
          onChange={(e) => setImageUri(e.target.value)}
        />

        <Button
          onClick={() => {
            editor.commands.setImage({
              src: imageUri,
            });
            setOpenImageForm(null);
            setImageUri("");
          }}
          sx={{
            marginTop: 1,
          }}
        >
          Apply
        </Button>
      </Popover>

      <CustomDialog
        open={openYoutubeForm}
        onClose={() => setOpenYoutubeForm(null)}
        title="Insert Link"
        description={
          // add youtube link, hight and width
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <TextField
              label="URL"
              variant="outlined"
              fullWidth
              value={youtubeUrl}
              onChange={(e) => setYoutubeUrl(e.target.value)}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 1,
              }}
            >
              <TextField
                label="Width"
                variant="outlined"
                fullWidth
                value={width}
                onChange={(e) => setWidth(e.target.value)}
              />
              <TextField
                label="Height"
                variant="outlined"
                fullWidth
                value={height}
                onChange={(e) => setHeight(e.target.value)}
              />
            </Box>
          </Box>
        }
        onConfirm={() => {
          console.log(youtubeUrl);
          if (youtubeUrl === "") {
            return;
          }
          editor.commands.setYoutubeVideo({
            src: youtubeUrl,
            width: Math.max(320, parseInt(width, 10)) || 640,
            height: Math.max(180, parseInt(height, 10)) || 480,
          });
          setOpenYoutubeForm(null);
          setYoutubeUrl("");
          setWidth("");
          setHeight("");
        }}
      />
    </div>
  );
};

export default MenuBar;
