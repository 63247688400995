import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// @mui
import {
  Box,
  DialogTitle,
  IconButton,
  DialogContent,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  DialogActions,
  Button,
  Drawer,
  Chip,
  Autocomplete,
  FormHelperText,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import Iconify from "../iconify";
import useResponsive from "../../hooks/useResponsive";
// components

const FilterForm = ({
  open,
  onClose,
  columns,
  register,
  watch,
  fields,
  append,
  remove,
  errors,
  onSubmit,
  trigger,
  setValue,
  prevFormData,
  resetForm,
  tableName,
}) => {
  const [operatorOptions, setOperatorOptions] = useState({});
  const [filteredColumns, setFilteredColumns] = useState([]);

  useEffect(() => {
    setFilteredColumns(columns?.filter((column) => column.field !== "actions"));
  }, [columns]);

  useEffect(() => {
    if (prevFormData?.filters?.length > 0) {
      setOperatorOptions(
        prevFormData?.filters?.reduce((options, _, index) => {
          const selectedColumn = filteredColumns.find(
            (column) => column.field === prevFormData?.filters?.[index]?.field,
          );
          if (!selectedColumn) {
            return options;
          }
          return {
            ...options,
            [index]: getOperatorOptions(selectedColumn.type),
          };
        }, {}),
      );
    }
  }, [prevFormData, setValue, open]);

  const handleFilterChange = (e, index) => {
    const selectedColumn = filteredColumns.find(
      (column) => column.field === e.target.value,
    );
    if (selectedColumn) {
      setOperatorOptions((prevOptions) => ({
        ...prevOptions,
        [index]: getOperatorOptions(selectedColumn.type),
      }));
      setValue(`filters[${index}].field`, e.target.value);
      setValue(`filters[${index}].operator`, "");
    }
    trigger(`filters[${index}].field`);
  };

  const handleRemoveFilter = (e, index, lastIndex) => {
    e.preventDefault();
    remove(index);
    if (index === lastIndex) {
      setOperatorOptions((prevOptions) => {
        const newOptions = { ...prevOptions };
        delete newOptions[index];
        return newOptions;
      });
    } else {
      setOperatorOptions((prevOptions) => {
        const newOptions = { ...prevOptions };
        for (let i = index; i <= lastIndex; i += 1) {
          newOptions[i] = newOptions[i + 1];
        }
        delete newOptions[lastIndex];
        return newOptions;
      });
    }
  };

  const getOperatorOptions = (type) => {
    // Your logic to get operator options based on column type
    // For demonstration purposes, returning hardcoded options
    switch (type) {
      case "string":
        return [
          { value: "startsWith", label: "Starts With" },
          { value: "endsWith", label: "Ends With" },
          { value: "contains", label: "Contains" },
          { value: "notEquals", label: "Not Equals" },
        ];
      case "enum":
        return [
          { value: "startsWith", label: "Starts With" },
          { value: "endsWith", label: "Ends With" },
          { value: "contains", label: "Contains" },
          { value: "isAnyOf", label: "Is Any Of" },
          { value: "equals", label: "Equals" },
          { value: "notEquals", label: "Not Equals" },
          { value: "isEmpty", label: "Is Empty" },
          { value: "isNotEmpty", label: "Is Not Empty" },
        ];
      case "number":
        return [
          { value: "equals", label: "Equals" },
          { value: "greaterThan", label: "Greater Than" },
          { value: "lessThan", label: "Less Than" },
          { value: "between", label: "Between" },
        ];
      case "boolean":
        return [
          { value: "true", label: "Yes" },
          { value: "false", label: "No" },
        ];
      case "date":
        return [
          { value: "exact", label: "Equals" },
          { value: "notExact", label: "Not Equals" },
          { value: "after", label: "After" },
          { value: "onOrAfter", label: "On or After" },
          { value: "before", label: "Before" },
          { value: "onOrBefore", label: "On or Before" },
          { value: "betweenDate", label: "Between" },
          { value: "isEmpty", label: "Is Empty" },
          { value: "isNotEmpty", label: "Is Not Empty" },
        ];
      // Add more cases for other column types as needed
      default:
        return [
          { value: "startsWith", label: "Starts With" },
          { value: "endsWith", label: "Ends With" },
          { value: "contains", label: "Contains" },
          { value: "isAnyOf", label: "Is Any Of" },
          { value: "equals", label: "Equals" },
          { value: "notEquals", label: "Not Equals" },
          { value: "isEmpty", label: "Is Empty" },
          { value: "isNotEmpty", label: "Is Not Empty" },
        ];
    }
  };

  const handleOperatorChange = (e, index) => {
    setValue(`filters[${index}].operator`, e.target.value);
    if (e.target.value === "isAnyOf") {
      setValue(`filters[${index}].value`, []);
    }
    if (e.target.value === "isEmpty" || e.target.value === "isNotEmpty") {
      setValue(`filters[${index}].value`, null);
    }
    if (e.target.value === "true" || e.target.value === "false") {
      setValue(`filters[${index}].value`, e.target.value === "true");
    }
    trigger(`filters[${index}].operator`);
  };

  // useEffect(() => {
  //   setValue('mainCondition', prevFormData?.mainCondition ?? 'and')
  // }, [prevFormData, setValue])
  const filterRef = useRef();

  const isMedium = useResponsive("down", "md");

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: {
          width: isMedium ? "100%" : "80%",
          border: "none",
          overflow: "hidden",
          maxWidth: 700,
        },
      }}
      open={open}
      onClose={() => {
        onClose();
        // setOperatorOptions({})
      }}
      sx={{
        padding: 2,
      }}
      // PaperProps={{
      //   sx: {
      //     width: '100%',
      //   },
      // }}
    >
      <DialogTitle>Filter {tableName}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <GridCloseIcon />
      </IconButton>
      <DialogContent
        sx={
          {
            // width: '800px',
          }
        }
      >
        <Box
          sx={{
            display: "flex",
            mt: 0,
            mb: 2,
          }}
        >
          <Button
            variant={
              watch("mainCondition") === "and" ? "contained" : "outlined"
            }
            onClick={() => {
              setValue("mainCondition", "and");
            }}
            aria-label={watch("mainCondition") === "and" ? "and selected" : ""}
          >
            AND
          </Button>
          <Button
            variant={watch("mainCondition") === "or" ? "contained" : "outlined"}
            onClick={() => {
              setValue("mainCondition", "or");
            }}
            sx={{
              ml: 2,
            }}
            aria-label={watch("mainCondition") === "or" ? "or selected" : ""}
          >
            OR
          </Button>
        </Box>

        <form>
          {fields.map(
            (item, index) =>
              index < watch("filters")?.length && (
                <Grid container spacing={2} key={item.id} mt={0}>
                  <Grid item xs={3.5}>
                    <FormControl
                      fullWidth
                      error={Boolean(errors?.filters?.[index]?.field)}
                    >
                      <InputLabel
                        id="filter"
                        error={Boolean(errors?.filters?.[index]?.field)}
                      >
                        Select Filter
                      </InputLabel>
                      <Select
                        // {...item}
                        {...register(`filters[${index}].field`, {
                          required: "Select a filter",
                          // value: prevFormData?.filters?.[index]?.field,
                        })}
                        labelId="filter"
                        label="Select Filter"
                        onChange={(e) => {
                          handleFilterChange(e, index);
                        }}
                        value={watch(`filters[${index}].field`)}
                        inputRef={
                          index === fields?.length - 1 ? filterRef : null
                        }
                        error={Boolean(errors?.filters?.[index]?.field)}
                      >
                        {/* Dropdown options here */}
                        {filteredColumns
                          .filter((column) => column.field !== "actions")
                          .map((column) => (
                            <MenuItem key={column.field} value={column.field}>
                              {column.headerName}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText error>
                        {errors?.filters?.[index]?.field?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl
                      fullWidth
                      error={Boolean(errors?.filters?.[index]?.operator)}
                    >
                      <InputLabel
                        id="condition"
                        error={Boolean(errors?.filters?.[index]?.operator)}
                      >
                        Select Condition
                      </InputLabel>
                      <Select
                        // {...item}
                        {...register(`filters[${index}].operator`, {
                          required: "Select a condition",
                        })}
                        labelId="condition"
                        label="Select Condition"
                        value={watch(`filters[${index}].operator` ?? "")}
                        onChange={(e) => {
                          handleOperatorChange(e, index);
                        }}
                        error={Boolean(errors?.filters?.[index]?.operator)}
                        inputProps={{
                          "data-testid": `operator-${index}`,
                        }}
                        // helperText={watch(`filters[${index}].operator`) === '' && 'Select a operator'}
                      >
                        {operatorOptions[index] &&
                          operatorOptions[index].map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText error>
                        {errors?.filters?.[index]?.operator?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3.5}>
                    {watch("filters")?.[index]?.operator === "isAnyOf" && (
                      <Autocomplete
                        multiple={
                          watch("filters")?.[index]?.operator === "isAnyOf"
                        }
                        id="tags-filled"
                        options={[]}
                        // defaultValue={[top100Films[13].title]}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value?.map((option, index) => (
                            <Chip
                              key={index}
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        {...register(`filters[${index}].value`)}
                        value={watch(`filters[${index}].value`)}
                        onChange={(e, value) => {
                          setValue(`filters[${index}].value`, value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            type={
                              filteredColumns.find(
                                (column) =>
                                  column.field ===
                                  watch(`filters[${index}].field`),
                              )?.type ?? "text"
                            }
                            inputProps={{
                              ...params.inputProps,
                              "data-testid": `isAnyOf-input-${index}`,
                            }}
                            // {...item}
                            fullWidth
                            label="Value"
                          />
                        )}
                      />
                    )}

                    {watch("filters")?.[index]?.operator !== "isAnyOf" &&
                      watch("filters")?.[index]?.operator !== "true" &&
                      watch("filters")?.[index]?.operator !== "false" &&
                      watch("filters")?.[index]?.operator !== "isEmpty" &&
                      watch("filters")?.[index]?.operator !== "isNotEmpty" &&
                      watch("filters")?.[index]?.operator !== "between" &&
                      watch("filters")?.[index]?.operator !== "betweenDate" &&
                      columns.find(
                        (column) =>
                          column.field === watch(`filters[${index}].field`),
                      )?.type !== "boolean" && (
                        <>
                          <TextField
                            type={
                              filteredColumns.find(
                                (column) =>
                                  column.field ===
                                  watch(`filters[${index}].field`),
                              )?.type
                            }
                            // {...item}
                            {...register(`filters[${index}].value`)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            label="Value"
                            value={watch(`filters[${index}].value`)}
                            error={Boolean(errors?.filters?.[index]?.value)}
                            helperText={
                              errors?.filters?.[index]?.value?.message
                            }
                          />
                        </>
                      )}

                    {(watch("filters")?.[index]?.operator === "between" ||
                      watch("filters")?.[index]?.operator ===
                        "betweenDate") && (
                      <Grid item>
                        <TextField
                          type={
                            filteredColumns.find(
                              (column) =>
                                column.field ===
                                watch(`filters[${index}].field`),
                            )?.type
                          }
                          // {...item}
                          {...register(`filters[${index}].value[0]`)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          label="From"
                          value={watch(`filters[${index}].value[0]` ?? "")}
                          error={Boolean(errors?.filters?.[index]?.value)}
                          helperText={errors?.filters?.[index]?.value?.message}
                        />
                        <TextField
                          type={
                            filteredColumns.find(
                              (column) =>
                                column.field ===
                                watch(`filters[${index}].field`),
                            )?.type
                          }
                          // {...item}
                          {...register(`filters[${index}].value[1]`)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          label="To"
                          defaultValue={
                            prevFormData?.filters?.[index]?.value?.[1] ?? ""
                          }
                          sx={{
                            mt: 2,
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <IconButton
                      onClick={(e) => {
                        handleRemoveFilter(e, index, fields?.length - 1);
                      }}
                      sx={{
                        color: (theme) => theme.palette.error.main,
                        display: "flex",
                        height: "fit-content",
                      }}
                      aria-label={`Remove filter ${watch(
                        `filters[${index}].field`,
                      )}`}
                    >
                      <Iconify icon="eva:trash-fill" />
                    </IconButton>
                  </Grid>
                </Grid>
              ),
          )}
        </form>
        <Button
          onClick={async () => {
            await append({
              field: "",
              operator: "",
              value: null,
            });
            filterRef.current.focus();
          }}
          sx={{
            mt: 2,
          }}
          disabled={errors?.filters?.length > 0}
        >
          + Add Filter
        </Button>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              sx={{
                color: "error.main",
              }}
              onClick={resetForm}
              disabled={watch("filters")?.length === 0}
            >
              Reset
            </Button>
          </Grid>

          <Grid item>
            <Button
              onClick={() => {
                onSubmit();
              }}
            >
              Apply Filters
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Drawer>
  );
};

FilterForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  columns: PropTypes.array,
  control: PropTypes.object,
  register: PropTypes.func,
  reset: PropTypes.func,
  watch: PropTypes.func,
  fields: PropTypes.array,
  append: PropTypes.func,
  remove: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  setValue: PropTypes.func,
  prevFormData: PropTypes.object,
  resetForm: PropTypes.func,
  tableName: PropTypes.string,
  errors: PropTypes.object,
};

export default FilterForm;
