import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBaseQuery } from "../basequery";

export const organizationApi = createApi({
  reducerPath: "organizationApi",
  baseQuery: extendedBaseQuery,
  tagTypes: ["Organization"],
  endpoints: (builder) => ({
    getOrganizations: builder.query({
      query: (params) => {
        const query = {};
        if (params?.skip) query.skip = params.skip;
        if (params?.limit) query.limit = params.limit;
        if (params?.search) query.search = params.search;
        if (params?.filter) query.filter = params.filter;
        if (params?.sortBy) query.sortBy = params.sortBy;
        if (params?.orderBy) query.orderBy = params.orderBy;
        query.isOrganizationDeviceInfo = params?.isOrganizationDeviceInfo || false;

        return {
          url: `organization?${new URLSearchParams(query).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Organization"],
    }),
    getOrganization: builder.query({
      query: (id) => ({
        url: `organization/${id}`,
        method: "GET",
      }),
      providesTags: ["Organization"],
    }),
    createOrganization: builder.mutation({
      query: (body) => ({
        url: "organization",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Organization"],
    }),
    updateOrganization: builder.mutation({
      query: (body) => ({
        url: `organization/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Organization"],
    }),
    deleteOrganization: builder.mutation({
      query: (id) => ({
        url: `organization/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Organization"],
    }),
    uploadOrganizationLogo: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: "uploads/single",
          method: "POST",
          body: formData,
        };
      },
    }),
    organizationDeviceInfo: builder.query({
      query: (id) => `organization/deviceInfo?organizationId=${id}`,
      providesTags: ["Organization"],
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useGetOrganizationQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useUploadOrganizationLogoMutation,
  useOrganizationDeviceInfoQuery,
} = organizationApi;
