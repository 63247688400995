import { createSlice } from "@reduxjs/toolkit";
import { permissionApi } from "../services/permission.service";
import abilityBuilder, { can, rules } from "../../casl/abilityBuilder";

const caslSlice = createSlice({
  name: "casl",
  initialState: {
    ability: abilityBuilder,
    routes: [],
  },
  reducers: {
    updateRules: (state, action) => {
      action.payload.rules?.forEach((rule) => {
        can(rule.action, rule.subject);
      });
      state.ability.update(rules);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      permissionApi.endpoints.getPermissionsByUserId.matchFulfilled,
      (state, action) => {
        state.routes = action?.payload?.data;
        action?.payload?.data
          ?.filter((item) => item?.method && item.tag)
          ?.forEach((permission) => {
            can(permission?.method, permission?.tag);
          });
        abilityBuilder.update(rules);
        state.ability = abilityBuilder;
      },
    );
  },
});

export const selectAbility = (state) => state.casl.ability;

export const { updateRules } = caslSlice.actions;

export default caslSlice.reducer;
