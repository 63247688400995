import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import { useLinkUserMutation } from "../../../redux/services/user.service";
import { LinkCodeSchema } from "../../../forms-schema/form.schema";

LinkCodeForm.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  openRow: PropTypes.object,
  setRows: PropTypes.func,
};

export default function LinkCodeForm({ open, onClose, openRow }) {
  const [linkUserCode, { isLoading }] = useLinkUserMutation();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(LinkCodeSchema),
  });

  useEffect(() => {
    reset();
    // eslint-disable-next-line
  }, [open]);

  const handleClick = async (values) => {
    const resultAction = await linkUserCode({
      ...values,
      userId: openRow?.id,
    });
    if (resultAction?.data?.success) {
      onClose();
      reset();
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">Link Code</Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                <TextField
                  name="deviceCode"
                  label="Device Code"
                  {...register("deviceCode", { required: true })}
                  error={!!errors.deviceCode}
                  helperText={errors.deviceCode?.message}
                  inputProps={{ "data-testid": "deviceCode" }}
                />
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
