import { unwrapResult } from "@reduxjs/toolkit";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";

// @mui
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { useForgotPasswordMutation } from "../../../redux/services/auth.service";
import { ForgotPasswordSchema } from "../../../forms-schema/form.schema";
// ----------------------------------------------------------------------

export default function ForgotPasswordForm() {
  const { enqueueSnackbar } = useSnackbar();

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const handleClick = async (values) => {
    try {
      const resultAction = await forgotPassword(values);
      unwrapResult(resultAction);
      if (resultAction?.data?.success) {
        enqueueSnackbar("Reset Password link sent successfully", {
          variant: "success",
        });
        reset();
      }
    } catch (err) {
      enqueueSnackbar(err?.error, { variant: "error" });
      console.error("Failed to forgotPassword: ", err);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleClick)}>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          {...register("email", { required: true })}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          inputProps={{ "data-testid": "email" }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isLoading}
        >
          Proceed
        </LoadingButton>
      </Stack>
    </form>
  );
}
