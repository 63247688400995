import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBaseQuery } from "../basequery";

export const deviceApi = createApi({
  reducerPath: "deviceApi",
  baseQuery: extendedBaseQuery,
  tagTypes: ["Device"],
  endpoints: (builder) => ({
    getDevices: builder.query({
      query: (params) => {
        console.log("params:", params);
        const query = {};
        if (params?.skip) query.skip = params.skip;
        if (params?.limit) query.limit = params.limit;
        if (params?.search) query.search = params.search;
        if (params?.filter) query.filter = params.filter;
        if (params?.sortBy) query.sortBy = params.sortBy;
        if (params?.orderBy) query.orderBy = params.orderBy;

        if (params?.distributorId) query.distributorId = params.distributorId;
        if (params?.organizationId)
          query.organizationId = params.organizationId;
        if (params?.eventPlace) query.eventPlace = params.eventPlace;
        if (params?.tag) query.tag = params.tag;

        return {
          url: `device?${new URLSearchParams(query).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Device"],
    }),
    getDevice: builder.query({
      query: (id) => ({
        url: `device/${id}`,
        method: "GET",
      }),
      providesTags: ["Device"],
    }),
    createDevice: builder.mutation({
      query: (body) => ({
        url: "device",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Device"],
    }),
    updateDevice: builder.mutation({
      query: (body) => ({
        url: `device/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Device"],
    }),
    deleteDevice: builder.mutation({
      query: (id) => ({
        url: `device/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Device"],
    }),
    getEventPlaces: builder.query({
      query: (params) => {
        const query = {};
        if (params?.organizationId)
          query.organizationId = params.organizationId;
        return {
          url: `device/allEventPlaces?${new URLSearchParams(query).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Device"],
    }),
  }),
});

export const {
  useGetDevicesQuery,
  useGetDeviceQuery,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
  useGetEventPlacesQuery,
} = deviceApi;
