import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { Card, Container, Typography, Stack } from "@mui/material";

import { useSelector } from "react-redux";
import { Edit } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useGetPermissionsQuery } from "../redux/services/permission.service";
import CustomTable from "../components/data-table/CustomDataTable";
import Scrollbar from "../components/scrollbar";

import Protected from "../protected/Protected";
import PermissionForm from "../sections/@dashboard/permission/permission.form";
import { selectAbility } from "../redux/slices/casl.slice";

export default function PermissionPage() {
  const ability = useSelector(selectAbility);

  const [openRow, setOpenRow] = useState(null);

  const [edit, setEdit] = useState(false);

  const [openPermissionForm, setOpenPermissionForm] = useState(false);

  const handleClosePermissionForm = () => {
    setOpenPermissionForm(false);
    setEdit(false);
    setOpenRow(null);
  };

  return (
    <Protected>
      <Helmet>
        <title>Permission</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Permission
          </Typography>

          {ability.can("patch", "Permission") && (
            <PermissionForm
              open={openPermissionForm}
              onClose={handleClosePermissionForm}
              edit={edit}
              openRow={openRow}
            />
          )}
        </Stack>

        <Card>
          <Scrollbar>
            <CustomTable
              canSelect
              useDataQuery={useGetPermissionsQuery}
              tableName="Permission"
              searchLabel="Search Permission..."
              searchCols={["route", "description"]}
              // defaultVisibleCols={[
              //   "name",
              //   "type",
              //   "totalCodes",
              //   "activeCodes",
              //   "city",
              //   "state",
              //   "country",
              // ]}
              actions={
                ability.can("patch", "Permission")
                  ? {
                      field: "actions",
                      headerName: "",
                      type: "actions",
                      getActions: ({ row }) =>
                        [
                          ability.can("patch", "Permission") ? (
                            <GridActionsCellItem
                              key="edit"
                              icon={<Edit />}
                              label="Edit"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setEdit(true);
                                setOpenPermissionForm(true);
                              }}
                            />
                          ) : null,
                        ].filter((action) => action !== null),
                    }
                  : null
              }
            />
          </Scrollbar>
        </Card>
      </Container>
    </Protected>
  );
}
