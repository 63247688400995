import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Box,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";

// components
import { useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import {
  useAddLanguageMutation,
  useGetLanguagesQuery,
} from "../../../redux/services/language.service";
import { LanguageSchema } from "../../../forms-schema/form.schema";

AddLanguageForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  editingItem: PropTypes.object,
};

export default function AddLanguageForm({ open, onClose }) {
  const [LANGUAGES] = useState([
    { label: "English", id: "en" },
    { label: "Hindi", id: "hi" },
    { label: "Marathi", id: "mr" },
    { label: "Tamil", id: "ta" },
    { label: "Telugu", id: "te" },
    { label: "Kannada", id: "kn" },
    { label: "Malayalam", id: "ml" },
    { label: "Gujarati", id: "gu" },
    { label: "Bengali", id: "bn" },
    { label: "Punjabi", id: "pa" },
  ]);

  const {
    isLoading: isLanguagesLoading,
    isError: isLanguagesError,
    data: languages,
  } = useGetLanguagesQuery();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(LanguageSchema),
  });

  useEffect(() => {
    reset();
    // eslint-disable-next-line
  }, [open]);

  const [addLanguage, { isLoading }] = useAddLanguageMutation();

  const handleClick = async (values) => {
    const resultAction = await addLanguage({
      name: values.language,
      code: LANGUAGES.find((lang) => lang.label === values.language).id,
    });
    if (resultAction?.data?.success) {
      onClose();
      reset();
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none" },
        }}
      >
        <Scrollbar>
          <Stack height={"70vh"} spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">Create Language</Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                <FormControl fullWidth error={Boolean(errors.language)}>
                  <InputLabel
                    id="language-select"
                    error={Boolean(errors.language)}
                  >
                    Language
                  </InputLabel>
                  <Select
                    {...register("language", { required: true })}
                    labelId="language-select"
                    id="demo-simple-select"
                    label="Language"
                    role="combobox"
                    name="language"
                    inputProps={{ "data-testid": "language" }}
                    error={Boolean(errors.language)}
                  >
                    {LANGUAGES?.filter(
                      (lang) =>
                        !languages?.data?.find((l) => l?.code === lang?.id),
                    )?.map((lang) => (
                      <MenuItem key={lang.id} value={lang.label}>
                        {lang.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={Boolean(errors.language)}>
                    {errors.language?.message}
                  </FormHelperText>
                </FormControl>
                <LoadingButton
                  loading={isLoading}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
