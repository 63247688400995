// routes
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/scroll-to-top";
import { StyledChart } from "./components/chart";
import { setToast } from "./redux/slices/toast.slice";
import { setSelectedEnvironment } from "./redux/slices/environment.slice";

// ----------------------------------------------------------------------

export default function App() {
  const { enqueueSnackbar } = useSnackbar();

  const { open, message, variant } = useSelector((state) => state.toast);

  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      enqueueSnackbar(message, { variant });
      dispatch(
        setToast({
          open: false,
          message: "",
          variant: "default",
        }),
      );
    }
  }, [open, message, variant, enqueueSnackbar, dispatch]);

  // useEffect(() => {
  //   const env = localStorage.getItem("env");
  //   if (env?.length) {
  //     dispatch(setSelectedEnvironment(env));
  //   } else {
  //     dispatch(setSelectedEnvironment("live"));
  //   }
  // }, [dispatch]);

  return (
    <ThemeProvider>
      <ScrollToTop />
      <StyledChart />
      <Router />
    </ThemeProvider>
  );
}
