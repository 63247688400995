import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBaseQuery } from "../basequery";

export const supportedDeviceApi = createApi({
  reducerPath: "supportedDeviceApi",
  baseQuery: extendedBaseQuery,
  tagTypes: ["SupportedDevice"],
  endpoints: (builder) => ({
    getSupportedDevices: builder.query({
      query: (params) => {
        const query = {};
        if (params?.skip) query.skip = params.skip;
        if (params?.limit) query.limit = params.limit;
        if (params?.search) query.search = params.search;
        if (params?.filter) query.filter = params.filter;
        if (params?.sortBy) query.sortBy = params.sortBy;
        if (params?.orderBy) query.orderBy = params.orderBy;

        return {
          url: `supported-device?${new URLSearchParams(query).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["SupportedDevice"],
    }),
    getSupportedDevice: builder.query({
      query: (id) => `supported-device/${id}`,
      providesTags: ["SupportedDevice"],
    }),
    createSupportedDevice: builder.mutation({
      query: (body) => ({
        url: "supported-device",
        method: "POST",
        body,
      }),
      invalidatesTags: ["SupportedDevice"],
    }),
    updateSupportedDevice: builder.mutation({
      query: (body) => ({
        url: `supported-device/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["SupportedDevice"],
    }),
    deleteSupportedDevice: builder.mutation({
      query: (id) => ({
        url: `supported-device/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SupportedDevice"],
    }),
  }),
});

export const {
  useGetSupportedDevicesQuery,
  useGetSupportedDeviceQuery,
  useCreateSupportedDeviceMutation,
  useUpdateSupportedDeviceMutation,
  useDeleteSupportedDeviceMutation,
} = supportedDeviceApi;
