import PropTypes from "prop-types";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button,
  Box,
  Avatar,
} from "@mui/material";
// component
import { useRef } from "react";
import { useSelector } from "react-redux";
import Iconify from "../../../components/iconify";
import { selectAbility } from "../../../redux/slices/casl.slice";

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 320,
  // transition: theme.transitions.create(["box-shadow", "width"], {
  //   easing: theme.transitions.easing.easeInOut,
  //   duration: theme.transitions.duration.shorter,
  // }),
  // "&.Mui-focused": {
  //   width: 320,
  //   boxShadow: theme?.customShadows?.z8,
  // },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  onFilterName: PropTypes.func,
  icon: PropTypes.string,
  onActionClick: PropTypes.func,
  selected: PropTypes.array,
  placeholder: PropTypes.string,
  onOpenFilter: PropTypes.func,
  extraHeaderField: PropTypes.node,
  resetForm: PropTypes.func,
  totalFilterCount: PropTypes.number,
};

export default function UserListToolbar({
  numSelected,
  onFilterName,
  icon,
  onActionClick,
  selected,
  placeholder = "Search user...",
  onOpenFilter,
  extraHeaderField,
  resetForm,
  totalFilterCount,
}) {
  const handleResetFilter = () => {
    resetForm();
  };

  const searchRef = useRef(null);

  const ability = useSelector(selectAbility);

  return (
    <StyledRoot
    // sx={{
    //   ...(numSelected > 0 && {
    //     color: "primary.main",
    //     bgcolor: "primary.lighter",
    //   }),
    // }}
    >
      <StyledSearch
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onFilterName(searchRef.current.value);
          }
        }}
        onChange={(e) => {
          if (e.target.value === "") {
            onFilterName("");
          }
        }}
        placeholder={placeholder}
        inputRef={searchRef}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              // disableRipple
              onClick={() => {
                onFilterName(searchRef.current.value);
              }}
              aria-label="search"
            >
              <Iconify icon="eva:search-fill" />
            </IconButton>
          </InputAdornment>
        }
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          width: "100%",
          justifyContent: extraHeaderField ? "space-between" : "flex-end",
        }}
      >
        {extraHeaderField && extraHeaderField}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Tooltip
            title={`${icon || "Delete"}`}
            sx={{
              zIndex: numSelected > 0 ? 9999 : -1,
              opacity: numSelected > 0 ? 1 : 0,
            }}
          >
            <IconButton
              onClick={() => {
                onActionClick(selected);
              }}
            >
              <Iconify icon={`eva:${icon || "trash-2"}-fill`} />
            </IconButton>
          </Tooltip>
          {(ability.can("Is", "admin") || ability.can("Is", "superadmin")) && (
            <>
              <Button
                disableRipple
                color="inherit"
                // endIcon={<Iconify icon='ic:round-filter-list' />}
                onClick={onOpenFilter}
              >
                Filters
                {totalFilterCount > 0 && (
                  <Avatar
                    sx={{
                      width: 20,
                      height: 20,
                      bgcolor: "rgb(186, 213, 241, 0.8)",
                      color: "common.black",
                      fontSize: 12,
                      border: "1px solid",
                      borderColor: "primary.main",
                      marginLeft: 1,
                    }}
                    data-testid="filter-count-badge"
                  >
                    {totalFilterCount}
                  </Avatar>
                )}
              </Button>
              <Button
                disableRipple
                color="error"
                // endIcon={<Iconify icon="eva:refresh-fill" color="error.main" />}
                onClick={(e) => {
                  searchRef.current.value = "";
                  handleResetFilter(e);
                }}
              >
                Reset
              </Button>
            </>
          )}
        </Box>
      </Box>
    </StyledRoot>
  );
}
