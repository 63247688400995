import { useEffect } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete,
  FormHelperText,
  Box,
  IconButton,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import {
  useCreateSupportedDeviceMutation,
  useUpdateSupportedDeviceMutation,
} from "../../../redux/services/supportedDevice.service";
import Scrollbar from "../../../components/scrollbar";
import { SupportedDevicesSchema } from "../../../forms-schema/form.schema";

SupportedDeviceForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  openRow: PropTypes.object,
  deviceOptions: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default function SupportedDeviceForm({
  open,
  onClose,
  edit,
  openRow,
  deviceOptions,
  isLoading,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(SupportedDevicesSchema(deviceOptions, edit)),
  });

  const [createSupportedDevice, { isLoading: isCreating }] =
    useCreateSupportedDeviceMutation();
  const [editSupportedDevice, { isLoading: isUpdating }] =
    useUpdateSupportedDeviceMutation();

  useEffect(() => {
    if (edit) {
      setValue("deviceName", openRow.deviceName);
      setValue("androidVersion", openRow.androidVersion);
      setValue("isSupported", openRow.isSupported ? "true" : "false");
    } else {
      reset();
    }
  }, [edit, openRow, reset, setValue, open]);

  const handleClick = async (values) => {
    if (values.androidVersion === "") {
      delete values.androidVersion;
    } else {
      values.androidVersion = +values.androidVersion;
    }
    values.isSupported = values.isSupported === "true";
    let resultAction;
    if (edit) {
      resultAction = await editSupportedDevice({ ...values, id: openRow.id });
    } else {
      resultAction = await createSupportedDevice({ ...values });
    }
    if (resultAction.data.success) {
      onClose();
      reset();
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">
                  {edit ? "Edit" : "Create"} Supported Device
                </Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                <Autocomplete
                  freeSolo
                  id="combo-box-demo"
                  options={
                    isLoading
                      ? []
                      : deviceOptions?.map((option) => option.deviceName)
                  }
                  sx={{ width: 300 }}
                  defaultValue={edit ? openRow?.deviceName : null}
                  renderInput={(params) => (
                    <TextField
                      {...register("deviceName", { required: true })}
                      {...params}
                      fullWidth
                      label="Device Name"
                      error={!!errors.deviceName}
                      helperText={
                        errors.deviceName && errors.deviceName.message
                      }
                      inputProps={{
                        ...params.inputProps,
                        "data-testid": "deviceName",
                      }}
                    />
                  )}
                />

                <TextField
                  fullWidth
                  type="number"
                  label="Android Version"
                  {...register("androidVersion")}
                  error={!!errors.androidVersion}
                  helperText={
                    errors.androidVersion && errors.androidVersion.message
                  }
                  inputProps={{ "data-testid": "androidVersion" }}
                />

                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    error={!!errors.isSupported}
                  >
                    Supported
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...register("isSupported", { required: true })}
                    label="Supported"
                    defaultValue={edit ? openRow?.isSupported : null}
                    error={!!errors.isSupported}
                    inputProps={{ "data-testid": "isSupported" }}
                  >
                    <MenuItem value={"true"}>Yes</MenuItem>
                    <MenuItem value={"false"}>No</MenuItem>
                  </Select>
                  <FormHelperText error={Boolean(errors.isSupported)}>
                    {errors.isSupported?.message}
                  </FormHelperText>
                </FormControl>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  loading={isCreating || isUpdating}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
