import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader, Typography } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
// components
import { useChart } from "../../../components/chart";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));
// ----------------------------------------------------------------------

LanguageChart.propTypes = {
  // title: PropTypes.string,
  // subheader: PropTypes.string,
  // chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
  duration: PropTypes.string,
};

export default function LanguageChart({ chartData }) {
  const theme = useTheme();

  const [isChartDataZero, setIsChartDataZero] = useState(false);

  useEffect(() => {
    if (chartData && chartData.length > 0) {
      const total = chartData.reduce((a, b) => a + b.value, 0);
      setIsChartDataZero(total === 0);
    }
  }, [chartData]);

  // const chartLabels = chartData[duration].map(i => i.label)

  // const chartSeries = chartData[duration].map(i => i.value)

  const chartOptions = useChart({
    colors: [
      theme.palette.primary.main,
      theme.palette.error.main,
      theme.palette.info.main,
      theme.palette.warning.main,
    ],
    labels: chartData?.map((i) => i?.label),
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: "center" },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card sx={{ height: "450px" }}>
      <CardHeader title="Languages Used" />
      {isChartDataZero ? (
        <StyledChartWrapper
          dir="ltr"
          sx={{
            height: "320px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>No data</Typography>
        </StyledChartWrapper>
      ) : (
        <StyledChartWrapper dir="ltr">
          <ReactApexChart
            type="pie"
            series={chartData?.map((i) => i?.value) || []}
            options={chartOptions}
            height={280}
          />
        </StyledChartWrapper>
      )}
    </Card>
  );
}
