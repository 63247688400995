import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { LoadingButton } from "@mui/lab";
import { countries as countriesList } from "countries-list";
import { Close } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import {
  useCreateCountryMutation,
  useUpdateCountryMutation,
} from "../../../redux/services/country.service";
import { CountrySchema } from "../../../forms-schema/form.schema";

CountryForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  openRow: PropTypes.object,
  rows: PropTypes.array,
  loading: PropTypes.bool,
};

export default function CountryForm({
  open,
  onClose,
  rows,
  edit,
  openRow,
  loading,
}) {
  const [countries] = useState(Object.values(countriesList));

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(CountrySchema),
  });

  useEffect(() => {
    if (edit) {
      setValue("countryName", openRow?.countryName);
      setValue("countryMobileCode", openRow?.countryMobileCode?.split("+")[1]);
    } else {
      reset();
    }
  }, [open, edit, openRow, reset, setValue]);

  const [createCountry, { isLoading }] = useCreateCountryMutation();
  const [updateCountry, { isLoading: isUpdating }] = useUpdateCountryMutation();

  const handleClick = async (values) => {
    let resultAction;
    if (edit) {
      resultAction = await updateCountry({
        id: openRow?.id,
        countryName: values.countryName,
        countryMobileCode: `+${values.countryMobileCode}`,
      });
    } else {
      resultAction = await createCountry({
        countryName: values.countryName,
        countryMobileCode: `+${values.countryMobileCode}`,
      });
    }
    if (resultAction?.data?.success) {
      onClose();
      reset();
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">
                  {edit ? "Edit" : "Add"} Country
                </Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    error={Boolean(errors.countryName)}
                  >
                    Country Name
                  </InputLabel>
                  <Select
                    {...register("countryName", { required: true })}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Country Name"
                    role="combobox"
                    name="countryName"
                    inputProps={{ "data-testid": "countryName" }}
                    value={watch("countryName") ?? ""}
                  >
                    {loading && (
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        disabled
                      >
                        <CircularProgress size={20} />
                      </MenuItem>
                    )}
                    {countries
                      ?.filter((country) =>
                        edit
                          ? true
                          : !rows?.some(
                              (row) => row?.countryName === country?.name,
                            ),
                      )
                      ?.toSorted((a, b) => a?.name?.localeCompare(b?.name))
                      ?.map((country) => (
                        <MenuItem key={country?.name} value={country?.name}>
                          {country?.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText error={Boolean(errors.countryName)}>
                    {errors.countryName?.message}
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    error={Boolean(errors.countryMobileCode)}
                  >
                    Country Code
                  </InputLabel>
                  <Select
                    {...register("countryMobileCode", { required: true })}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Country Code"
                    role="combobox"
                    name="countryMobileCode"
                    inputProps={{ "data-testid": "countryMobileCode" }}
                    value={watch("countryMobileCode") ?? ""}
                  >
                    {watch("countryName") &&
                      countries
                        ?.find(
                          (country) => country?.name === watch("countryName"),
                        )
                        ?.phone.map((code) => (
                          <MenuItem key={code} value={code}>
                            {code}
                          </MenuItem>
                        ))}
                  </Select>
                  <FormHelperText error={Boolean(errors.countryMobileCode)}>
                    {errors.countryMobileCode?.message}
                  </FormHelperText>
                </FormControl>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  loading={edit ? isUpdating : isLoading}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
