import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useSelector } from "react-redux";
// @mui
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import CustomTable from "../components/data-table/CustomDataTable";

// components
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
// sections
import Protected from "../protected/Protected";
import {
  useDeleteOrderMutation,
  useGetOrdersQuery,
  useUpdateOrderStatusMutation,
} from "../redux/services/order.service";
import OrderForm from "../sections/@dashboard/order/order.form";
import { selectAbility } from "../redux/slices/casl.slice";
import CustomDeleteConfirmation from "../components/dialog/CustomDeleteConfirmation";
import { selectUser } from "../redux/slices/auth.slice";

export default function OrderPage() {
  const [openOrderForm, setOpenOrderForm] = useState(false);

  const [edit, setEdit] = useState(false);

  const [openRow, setOpenRow] = useState({});

  const [deleteOrder, { isLoading: isDeletedLoading }] =
    useDeleteOrderMutation();

  const [updateOrderStatus, { isLoading: isOrderStatusLoading }] =
    useUpdateOrderStatusMutation();

  const ability = useSelector(selectAbility);

  const user = useSelector(selectUser);

  const [openApproveConfirmation, setOpenApproveConfirmation] = useState(false);

  const [openRejectConfirmation, setOpenRejectConfirmation] = useState(false);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const [orderType, setOrderType] = useState("all");

  const handleCloseFilter = () => {
    setOpenOrderForm(false);
    setEdit(false);
    setOpenRow(null);
  }

  return (
    <Protected>
      <Helmet>
        <title> Orders </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Orders
          </Typography>
          {ability.can("post", "Distributor-Order") && (
            <Button
              variant="contained"
              onClick={setOpenOrderForm}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Create Order
            </Button>
          )}
          {(ability.can("post", "Distributor-Order") ||
            ability.can("patch", "Distributor-Order")) && (
            <OrderForm
              open={openOrderForm}
              onClose={handleCloseFilter}
              edit={edit}
              openRow={openRow}
            />
          )}
        </Stack>

        <Card>
          <Scrollbar>
            <CustomTable
              canSelect
              useDataQuery={useGetOrdersQuery}
              tableName="Order"
              searchLabel="Search order..."
              searchCols={["numberOfDevices"]}
              actions={
                ability.can("patch", "Distributor-Order") ||
                ability.can("delete", "Distributor-Order") ||
                ability.can("patch", "Distributor-Order-Status")
                  ? {
                      field: "actions",
                      headerName: "Actions",
                      type: "actions",
                      getActions: ({ row }) =>
                        [
                          ability.can("patch", "Distributor-Order-Status") &&
                          row?.orderTo === user?.id ? (
                            <GridActionsCellItem
                              key="approve"
                              icon={
                                <Iconify
                                  icon="eva:checkmark-circle-2-outline"
                                  sx={{
                                    color: "success.dark",
                                  }}
                                />
                              }
                              sx={{
                                color: "success.dark",
                              }}
                              label="Approve"
                              showInMenu
                              onClick={() => {
                                if (row?.orderStatus !== "pending") return;
                                setOpenRow(row);
                                setOpenApproveConfirmation(true);
                              }}
                              disabled={row?.orderStatus !== "pending"}
                            />
                          ) : null,
                          ability.can("patch", "Distributor-Order-Status") ? (
                            <GridActionsCellItem
                              key="reject"
                              icon={
                                <Iconify
                                  icon="eva:close-circle-outline"
                                  sx={{
                                    color: "error.dark",
                                  }}
                                />
                              }
                              label="Cancel"
                              sx={{
                                color: "error.dark",
                              }}
                              showInMenu
                              onClick={() => {
                                if (
                                  row?.orderStatus !== "pending" ||
                                  (row?.orderTo !== user?.id &&
                                    row?.distributorId !== user?.id)
                                )
                                  return;
                                setOpenRow(row);
                                setOpenRejectConfirmation(true);
                              }}
                              disabled={
                                row?.orderStatus !== "pending" ||
                                (row?.orderTo !== user?.id &&
                                  row?.distributorId !== user?.id)
                              }
                            />
                          ) : null,
                          ability.can("Is", "admin") ? (
                            <GridActionsCellItem
                              key="edit"
                              icon={<Edit />}
                              label="Edit"
                              showInMenu
                              onClick={() => {
                                if (row?.orderStatus !== "pending") return;
                                setOpenRow(row);
                                setEdit(true);
                                setOpenOrderForm(true);
                              }}
                              disabled={row?.orderStatus !== "pending"}
                            />
                          ) : null,
                          ability.can("delete", "Distributor-Order") ? (
                            <GridActionsCellItem
                              key="delete"
                              sx={{
                                color: "error.main",
                              }}
                              icon={
                                <Iconify
                                  color="error.main"
                                  icon="eva:trash-2-outline"
                                />
                              }
                              label="Delete"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setOpenDeleteConfirmation(true);
                              }}
                            />
                          ) : null,
                        ].filter((action) => action),
                    }
                  : null
              }
              defaultQueryParams={{
                orderType,
              }}
              extraHeaderField={
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <FormControl
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      margin: "0 1rem",
                      gap: "1rem",
                    }}
                  >
                    {/* <FormLabel id="demo-row-radio-buttons-group-label">
                    Order Type
                  </FormLabel> */}
                    <RadioGroup
                      row
                      // aria-labelledby="demo-row-radio-buttons-group-label"
                      name="orderType"
                      value={orderType}
                      onChange={(e) => {
                        setOrderType(e.target.value);
                      }}
                    >
                      {!ability.can("Is", "admin") && (
                        <FormControlLabel
                          value="purchase"
                          control={<Radio />}
                          label="Purchase Orders"
                        />
                      )}
                      <FormControlLabel
                        value="sell"
                        control={<Radio />}
                        label="Sell Orders"
                      />
                      <FormControlLabel
                        value="all"
                        control={<Radio />}
                        label="All Orders"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              }
            />
          </Scrollbar>
        </Card>
      </Container>

      {ability.can("patch", "Distributor-Order-Status") && (
        <CustomDeleteConfirmation
          open={openApproveConfirmation}
          onClose={() => setOpenApproveConfirmation(false)}
          tableName="Order"
          extraBodyParams={{ orderStatus: "approved" }}
          deleteFunc={updateOrderStatus}
          row={openRow}
          nameKey="totalDevices"
          isLoading={isOrderStatusLoading}
          isTextConfirmation={false}
          title={`Approve Order`}
          confirmTextColor="primary"
          cancelTextColor="error"
          can
          description={`Are you sure you want to approve the order with ${openRow?.totalDevices} devices?`}
        />
      )}
      {ability.can("patch", "Distributor-Order-Status") && (
        <CustomDeleteConfirmation
          open={openRejectConfirmation}
          onClose={() => setOpenRejectConfirmation(false)}
          tableName="Order"
          extraBodyParams={{ orderStatus: "cancelled" }}
          deleteFunc={updateOrderStatus}
          row={openRow}
          nameKey="totalDevices"
          isLoading={isOrderStatusLoading}
          isTextConfirmation={false}
          title={`Cancel Order`}
          description={`Are you sure you want to cancel the order with ${openRow?.totalDevices} devices?`}
        />
      )}

      {ability.can("delete", "Distributor-Order") && (
        <CustomDeleteConfirmation
          open={openDeleteConfirmation}
          onClose={() => setOpenDeleteConfirmation(false)}
          tableName="Order"
          deleteFunc={deleteOrder}
          row={openRow}
          nameKey="totalDevices"
          isLoading={isDeletedLoading}
          isTextConfirmation={false}
          description={`Are you sure you want to delete the order with ${openRow?.totalDevices} devices?`}
        />
      )}
    </Protected>
  );
}
