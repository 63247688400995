import PropTypes from "prop-types";

// @mui
import { Avatar, Container, LinearProgress } from "@mui/material";
// components
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { sentenceCase } from "change-case";
import moment from "moment";
import { upperCase } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  resetFilterState,
  selectTableFilter,
  setFilters,
  setMainCondition,
  setTableName,
} from "../../redux/slices/tableFilter.slice";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import FilterForm from "../filter/CustomFilterForm";
import { UserListToolbar } from "../../sections/@dashboard/user";
import Label from "../label/Label";

CustomTable.propTypes = {
  canSelect: PropTypes.bool,
  searchLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  isFetching: PropTypes.bool,
  tableName: PropTypes.string,
  restrictDownloadCols: PropTypes.array,
  searchCols: PropTypes.array,
  defaultVisibleCols: PropTypes.array,
  extraHeaderField: PropTypes.element,
  resetParentForm: PropTypes.func,
  actions: PropTypes.object,
  useDataQuery: PropTypes.func,
  defaultQueryParams: PropTypes.object,
  dataQueryOptions: PropTypes.object,
  sx: PropTypes.object,
};

CustomTable.defaultProps = {
  canSelect: false,
  searchLabel: "Search...",
  isLoading: false,
  isFetching: false,
  tableName: "",
  restrictDownloadCols: [],
  searchCols: [],
  defaultVisibleCols: [],
  extraHeaderField: null,
  resetParentForm: null,
  actions: null,
  useDataQuery: null,
  defaultQueryParams: {},
  dataQueryOptions: {},
  sx: {},
};

const CustomToolbar = () => (
  <GridToolbarContainer
    sx={{
      display: "flex",
      padding: "10px 16px",
      borderRadius: 0,
    }}
  >
    <GridToolbarColumnsButton />
  </GridToolbarContainer>
);

const statusColor = {
  active: "success",
  inactive: "error",
  pending: "warning",
  deleted: "error",
  confirm: "success",
  cancelled: "error",
  success: "success",
  in_progress: "warning",
  failed: "error",
  true: "success",
  false: "error",
  No: "error",
  Yes: "success",
  no: "error",
  yes: "success",
  approved: "success",
};

export const CustomFormSchema = yup.object().shape({
  mainCondition: yup.string().required("Main condition is required"),
  filters: yup.array().of(
    yup.object().shape({
      field: yup.string().required("Field is required"),
      operator: yup.string().required("Operator is required"),
    }),
  ),
});

export default function CustomTable({
  canSelect,
  searchLabel,
  tableName,
  extraHeaderField,
  resetParentForm,
  useDataQuery,
  defaultQueryParams,
  dataQueryOptions,
  actions,
  sx,
  ...props
}) {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const dispatch = useDispatch();

  const [customColumns, setCustomColumns] = useState([]);

  const [openFilterForm, setOpenFilterForm] = useState(false);

  const [selected, setSelected] = useState([]);

  const [queryFilters, setQueryFilters] = useState({});

  const downloadRef = useRef(null);

  const [filterName, setFilterName] = useState("");

  const [sortModel, setSortModel] = useState({});

  const defaultVisibilityModelState = useCallback(
    (providedCols) =>
      providedCols?.reduce((acc, column) => {
        if (canSelect) {
          acc.__check__ = true;
        }
        acc[column.field] = column?.defaultVisible;
        if (column?.field === "actions") {
          acc[column.field] = true;
        }
        return acc;
      }, {}),
    [canSelect],
  );

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    defaultVisibilityModelState(customColumns),
  );

  const [submittedFilterForm, setSubmittedFilterForm] = useState({});

  const {
    data: fetchedData,
    isFetching: isDataFetching,
    isLoading: isDataLoading,
  } = useDataQuery(
    {
      skip: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
      search: filterName,
      filter: JSON.stringify(queryFilters),
      sortBy: sortModel?.field,
      orderBy: sortModel?.sort,
      ...defaultQueryParams,
    },
    {
      ...dataQueryOptions,
    },
  );

  const [filteredRows, setFilteredRows] = useState([]);

  const rowCountRef = useRef(fetchedData?.totalCount || 0);

  const rowCount = useMemo(() => {
    if (fetchedData?.totalCount !== undefined) {
      rowCountRef.current = fetchedData?.totalCount;
    }
    return rowCountRef.current;
  }, [fetchedData?.totalCount]);

  // setting filtered rows on data change
  useEffect(() => {
    if (fetchedData?.data) {
      setFilteredRows(fetchedData?.data);
    }
  }, [fetchedData?.data]);

  // setting custom columns on data change
  useEffect(() => {
    if (fetchedData?.data?.length && !customColumns?.length) {
      setCustomColumns(() => {
        const columns = fetchedData?.columns
          ?.map((column) => {
            const defaultObject = {
              field: column?.columnName,
              headerName: column?.headerName,
              type: column?.type,
              minWidth: 150,
              sortable: column?.isSortable,
              filterable: column?.isFilterable,
              defaultVisible: column?.defaultVisible,
              isDownloadable: column?.isDownloadable,
            };
            switch (column?.type) {
              case "date":
                return {
                  ...defaultObject,
                  valueFormatter: (value) =>
                    value ? moment(value).format("DD/MM/YYYY") : "--",
                  valueGetter: (value) => value && new Date(value),
                };
              case "boolean":
                return {
                  ...defaultObject,
                  valueFormatter: (value) => (value ? "Yes" : "No"),
                  renderCell: (params) => (
                    <Label color={statusColor[params.formattedValue]}>
                      {params.formattedValue}
                    </Label>
                  ),
                };
              case "enum":
                return {
                  ...defaultObject,
                  renderCell: (params) => (
                    <Label color={statusColor[params.formattedValue]}>
                      {params.formattedValue}
                    </Label>
                  ),
                };
              case "object":
                return null;
              case "image":
                return {
                  ...defaultObject,
                  renderCell: (params) => (
                    <Avatar
                      src={params.formattedValue}
                      alt={params?.row?.name}
                      slotProps={{
                        img: {
                          sx: {
                            objectFit: "contain",
                          },
                        },
                      }}
                      sx={{
                        width: 40,
                        height: 40,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 1,
                        m: 1,
                      }}
                    />
                  ),
                };
              default:
                return {
                  ...defaultObject,
                  align: "left",
                  headerAlign: "left",
                  valueGetter: (params) => {
                    if (!params && params !== 0) {
                      return "--";
                    }
                    return params;
                  },
                };
            }
          })
          .filter((column) => column !== null);

        if (actions) {
          columns?.push(actions);
        }
        return columns ?? [];
      });
    }
  }, [fetchedData, actions]);

  // setting visibility model on column change
  useEffect(() => {
    setColumnVisibilityModel(defaultVisibilityModelState(customColumns));
  }, [customColumns]);

  const {
    control,
    register,
    watch,
    setValue,
    getValues,
    reset,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mainCondition: "and",
      filters: [],
    },
    resolver: yupResolver(CustomFormSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    shouldUnregister: true,
    name: "filters",
  });

  const tableFilter = useSelector(selectTableFilter);

  // setting submitted filter form on filter change
  useEffect(() => {
    if (
      tableFilter.tableName &&
      tableFilter.tableName === tableName &&
      tableFilter.filters.length > 0
    ) {
      setValue("mainCondition", tableFilter.mainCondition);
      setValue("filters", tableFilter.filters);
      setSubmittedFilterForm({
        mainCondition: tableFilter.mainCondition,
        filters: tableFilter.filters,
      });
      setQueryFilters({
        mainCondition: tableFilter.mainCondition,
        filters: tableFilter.filters,
      });
    }
  }, [tableFilter, tableName, setValue]);

  const onFilterFormSubmit = () => {
    const formData = getValues();
    setQueryFilters({
      mainCondition: formData.mainCondition,
      filters: formData.filters,
    });
    setSubmittedFilterForm({
      mainCondition: formData.mainCondition,
      filters: formData.filters,
    });
    dispatch(setTableName(tableName));
    dispatch(setFilters(formData.filters));
    dispatch(setMainCondition(formData.mainCondition));
    setOpenFilterForm(false);
    setValue("mainCondition", formData?.mainCondition);
    setValue("filters", formData?.filters);
  };

  const handleCloseFilterForm = () => {
    setOpenFilterForm(false);
    setValue("mainCondition", submittedFilterForm?.mainCondition ?? "and");
    setValue("filters", submittedFilterForm?.filters);
  };

  const handleResetFilterForm = () => {
    getValues("filters")?.forEach((_, index) => remove(index));
    reset({
      mainCondition: "and",
      filters: [],
    });
    // setOpenFilterForm(false)
    setSubmittedFilterForm({
      mainCondition: "and",
      filters: [],
    });
    setQueryFilters({});
    setFilteredRows(fetchedData?.data);
    setOpenFilterForm(false);
    dispatch(resetFilterState());
    if (resetParentForm) {
      resetParentForm();
    }
    setFilterName("");
  };

  const handleSelectionChange = (ids) => {
    setSelected((prev) => {
      const prevSelected = prev?.filter((row) => ids?.includes(row?.id));

      const selected = [...prevSelected];

      const newSelected = fetchedData?.data?.filter((row) =>
        ids?.includes(row?.id),
      );

      if (newSelected?.length) {
        selected.push(...newSelected);
      }

      return selected.filter(
        (value, index, self) =>
          self.findIndex((t) => t.id === value.id) === index,
      );
    });
  };

  const handleFilterByName = (filter) => {
    setFilterName(filter);
  };

  const handleColumnVisibilityModelChange = (model) => {
    if (Object.keys(model).length === 0) {
      setColumnVisibilityModel(
        customColumns.reduce((acc, column) => {
          acc[column.field] = true;
          return acc;
        }, {}),
      );
    } else {
      const newModel = customColumns.reduce((acc, column) => {
        acc[column.field] = model[column.field];
        return acc;
      }, {});
      setColumnVisibilityModel(newModel);
    }
  };

  const handleDownload = () => {
    const data = selected.map((row) => {
      const str = Object.keys(columnVisibilityModel)
        .filter(
          (key) =>
            customColumns.find((column) => column.field === key)
              ?.isDownloadable &&
            !key !== "__check__" &&
            key !== "actions" &&
            columnVisibilityModel[key],
        )
        .map((key) => {
          switch (customColumns.find((column) => column.field === key)?.type) {
            case "date":
              return row[key] ? moment(row[key]).format("DD/MM/YYYY") : "N/A";
            case "boolean":
              return row[key] ? "Yes" : "No";
            default:
              return row[key];
          }
        });
      return str.join(",");
    });
    const headers = Object.keys(columnVisibilityModel)
      .filter(
        (key) =>
          customColumns.find((column) => column.field === key)
            ?.isDownloadable &&
          key !== "__check__" &&
          key !== "actions" &&
          columnVisibilityModel[key],
      )
      .map(
        (key) =>
          customColumns.find((column) => column.field === key)?.headerName,
      );

    data.unshift(headers.join(","));
    const textContent = data.join("\n");
    const blob = new Blob([textContent], { type: "excel/csv" });
    const url = URL.createObjectURL(blob);
    downloadRef.current.href = url;
    downloadRef.current.click();
    URL.revokeObjectURL(url);
  };

  const handlePaginationModelChange = (model) => {
    setPaginationModel(model);
  };

  const handleSortModelChange = (model) => {
    setSortModel({
      field: model[0]?.field,
      sort: upperCase(model[0]?.sort),
    });
  };

  return (
    <Container
      sx={{
        display: "flex",
        // justifyContent: 'space-between',
        // alignItems: 'center',
        flexDirection: "column",
        padding: "0px!important",
      }}
    >
      <FilterForm
        open={openFilterForm}
        columns={customColumns?.filter((column) => column.filterable)}
        onClose={handleCloseFilterForm}
        control={control}
        register={register}
        watch={watch}
        fields={fields}
        append={append}
        remove={remove}
        onSubmit={handleSubmit(onFilterFormSubmit)}
        setValue={setValue}
        handleSubmit={handleSubmit}
        trigger={trigger}
        errors={errors}
        prevFormData={submittedFilterForm}
        resetForm={handleResetFilterForm}
        tableName={tableName}
      />

      <UserListToolbar
        icon="download"
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
        onActionClick={handleDownload}
        selected={selected}
        placeholder={searchLabel}
        onOpenFilter={() => setOpenFilterForm(true)}
        extraHeaderField={extraHeaderField}
        resetForm={handleResetFilterForm}
        totalFilterCount={tableFilter?.filters?.length}
      />

      <a
        ref={downloadRef}
        style={{ display: "none" }}
        download={`${tableName}_${new Date().toISOString().split("T")[0]}.csv`}
        hidden
      >
        a
      </a>

      {filteredRows?.every((row) => row?.id) && (
        <DataGrid
          columns={customColumns}
          rows={customColumns?.length ? filteredRows : []}
          // rows={fetchedData?.data || []}
          loading={isDataFetching || isDataLoading}
          getRowId={(row) => row?.id}
          // select
          checkboxSelection={canSelect}
          rowSelectionModel={selected.map((row) => row.id)}
          onRowSelectionModelChange={handleSelectionChange}
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          // onFilterModelChange={handleFilterModelChange}
          filterMode="client"
          // pagination
          pagination
          pageSizeOptions={[10, 25, 50, 100]}
          paginationModel={paginationModel}
          paginationMode="server"
          rowCount={rowCount}
          onPaginationModelChange={handlePaginationModelChange}
          // sorting
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          scrollbarSize={1}
          disableColumnMenu
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
            toolbar: CustomToolbar,
          }}
          slotProps={{
            pagination: {
              showFirstButton: true,
              showLastButton: true,
              slotProps: {
                actions: {
                  nextButton: {
                    "data-testid": "pagination-next",
                  },
                  previousButton: {
                    "data-testid": "pagination-previous",
                  },
                },
                select: {
                  inputProps: {
                    "data-testid": "pagination-select-input",
                  },
                  "data-testid": "pagination-select",
                },
              },
            },
          }}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
          columnVisibilityModel={columnVisibilityModel}
          // autosizeOptions={{
          //   columns: ["firstName", "lastName", "Id"],
          //   includeOutliers: true,
          //   includeHeaders: true,
          //   outliersFactor: 0.5,
          //   // expand: true,
          // }}

          sx={{
            "& .MuiDataGrid-scrollbar--horizontal": {
              // height: "5px!important",
              position: "relative",
              // "& .MuiDataGrid-scrollbarContent": {
              overflowX: "auto",
              "&::-webkit-scrollbar": {
                height: "10px",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "gray",
                opacity: 0,
                borderRadius: 5,
                "&:active": {
                  transform: "scale(0.8)",
                  opacity: 1,
                },
              },
              // },
            },
            "& .MuiDataGrid-filler": {
              height: "0px!important",
            },
            "& .MuiDataGrid-overlayWrapper": {
              height:
                isDataLoading || isDataFetching
                  ? "2px!important"
                  : "300px!important",
              transition: "height 0s !important",
              "& .MuiDataGrid-overlayWrapperInner": {
                height:
                  isDataLoading || isDataFetching
                    ? "2px!important"
                    : "300px!important",
                transition: "height 0s !important",
              },
            },
            borderRadius: 0,
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#F4F6F8",
              color: "#637381",
              borderRadius: 0,
            },
            ...sx,
          }}
          data-testid="custom-table"
          {...props}
        />
      )}
    </Container>
  );
}
