import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

CustomDeleteConfirmation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  tableName: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.any,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  cancelTextColor: PropTypes.string,
  isLoading: PropTypes.bool,
  sx: PropTypes.object,
  cancelButton: PropTypes.bool,
  confirmTextColor: PropTypes.string,
  row: PropTypes.object,
  nameKey: PropTypes.string,
  deleteFunc: PropTypes.func,
  nameTitle: PropTypes.string,
  isTextConfirmation: PropTypes.bool,
  extraBodyParams: PropTypes.object,
};

function CustomDeleteConfirmation({
  open,
  onClose,
  tableName = "",
  confirmText = "Confirm",
  confirmTextColor = "error",
  cancelText = "Cancel",
  cancelTextColor = "primary",
  cancelButton = true,
  isLoading,
  row,
  nameKey = "name",
  nameTitle = nameKey,
  sx,
  deleteFunc,
  isTextConfirmation = true,
  description,
  title,
  extraBodyParams,
  ...props
}) {
  const ConfirmationSchema = yup.object().shape({
    confirmName: yup
      .string()
      .required(`Please type the ${tableName} ${nameTitle} to confirm.`)
      .test(
        "match",
        `The ${tableName} ${nameTitle} does not match.`,
        (value) => value === row?.[nameKey],
      ),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(ConfirmationSchema),
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    reset({
      confirmName: "",
    });
  }, [open, reset]);

  const handleConfirm = async () => {
    try {
      let body = row?.id;

      if (extraBodyParams) {
        body = {
          id: row?.id,
          ...extraBodyParams,
        };
      }

      const resultAction = await deleteFunc(body);
      if (resultAction?.data?.success) {
        onClose();
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
      console.error("Failed to delete org device: ", err);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        ...sx,
      }}
      {...props}
    >
      <DialogTitle id="alert-dialog-title">
        {title || `Delete ${tableName}`}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <DialogContentText id="alert-dialog-description">
            {description ||
              `Are you sure you want to delete "${
                row?.[nameKey]
              }" ${tableName}? ${
                isTextConfirmation
                  ? `Please type the ${tableName} ${nameTitle} to confirm.`
                  : ""
              } `}
          </DialogContentText>
          {isTextConfirmation && (
            <TextField
              {...register("confirmName")}
              label={`${tableName} ${nameTitle}`}
              variant="outlined"
              fullWidth
              value={watch("confirmName")}
              error={Boolean(errors.confirmName)}
              helperText={errors.confirmName?.message}
              inputProps={{
                "data-testid": "confirmInput",
              }}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        {cancelButton && (
          <LoadingButton onClick={onClose} color={cancelTextColor}>
            {cancelText}
          </LoadingButton>
        )}
        <LoadingButton
          onClick={
            isTextConfirmation ? handleSubmit(handleConfirm) : handleConfirm
          }
          autoFocus
          color={confirmTextColor}
          loading={isLoading}
        >
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default CustomDeleteConfirmation;
