import { createSlice } from "@reduxjs/toolkit";

const environmentSlice = createSlice({
  name: "environment",
  initialState: {
    selected: "live",
  },
  reducers: {
    setSelectedEnvironment: (state, action) => {
      state.selected = action.payload;
    },
  },
});

export const { setSelectedEnvironment } = environmentSlice.actions;

export const selectEnvironment = (state) => state.environment.selected;

export default environmentSlice.reducer;
