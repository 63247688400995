import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  Box,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  CircularProgress,
  Select,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import { ColumnAccessControlSchema } from "../../../forms-schema/form.schema";
import {
  useCreateColumnMutation,
  useGetEntitiesQuery,
  useUpdateColumnMutation,
} from "../../../redux/services/columnAccessControl.service";

ColumnAccessControlForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  openRow: PropTypes.object,
  edit: PropTypes.bool,
};

export default function ColumnAccessControlForm({
  open,
  onClose,
  openRow,
  edit,
}) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(ColumnAccessControlSchema),
  });

  useEffect(() => {
    if (edit) {
      setValue("entityName", openRow?.entityName);
      setValue("columnName", openRow?.columnName);
      setValue("type", openRow?.type);
      setValue("headerName", openRow?.headerName);
      setValue("defaultVisible", openRow?.defaultVisible);
      setValue("isDownloadable", openRow?.isDownloadable);
      setValue("isFilterable", openRow?.isFilterable);
      setValue("isSortable", openRow?.isSortable);
      setValue("isViewable", openRow?.isViewable);
      setValue("isAccessible", openRow?.isAccessible);
      setValue("isForeignKey", openRow?.isForeignKey);
    } else {
      reset({
        entityName: "",
      });
    }
  }, [open, openRow, setValue, reset, edit]);

  const [createColumn, { isLoading: isCreating }] = useCreateColumnMutation();
  const [updateColumn, { isLoading: isUpdating }] = useUpdateColumnMutation();

  const handleClick = async (values) => {
    if (edit) {
      delete values.entityName;
      delete values.columnName;
    }
    let resultAction;
    if (edit) {
      resultAction = await updateColumn({
        id: openRow?.id,
        ...values,
      });
    } else {
      resultAction = await createColumn(values);
    }
    if (resultAction?.data?.success) {
      onClose();
      reset();
    }
  };

  const {
    isLoading: isEntitiesLoading,
    isFetching: isEntitiesFetching,
    data: entities,
  } = useGetEntitiesQuery();

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">
                  {edit ? "Edit" : "Create"} Column
                </Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <InputLabel id="entity-name-label">Entity Name</InputLabel>
                  <Select
                    {...register("entityName", { required: true })}
                    labelId="entity-name-label"
                    inputProps={{ "data-testid": "entityName" }}
                    label="Entity Name"
                    error={Boolean(errors.entityName)}
                    value={watch("entityName")}
                    disabled={edit}
                    onChange={(e) => {
                      setValue("entityName", e.target.value);
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {(isEntitiesLoading || isEntitiesFetching) && (
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        disabled
                      >
                        <CircularProgress size={20} />
                      </MenuItem>
                    )}
                    {!isEntitiesLoading &&
                      !isEntitiesFetching &&
                      entities?.data
                        ?.toSorted((a, b) => a?.name.localeCompare(b?.name))
                        ?.map((e) => (
                          <MenuItem key={e.name} value={e?.name}>
                            {e?.name}
                          </MenuItem>
                        ))}
                  </Select>
                  <FormHelperText error={Boolean(errors.entityName)}>
                    {errors.entityName?.message}
                  </FormHelperText>
                </FormControl>

                <TextField
                  name="columnName"
                  label="Column Name"
                  {...register("columnName", { required: true })}
                  error={!!errors.columnName}
                  helperText={errors.columnName?.message}
                  inputProps={{ "data-testid": "columnName" }}
                  disabled={edit}
                />

                <TextField
                  name="type"
                  label="Type"
                  {...register("type", { required: true })}
                  error={!!errors.type}
                  helperText={errors.type?.message}
                  inputProps={{ "data-testid": "type" }}
                />

                <TextField
                  name="headerName"
                  label="Header Name"
                  {...register("headerName", { required: true })}
                  error={!!errors.headerName}
                  helperText={errors.headerName?.message}
                  inputProps={{ "data-testid": "headerName" }}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={openRow?.defaultVisible}
                      // checked={watch("defaultVisible")}
                      {...register("defaultVisible")}
                      inputProps={{ "data-testid": "defaultVisible" }}
                    />
                  }
                  label="Default Visible"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={openRow?.isDownloadable}
                      // checked={watch("isDownloadable")}
                      {...register("isDownloadable")}
                      inputProps={{ "data-testid": "isDownloadable" }}
                    />
                  }
                  label="Downloadable"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={openRow?.isFilterable}
                      // checked={watch("isFilterable")}
                      {...register("isFilterable")}
                      inputProps={{ "data-testid": "isFilterable" }}
                    />
                  }
                  label="Filterable"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={openRow?.isSortable}
                      // checked={watch("isSortable")}
                      {...register("isSortable")}
                      inputProps={{ "data-testid": "isSortable" }}
                    />
                  }
                  label="Sortable"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={openRow?.isViewable}
                      // checked={watch("isViewable")}
                      {...register("isViewable")}
                      inputProps={{ "data-testid": "isViewable" }}
                    />
                  }
                  label="Viewable"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={openRow?.isAccessible}
                      // checked={watch("isAccessible")}
                      {...register("isAccessible")}
                      inputProps={{ "data-testid": "isAccessible" }}
                    />
                  }
                  label="Accessible"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={openRow?.isForeignKey}
                      // checked={watch("isForeignKey")}
                      {...register("isForeignKey")}
                      inputProps={{ "data-testid": "isForeignKey" }}
                    />
                  }
                  label="Is Foreign Key"
                />

                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isUpdating || isCreating}
                  fullWidth
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
