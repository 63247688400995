import { useEffect } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
  IconButton,
  Box,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import {
  useCreateOrderMutation,
  useUpdateOrderMutation,
} from "../../../redux/services/order.service";
import { OrderSchema } from "../../../forms-schema/form.schema";
import { selectAbility } from "../../../redux/slices/casl.slice";
import { selectUser } from "../../../redux/slices/auth.slice";
import {
  useGetDistributorsQuery,
  useGetParentDistributorQuery,
} from "../../../redux/services/authUser.service";

OrderForm.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  onDownload: PropTypes.func,
  openRow: PropTypes.object,
};

export default function OrderForm({ open, onClose, edit, openRow }) {
  const ability = useSelector(selectAbility);

  const user = useSelector(selectUser);

  const {
    isLoading: isDistributorLoading,
    isFetching: isDistributorFetching,
    data: distributors,
  } = useGetDistributorsQuery();

  const {
    data: parentDistributor,
    isLoading: isParentDistributorLoading,
    isFetching: isParentDistributorFetching,
  } = useGetParentDistributorQuery();

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(OrderSchema),
  });

  const [createOrder, { isLoading }] = useCreateOrderMutation();
  const [updateOrder, { isLoading: isUpdateLoading }] =
    useUpdateOrderMutation();

  useEffect(() => {
    if (openRow && edit) {
      setValue("totalDevices", openRow?.totalDevices);
      setValue("distributorId", openRow?.distributorId);
      setValue("orderTo", openRow?.orderTo);
    } else {
      reset({
        totalDevices: "",
      });
    }
  }, [open, reset, openRow, edit, setValue]);

  const handleClick = async (values) => {
    let resultAction;

    if (edit) {
      resultAction = await updateOrder({
        ...values,
        id: openRow.id,
        distributorId: values.distributorId || user?.id,
      });
    } else {
      resultAction = await createOrder({
        ...values,
        orderDate: new Date().toISOString(),
        distributorId: values.distributorId || user?.id,
      });
    }

    if (resultAction?.data?.success) {
      reset();
      onClose();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: 360, border: "none", overflow: "hidden" },
      }}
    >
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3 }}>
          <div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 0.5,
              }}
            >
              <Typography variant="subtitle1">
                {edit ? "Edit" : "Create"} Order
              </Typography>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
            <Divider />
          </div>
          <form onSubmit={handleSubmit(handleClick)}>
            <Stack spacing={3}>
              <TextField
                type="number"
                name="totalDevices"
                label="Total Devices to Order"
                {...register("totalDevices", { required: true })}
                error={Boolean(errors.totalDevices)}
                // data-testid='totalDevice'
                inputProps={{ "data-testid": "totalDevice" }}
                helperText={errors.totalDevices && errors.totalDevices.message}
              />

              {ability.can("get", "Distributors") &&
                user?.roles?.map((role) => role.name).includes("admin") && (
                  <FormControl
                    fullWidth
                    error={!!errors.distributorId}
                    data-testid="frmD"
                  >
                    <InputLabel
                      id="distributorId"
                      error={!!errors.distributorId}
                    >
                      Distributor
                    </InputLabel>
                    <Select
                      id="demo-simple-select"
                      value={watch("distributorId") || ""}
                      label="Distributor"
                      error={!!errors.distributorId}
                      labelId="distributorId"
                      name="distributorId"
                      {...register("distributorId", { required: true })}
                      inputProps={{ "data-testid": "orgDistributorId" }}
                    >
                      {(isDistributorLoading || isDistributorFetching) && (
                        <MenuItem
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          disabled
                        >
                          <CircularProgress
                            data-testid="distributorLoading"
                            size={20}
                          />
                        </MenuItem>
                      )}
                      {!isDistributorLoading &&
                        !isDistributorFetching &&
                        distributors?.data
                          ?.toSorted((a, b) =>
                            a?.firstName?.localeCompare(b?.firstName),
                          )
                          ?.map((distributor) => (
                            <MenuItem
                              key={distributor?.id}
                              value={distributor?.id}
                            >
                              {distributor?.firstName} {distributor?.lastName}
                            </MenuItem>
                          ))}
                    </Select>
                    <FormHelperText>
                      {errors.distributorId?.message}
                    </FormHelperText>
                  </FormControl>
                )}

              {!edit && (
                <FormControl fullWidth error={!!errors.orderTo}>
                  <InputLabel id="order-to-label" error={!!errors.orderTo}>
                    Order To
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={watch("orderTo") || ""}
                    label="Order To"
                    error={!!errors.orderTo}
                    labelId="order-to-label"
                    name="orderTo"
                    {...register("orderTo", { required: true })}
                    inputProps={{ "data-testid": "orderTo" }}
                  >
                    {(isParentDistributorLoading ||
                      isParentDistributorFetching) && (
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        disabled
                      >
                        <CircularProgress />
                      </MenuItem>
                    )}
                    {!isParentDistributorLoading &&
                      !isParentDistributorFetching &&
                      parentDistributor &&
                      parentDistributor?.data?.map((distributor) => (
                        <MenuItem key={distributor?.id} value={distributor?.id}>
                          {distributor?.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText error={Boolean(errors.orderTo)}>
                    {errors.orderTo?.message}
                  </FormHelperText>
                </FormControl>
              )}

              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                loading={isLoading || isUpdateLoading}
              >
                Submit
              </LoadingButton>
            </Stack>
          </form>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
}
