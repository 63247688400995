import LoginForm from "../sections/auth/login/login.form";
import AuthLayout from "../layouts/auth/AuthLayout";

export default function LoginPage() {
  return (
    <AuthLayout
      formHeading="Sign in to Sunbots Admin Panel"
      title="Login | Sunbots"
    >
      <LoginForm />
    </AuthLayout>
  );
}
