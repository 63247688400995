import { useEffect, useState } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Button,
  Drawer,
  Divider,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import { useGetOrganizationsQuery } from "../../../redux/services/organization.service";
import Scrollbar from "../../../components/scrollbar";
import Iconify from "../../../components/iconify";
import { DevicesSchema } from "../../../forms-schema/form.schema";
import { useGenerateCodeMutation } from "../../../redux/services/order.service";
import { selectUser } from "../../../redux/slices/auth.slice";

GenerateCodeForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  openRow: PropTypes.object,
  onDownload: PropTypes.func,
};

export default function GenerateCodeForm({ open, onClose, onDownload }) {
  const user = useSelector(selectUser);

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(DevicesSchema),
  });

  const {
    isLoading: isOrgLoading,
    isFetching: isOrgFetching,
    data: organizations,
  } = useGetOrganizationsQuery();
  const [generateCode, { isLoading }] = useGenerateCodeMutation();

  const [showDownload, setShowDownload] = useState(false);
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    reset();
    setShowDownload(false);
    // eslint-disable-next-line
  }, [open]);

  const handleClick = async (values) => {
    const resultAction = await generateCode({
      ...values,
      distributorId: user?.id,
    });

    if (resultAction?.data?.success) {
      setDownloadData(resultAction?.data?.data?.map((item) => item.code));
      reset();
      setShowDownload(true);
      // onClose();
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">Generate Code</Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                <TextField
                  type="number"
                  name="numberOfDevices"
                  label="Total Devices to Create"
                  {...register("numberOfDevices", { required: true })}
                  error={Boolean(errors.numberOfDevices)}
                  // data-testid='totalDevice'
                  inputProps={{ "data-testid": "totalDevice" }}
                  helperText={
                    errors.numberOfDevices && errors.numberOfDevices.message
                  }
                />

                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-sender"
                    error={Boolean(errors.senderOrganization)}
                  >
                    Sender Organization
                  </InputLabel>
                  <Select
                    {...register("senderOrganization", { required: true })}
                    labelId="demo-simple-select-sender"
                    id="demo-simple-select"
                    inputProps={{ "data-testid": "senderOrganization" }}
                    // value={senderOrg}
                    label="Sender Organization"
                    // onChange={e => setSenderOrg(e.target.value)}
                    error={Boolean(errors.senderOrganization)}
                  >
                    {(isOrgLoading || isOrgFetching) && (
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        disabled
                      >
                        <CircularProgress size={20} />
                      </MenuItem>
                    )}
                    {!isOrgLoading &&
                      !isOrgFetching &&
                      organizations?.data
                        ?.toSorted((a, b) => a?.name.localeCompare(b?.name))
                        ?.map((org) => (
                          <MenuItem key={org?.id} value={org?.id}>
                            {org?.name}
                          </MenuItem>
                        ))}
                  </Select>
                  <FormHelperText
                    error={Boolean(errors.senderOrganization)}
                    data-testid="senderOrganizationErr"
                  >
                    {errors.senderOrganization?.message}
                  </FormHelperText>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-recipient"
                    error={Boolean(errors.recipientOrganization)}
                  >
                    Recipient Organization
                  </InputLabel>
                  <Select
                    {...register("recipientOrganization", { required: true })}
                    labelId="demo-simple-select-recipient"
                    id="demo-simple-select"
                    inputProps={{ "data-testid": "recipientOrganization" }}
                    label="Recipient Organization"
                    error={Boolean(errors.recipientOrganization)}
                  >
                    {(isOrgLoading || isOrgFetching) && (
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        disabled
                      >
                        <CircularProgress size={20} />
                      </MenuItem>
                    )}
                    {!isOrgLoading &&
                      !isOrgFetching &&
                      organizations?.data
                        ?.toSorted((a, b) => a?.name?.localeCompare(b?.name))
                        ?.map((org) => (
                          <MenuItem key={org.id} value={org.id}>
                            {org.name}
                          </MenuItem>
                        ))}
                  </Select>
                  <FormHelperText
                    error={Boolean(errors.recipientOrganization)}
                    data-testid="recipientOrganizationErr"
                  >
                    {errors.recipientOrganization?.message}
                  </FormHelperText>
                </FormControl>

                <TextField
                  name="eventPlace"
                  label="Event Place"
                  {...register("eventPlace")}
                  error={Boolean(errors.eventPlace)}
                  inputProps={{ "data-testid": "eventPlace" }}
                  helperText={errors.eventPlace && errors.eventPlace.message}
                />

                <TextField
                  name="tag"
                  label="Tag"
                  {...register("tag")}
                  error={Boolean(errors.tag)}
                  inputProps={{ "data-testid": "tag" }}
                  helperText={errors.tag && errors.tag.message}
                />

                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  loading={isLoading}
                >
                  Submit
                </LoadingButton>
                {showDownload && (
                  <Button
                    onClick={() => {
                      onDownload(downloadData);
                    }}
                  >
                    <Iconify icon={`eva:download-fill`} />
                    Download
                  </Button>
                )}
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
