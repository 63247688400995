import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Page404 from "../pages/Page404";
import { selectUser } from "../redux/slices/auth.slice";
import { selectAbility } from "../redux/slices/casl.slice";

Protected.propTypes = {
  children: PropTypes.node,
};

export default function Protected({ children }) {
  const user = useSelector(selectUser);

  const location = useLocation();

  const ability = useSelector(selectAbility);

  const [allNavMenus, setAllNavMenus] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setAllNavMenus([
      ability.can("get", "Organization-Google-Analytics") && {
        title: "dashboard",
        path: "/dashboard/app",
      },
      ability.can("get", "Distributor-Orders") && {
        title: "Order",
        path: "/dashboard/order",
      },
      ability.can("get", "Users") && {
        title: "user",
        path: "/dashboard/user",
      },
      ability.can("get", "Offers") && {
        title: "offer",
        path: "/dashboard/offer",
      },
      ability.can("get", "Organizations") && {
        title: "organization",
        path: "/dashboard/organization",
      },
      ability.can("get", "Devices") && {
        title: "device",
        path: "/dashboard/device",
      },
      ability.can("get", "Supported-Devices") && {
        title: "Supported Device",
        path: "/dashboard/supported-device",
      },
      ability.can("get", "Supported-Languages") && {
        title: "Supported Language",
        path: "/dashboard/language",
      },
      ability.can("get", "Words-By-Language-Code") && {
        title: "Words",
        path: "/dashboard/words",
      },
      ability.can("get", "Countries") && {
        title: "Country",
        path: "/dashboard/country",
      },
      ability.can("get", "Blogs") && {
        title: "Blog",
        path: "/dashboard/blog",
      },
      ability.can("get", "Roles") && {
        title: "Role",
        path: "/dashboard/roles",
      },
      ability.can("get", "AuthUsers") && {
        title: "Auth User",
        path: "/dashboard/authUser",
      },
      ability.can("get", "Distributors") && {
        title: "Distributor",
        path: "/dashboard/distributor",
      },
      ability.can("get", "Permissions") && {
        title: "Permission",
        path: "/dashboard/permission",
      },
      ability.can("get", "Column-Access-Control") && {
        title: "Column Access Control",
        path: "/dashboard/column-access-control",
      },
      ability.can("get", "User-Google-Analytics") && {
        path: "user/:id",
        navVisible: false,
      },
    ]);
    setLoading(false);
  }, []);

  if (user?.roles?.map((role) => role?.name)?.includes("superadmin")) {
    return <>{children}</>;
  }

  // only superadmin can access permission page
  if (location.pathname === "/dashboard/permission") {
    return <Page404 />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (user?.roles?.map((role) => role?.name)?.includes("admin")) {
    return <>{children}</>;
  }

  const accessibleRoutes = allNavMenus.map((menu) => menu?.path);

  if (accessibleRoutes.includes(location.pathname)) {
    return <>{children}</>;
  }

  return <Page404 />;
}
