import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBaseQuery } from "../basequery";

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: extendedBaseQuery,
  tagTypes: ["Order"],
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: (params) => {
        const query = {};
        if (params?.skip) query.skip = params.skip;
        if (params?.limit) query.limit = params.limit;
        if (params?.search) query.search = params.search;
        if (params?.filter) query.filter = params.filter;
        if (params?.sortBy) query.sortBy = params.sortBy;
        if (params?.orderBy) query.orderBy = params.orderBy;
        if (params?.orderType) query.orderType = params.orderType;

        const userId = JSON.parse(localStorage.getItem("user"))?.id;

        return {
          url: `distributor-orders/all/${userId}?${new URLSearchParams(
            query,
          ).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Order"],
    }),
    createOrder: builder.mutation({
      query: (body) => ({
        url: "distributor-orders/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Order"],
    }),
    updateOrder: builder.mutation({
      query: (body) => ({
        url: `distributor-orders/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Order"],
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `distributor-orders/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Order"],
    }),
    generateCode: builder.mutation({
      query: (body) => ({
        url: "distributor-orders/generate-code",
        method: "POST",
        body,
      }),
    }),
    updateOrderStatus: builder.mutation({
      query: (body) => ({
        url: `distributor-orders/update-orders/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Order"],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
  useGenerateCodeMutation,
  useUpdateOrderStatusMutation,
} = orderApi;
