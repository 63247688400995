import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Container,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
// components
import {
  Assistant,
  CameraEnhance,
  CurrencyRupeeOutlined,
  DocumentScanner,
  SearchOutlined,
  Sync,
  TextFieldsOutlined,
  Visibility,
} from "@mui/icons-material";
import { useGetOneUserUsageQuery } from "../redux/services/googleAnalytics.service";

// sections
import { LanguageChart, UsageChart } from "../sections/@dashboard/app";
import AppWidgetNew from "../sections/@dashboard/app/AppWidgetNew";

export default function UserUsageDashboardPage() {
  const theme = useTheme();

  const [duration, setDuration] = useState("weekly");

  const [refresh, setRefresh] = useState(false);

  const [graph1Data, setGraph1Data] = useState([]);

  const [graph2Data, setGraph2Data] = useState([]);

  const [graph3Data, setGraph3Data] = useState([]);

  const { id } = useParams();

  const {
    data: graphData,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetOneUserUsageQuery({ refresh, id });

  useEffect(() => {
    if (isSuccess && graphData?.success) {
      setGraph1Data(graphData?.data?.graph1?.[duration]);
      setGraph2Data(graphData?.data?.graph2?.[duration]);
      setGraph3Data(graphData?.data?.graph3?.[duration]);
    }
  }, [graphData, duration, isSuccess]);

  const fetchData = () => {
    setRefresh(true);
  };

  return (
    <>
      <Helmet>
        <title> User Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid
          container
          mb={5}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid marginBottom={2}>
            <Typography variant="h4">User Dashboard</Typography>
          </Grid>
          <Grid marginBottom={2} display={"flex"} justifyContent={"flex-end"}>
            <IconButton
              style={{ marginRight: theme.spacing(2) }}
              color="primary"
              aria-label="sync"
              onClick={fetchData}
            >
              <Sync />
            </IconButton>
            <Button
              style={{ marginRight: theme.spacing(1) }}
              variant={duration === "weekly" ? "contained" : "outlined"}
              type="button"
              id="weekly"
              onClick={() => setDuration("weekly")}
            >
              1W
            </Button>
            <Button
              style={{ marginRight: theme.spacing(1) }}
              variant={duration === "monthly" ? "contained" : "outlined"}
              type="button"
              id="monthly"
              onClick={() => setDuration("monthly")}
            >
              1M
            </Button>
            <Button
              style={{ marginRight: theme.spacing(1) }}
              variant={duration === "yearly" ? "contained" : "outlined"}
              type="button"
              id="yearly"
              onClick={() => setDuration("yearly")}
            >
              1Y
            </Button>
          </Grid>
          <Grid marginBottom={2} item>
            <Link to={"/dashboard/user"}>
              <Button variant="contained" color="primary">
                Back to User Table
              </Button>
            </Link>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {(isLoading || isFetching) && (
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Grid>
          )}
          {!isLoading && !isFetching && isError && (
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" color="error">
                Something went wrong!
              </Typography>
            </Grid>
          )}
          {!isError && isSuccess && (
            <>
              {graph2Data?.totalObjectCount > -1 && (
                <Grid item xs={12} sm={6} md={3} data-testid="count-card">
                  <AppWidgetNew
                    title="Total Object Detected"
                    total={graph2Data?.totalObjectCount}
                    color="warning"
                    icon={<SearchOutlined />}
                  />
                </Grid>
              )}

              {graph2Data?.totalWordCount > -1 && (
                <Grid item xs={12} sm={6} md={3} data-testid="count-card">
                  <AppWidgetNew
                    title="Total Words Read"
                    total={graph2Data?.totalWordCount}
                    color="info"
                    icon={<TextFieldsOutlined />}
                  />
                </Grid>
              )}

              {graph2Data?.totalCurrencyCount > -1 && (
                <Grid item xs={12} sm={6} md={3} data-testid="count-card">
                  <AppWidgetNew
                    title="Total Currency Detected"
                    total={graph2Data?.totalCurrencyCount}
                    color="success"
                    icon={<CurrencyRupeeOutlined />}
                  />
                </Grid>
              )}

              {graph2Data?.totalImagesCount > -1 && (
                <Grid item xs={12} sm={6} md={3} data-testid="count-card">
                  <AppWidgetNew
                    title="Total Images Captured"
                    total={graph2Data?.totalImagesCount}
                    color="success"
                    icon={<CameraEnhance />}
                    sx={{ height: "100%" }}
                  />
                </Grid>
              )}

              {graph2Data?.totalDocAICount > -1 && (
                <Grid item xs={12} sm={6} md={3} data-testid="count-card">
                  <AppWidgetNew
                    title="Total DocAI Count"
                    total={graph2Data?.totalDocAICount}
                    color="info"
                    icon={<DocumentScanner />}
                    sx={{ height: "100%" }}
                  />
                </Grid>
              )}

              {graph2Data?.totalSmartEyeCount > -1 && (
                <Grid item xs={12} sm={6} md={3} data-testid="count-card">
                  <AppWidgetNew
                    title="Total SmartEye Count"
                    total={graph2Data?.totalSmartEyeCount}
                    color="warning"
                    icon={<Visibility />}
                    sx={{ height: "100%" }}
                  />
                </Grid>
              )}

              {graph2Data?.totalAIChatCount > -1 && (
                <Grid item xs={12} sm={6} md={3} data-testid="count-card">
                  <AppWidgetNew
                    title="Total AI Tutor Count"
                    total={graph2Data?.totalAIChatCount}
                    icon={<Assistant />}
                    sx={{ height: "100%" }}
                  />
                </Grid>
              )}

              {graph1Data?.length > 0 && (
                <Grid
                  item
                  xs={12}
                  md={8}
                  lg={8}
                  sx={{
                    height: "100%",
                  }}
                >
                  <UsageChart chartData={graph1Data} duration={duration} />
                </Grid>
              )}

              {graph3Data && Object.entries(graph3Data)?.length > 0 && (
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  sx={{
                    height: "100%",
                  }}
                >
                  <LanguageChart
                    chartData={Object.entries(graph3Data)?.map(
                      ([label, val]) => ({
                        label,
                        value: val?.totalCount,
                      }),
                    )}
                    duration={duration}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>

        <Grid container mt={5}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Link to={"/dashboard/user"}>
              <Button variant="contained" color="primary">
                Back to User Table
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
