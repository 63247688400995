import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { Card, Container, Typography, Button, Stack } from "@mui/material";

import { useSelector } from "react-redux";
import { Edit } from "@mui/icons-material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useDeleteAuthUserMutation, useGetAuthUsersQuery } from "../redux/services/authUser.service";
import CustomTable from "../components/data-table/CustomDataTable";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";

import Protected from "../protected/Protected";
import AuthUserForm from "../sections/@dashboard/auth-user/authUser.form";
import { selectAbility } from "../redux/slices/casl.slice";
import CustomDeleteConfirmation from "../components/dialog/CustomDeleteConfirmation";

export default function AuthUserPage() {
  const ability = useSelector(selectAbility);

  const [openRow, setOpenRow] = useState(null);

  const [edit, setEdit] = useState(false);

  const [openAuthUserForm, setOpenAuthUserForm] = useState(false);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const [deleteAuthUser, { isLoading: isDeleting }] =
    useDeleteAuthUserMutation();

  const handleCloseAuthUserForm = () => {
    setOpenAuthUserForm(false);
    setEdit(false);
    setOpenRow(null);
  };

  return (
    <Protected>
      <Helmet>
        <title>Auth User</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Auth User
          </Typography>
          {ability.can("post", "AuthUser") && (
            <Button
              variant="contained"
              onClick={() => {
                setOpenAuthUserForm(true);
                setEdit(false);
                setOpenRow(null);
              }}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Auth User
            </Button>
          )}
          {(ability.can("patch", "AuthUser") ||
            ability.can("post", "AuthUser")) && (
            <AuthUserForm
              open={openAuthUserForm}
              onClose={handleCloseAuthUserForm}
              edit={edit}
              openRow={openRow}
            />
          )}
        </Stack>

        <Card>
          <Scrollbar>
            <CustomTable
              canSelect
              useDataQuery={useGetAuthUsersQuery}
              tableName="Auth User"
              searchLabel="Search Auth User..."
              searchCols={["name"]}
              // defaultVisibleCols={[
              //   "contactNumber",
              //   "name",
              //   "email",
              //   "city",
              //   "state",
              //   "country",
              //   "roleName",
              // ]}
              actions={
                ability.can("patch", "AuthUser") ||
                ability.can("delete", "AuthUser")
                  ? {
                      field: "actions",
                      headerName: "Actions",
                      type: "actions",
                      getActions: ({ row }) =>
                        [
                          ability.can("patch", "AuthUser") ? (
                            <GridActionsCellItem
                              key="edit"
                              icon={<Edit />}
                              label="Edit"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setEdit(true);
                                setOpenAuthUserForm(true);
                              }}
                            />
                          ) : null,
                          ability.can("delete", "AuthUser") ? (
                            <GridActionsCellItem
                              key="delete"
                              sx={{
                                color: "error.main",
                              }}
                              icon={
                                <Iconify
                                  color="error.main"
                                  icon="eva:trash-2-outline"
                                />
                              }
                              label="Delete"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setOpenDeleteConfirmation(true);
                              }}
                            />
                          ) : null,
                        ].filter((action) => action !== null),
                    }
                  : null
              }
            />
          </Scrollbar>
        </Card>
      </Container>
      {ability.can("delete", "AuthUser") && (
        <CustomDeleteConfirmation
          open={openDeleteConfirmation}
          onClose={() => setOpenDeleteConfirmation(false)}
          tableName="Auth User"
          deleteFunc={deleteAuthUser}
          row={openRow}
          nameKey="firstName"
          isLoading={isDeleting}
        />
      )}
    </Protected>
  );
}
