import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import UserPage from "./pages/UserPage";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/DashboardAppPage";
import OfferPage from "./pages/OfferPage";
import OrganizationPage from "./pages/OrganizationPage";
import DevicePage from "./pages/DevicePage";
import UserUsageDashboardPage from "./pages/UserUsageDashboardPage";
import SupportedDevicePage from "./pages/SupportedDevicePage";
import CountryPage from "./pages/CountryPage";
import OrderPage from "./pages/OrderPage";
import BlogPage from "./pages/BlogPage";
import RolePage from "./pages/RolePage";
import AuthUserPage from "./pages/AuthUserPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import VerifyUserPage from "./pages/VerifyUserPage";
import PermissionPage from "./pages/PermissionPage";
import WordsPage from "./pages/WordsPage";
import SupportedLanguagesPage from "./pages/SupportedLanguagesPage";
import DistributorPage from "./pages/DistributorPage";
import ColumnAccessControlPage from "./pages/ColumnAccessControlPage";
import FeatureAnalyticsPage from "./pages/FeatureAnalyticsPage";

import PlansPage from "./pages/PlansPage";


// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "app", element: <DashboardAppPage /> },
        { path: "feature-analytics", element: <FeatureAnalyticsPage /> },

        { path: "plans", element: <PlansPage /> },
        { path: "user", element: <UserPage /> },
        { path: "user/:id", element: <UserUsageDashboardPage /> },
        { path: "offer", element: <OfferPage /> },
        { path: "organization", element: <OrganizationPage /> },
        // { path: "usage", element: <UsagePage /> },
        { path: "device", element: <DevicePage /> },
        // { path: "user-usage", element: <UserUsagePage /> },
        // { path: 'user-usage/:id', element: <UserUsageDashboardPage /> },
        { path: "supported-device", element: <SupportedDevicePage /> },
        { path: "language", element: <SupportedLanguagesPage /> },
        { path: "words", element: <WordsPage /> },
        { path: "country", element: <CountryPage /> },
        { path: "order", element: <OrderPage /> },
        { path: "blog", element: <BlogPage /> },
        { path: "roles", element: <RolePage /> },
        { path: "authUser", element: <AuthUserPage /> },
        { path: "permission", element: <PermissionPage /> },
        { path: "distributor", element: <DistributorPage /> },
        { path: "column-access-control", element: <ColumnAccessControlPage /> },
      ],
    },
    {
      path: "login",
      element: <LoginPage />,
    },
    {
      path: "forgot-password",
      element: <ForgotPasswordPage />,
    },
    { path: "reset-password/:token", element: <ResetPasswordPage /> },
    { path: "verify-user", element: <VerifyUserPage /> },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
