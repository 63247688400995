export function matchUrlWithPathList(url, pathList) {
  // Destructure the url object into method and path
  const { method: urlMethod, url: urlPath } = url;

  // Normalize the URL path
  const normalizedUrlPath = normalizePath(urlPath);

  // Use Array.prototype.some to iterate through the pathList
  const isMatched = pathList?.some((pathObj) => {
    // Extract method and path from the pathObj
    const { method: pathMethod, route: pathPath } = pathObj;

    // Check if method matches
    if (urlMethod?.toLowerCase() !== pathMethod?.toLowerCase()) {
      return false;
    }

    // Normalize the path from pathObj
    const normalizedPathPath = normalizePath(pathPath);
    // Create a regular expression from the normalized path path
    const pathRegex = new RegExp(`^${normalizedPathPath}$`);
    // Check if the normalized URL path matches the regular expression
    return pathRegex?.test(normalizedUrlPath);
  });
  // Return the result
  return !!isMatched;
}

export function normalizePath(path) {
  // Remove query string
  let pathWithoutQuery = path?.split("?")?.[0];
  if (pathWithoutQuery?.endsWith("/")) {
    pathWithoutQuery = pathWithoutQuery?.slice(0, -1);
  }
  if (pathWithoutQuery?.startsWith("/api/v1/")) {
    pathWithoutQuery = pathWithoutQuery?.slice(8);
  }
  // Replace path parameters (e.g. :id) with a regex pattern
  const normalizedPath = pathWithoutQuery?.replace(/\/:[^/]+/g, "/[^/]+");
  return normalizedPath;
}

// // Example usage:
// const url = {
//   method: "patch",
//   url: "user/123/456",
// };

// const pathList = [
//   { method: "GET", route: "/api/v1/user" },
//   { method: "PATCH", route: "/api/v1/user/:id/:id" },
// ];

// console.log(matchUrlWithPathList(url, pathList)); // This will print true because it matches with the second path in the list
