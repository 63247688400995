import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { useSelector } from "react-redux";
// @mui
import { Card, Stack, Button, Container, Typography, Box } from "@mui/material";

// components
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Edit, SwapHoriz } from "@mui/icons-material";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
import Protected from "../protected/Protected";
// sections
import CustomTable from "../components/data-table/CustomDataTable";
import {
  useDeleteBlogMutation,
  useGetBlogsQuery,
} from "../redux/services/blog.service";
import BlogForm from "../sections/@dashboard/blog/blog.form";
import { selectAbility } from "../redux/slices/casl.slice";
import CustomDeleteConfirmation from "../components/dialog/CustomDeleteConfirmation";
import PriorityForm from "../sections/@dashboard/blog/priority.form";

export default function BlogPage() {
  const ability = useSelector(selectAbility);

  const [deleteBlog, { isLoading: isDeleting }] = useDeleteBlogMutation();

  const [openBlogForm, setOpenBlogForm] = useState(false);

  const [openPriorityForm, setOpenPriorityForm] = useState(false);

  const [openRow, setOpenRow] = useState(null);

  const [edit, setEdit] = useState(false);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleCloseForm = () => {
    setOpenBlogForm(false);
    setEdit(false);
    setOpenRow(null);
  };

  return (
    <Protected>
      <Helmet>
        <title> Blogs </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Blogs
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            {ability.can("post", "Blog") && (
              <Button
                variant="contained"
                onClick={() => {
                  setOpenBlogForm(true);
                }}
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Blog
              </Button>
            )}
            {ability.can("patch", "Change-Priority") && (
              <Button
                variant="contained"
                onClick={() => {
                  setOpenPriorityForm(true);
                }}
                startIcon={<SwapHoriz />}
              >
                Change Priority
              </Button>
            )}
          </Box>
          {(ability.can("patch", "Blog") || ability.can("delete", "Blog")) && (
            <BlogForm
              open={openBlogForm}
              onClose={handleCloseForm}
              edit={edit}
              openRow={openRow}
            />
          )}
          {ability.can("patch", "Change-Priority") && (
            <PriorityForm
              open={openPriorityForm}
              onClose={() => {
                setOpenPriorityForm(false);
              }}
            />
          )}
        </Stack>

        <Card>
          <Scrollbar>
            <CustomTable
              canSelect
              useDataQuery={useGetBlogsQuery}
              tableName="Blogs"
              searchLabel="Search Blog..."
              searchCols={["title", "tags"]}
              actions={
                ability.can("patch", "Blog") || ability.can("delete", "Blog")
                  ? {
                      field: "actions",
                      headerName: "Action",
                      type: "actions",
                      getActions: ({ row }) =>
                        [
                          ability.can("patch", "Blog") ? (
                            <GridActionsCellItem
                              key="edit"
                              icon={<Edit />}
                              label="Edit"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setEdit(true);
                                setOpenBlogForm(true);
                              }}
                            />
                          ) : null,
                          ability.can("delete", "Blog") ? (
                            <GridActionsCellItem
                              key="delete"
                              sx={{
                                color: "error.main",
                              }}
                              icon={
                                <Iconify
                                  color="error.main"
                                  icon="eva:trash-2-outline"
                                />
                              }
                              label="Delete"
                              showInMenu
                              onClick={() => {
                                setOpenRow(row);
                                setOpenDeleteConfirmation(true);
                              }}
                            />
                          ) : null,
                        ].filter((action) => action !== null),
                    }
                  : null
              }
            />
          </Scrollbar>
        </Card>
      </Container>

      {ability.can("delete", "Blog") && (
        <CustomDeleteConfirmation
          open={openDeleteConfirmation}
          onClose={() => setOpenDeleteConfirmation(false)}
          tableName="Blog"
          nameKey="title"
          deleteFunc={deleteBlog}
          row={openRow}
          isLoading={isDeleting}
        />
      )}
    </Protected>
  );
}
