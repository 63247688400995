import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBaseQuery } from "../basequery";

export const columnAccessControlApi = createApi({
  reducerPath: "columnAccessControlApi",
  baseQuery: extendedBaseQuery,
  tagTypes: ["ColumnAccessControl"],
  endpoints: (builder) => ({
    getEntities: builder.query({
      query: () => ({
        url: `role-columns/all-entity`,
        method: "GET",
      }),
      providesTags: ["ColumnAccessControl"],
    }),
    getColumns: builder.query({
      query: (params) => {
        const query = {};
        if (params?.skip) query.skip = params.skip;
        if (params?.limit) query.limit = params.limit;
        if (params?.search) query.search = params.search;
        if (params?.filter) query.filter = params.filter;
        if (params?.sortBy) query.sortBy = params.sortBy;
        if (params?.orderBy) query.orderBy = params.orderBy;
        if (params?.entityName) query.entityName = params.entityName;
        if (params?.roleId) query.roleId = params.roleId;

        return {
          url: `role-columns?${new URLSearchParams(query)}`,
          method: "GET",
        };
      },
      providesTags: ["ColumnAccessControl"],
    }),
    updateColumn: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `role-columns/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["ColumnAccessControl"],
    }),
    createColumn: builder.mutation({
      query: (body) => ({
        url: `role-columns`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ColumnAccessControl"],
    }),
  }),
});

export const {
  useGetEntitiesQuery,
  useGetColumnsQuery,
  useUpdateColumnMutation,
  useCreateColumnMutation,
} = columnAccessControlApi;
