import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Grid,
  Stack,
  Button,
  Drawer,
  Divider,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Box,
  FormHelperText,
  CircularProgress,
  TextField,
  IconButton,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { Close, UploadFile } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useGetOrganizationsQuery } from "../../../redux/services/organization.service";
import Scrollbar from "../../../components/scrollbar";
import CustomDialog from "../../../components/dialog/CustomDialog";
import { useUploadSingleFileMutation } from "../../../redux/services/upload.service";
import { useBulkUserCreateMutation } from "../../../redux/services/user.service";
import { BulkAddUserSchema } from "../../../forms-schema/form.schema";
import { useGetEventPlacesQuery } from "../../../redux/services/device.service";

BulkAddUserForm.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  onDownload: PropTypes.func,
  setRows: PropTypes.func,
};

const supportedFileTypes = [
  // "text/csv",
  "application/vnd.ms-excel",
  // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export default function BulkAddUserForm({ open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [selectedEventPlace, setSelectedEventPlace] = useState("");
  const [filteredTags, setFilteredTags] = useState([]); // State to store filtered tags

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(BulkAddUserSchema),
  });

  useEffect(() => {
    reset({
      senderOrganizationId: "",
      recipientOrganizationId: "",
    });
    setSelectedFile(null);
    setFileError("");
  }, [open, reset]);

  const {
    isLoading: isOrgLoading,
    isFetching: isOrgFetching,
    data: organizations,
  } = useGetOrganizationsQuery(null, {
    // skip: !["admin", "distributor"].includes(user?.role?.name),
  });

  const {
    data: eventPlaces,
    isSuccess: isSuccessEventPlaces,
    isLoading: isLoadingEventPlaces,
  } = useGetEventPlacesQuery({
    organizationId: watch("organizationId"),
  });

  const [createBulkUser, { isLoading }] = useBulkUserCreateMutation();
  const [uploadFile, { isLoading: isFileUploading }] =
    useUploadSingleFileMutation();

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openExistingUserDialog, setOpenExistingUserDialog] = useState(false);
  const [existingUsers, setExistingUsers] = useState([]);

  const handleClick = async (values) => {
    setLoading(true);
    try {
      // Attempt to upload the file
      const uploadResult = await uploadFile(selectedFile);

      if (uploadResult?.data?.success) {
        // Attempt to create bulk users if the file upload is successful
        const resultAction = await createBulkUser({
          ...values,
          url: uploadResult?.data?.data?.Location,
        });

        if (resultAction?.data?.success) {
          reset();
          setSelectedFile(null);
          onClose();
          setOpenConfirmationDialog(false);

          // Handle existing users if returned from the bulk user creation
          if (resultAction?.data?.data?.existUsers?.length) {
            setOpenExistingUserDialog(true);
            setExistingUsers(resultAction?.data?.data?.existUsers);
          }
        } else {
          setOpenConfirmationDialog(false);
        }
      }
    } catch (error) {
      // Catch and handle any errors during the process
      console.error(
        "Error occurred during file upload or user creation:",
        error,
      );

      // Display error using notistack
      enqueueSnackbar("An error occurred. Please try again later.", {
        variant: "error", // Set the snackbar type to 'error'
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    } finally {
      setLoading(false); // Ensure loading state is turned off in all cases
    }
  };

  const fileRef = useRef();

  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];

      // Check if the file exists
      if (!file) {
        enqueueSnackbar("No file selected", {
          variant: "error", // Display error message
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        return;
      }

      // Check if the file type is supported
      if (!supportedFileTypes.includes(file.type)) {
        setSelectedFile(null);
        enqueueSnackbar("Unsupported file format", {
          variant: "error", // Display error message
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        return;
      }

      // Clear the error and set the selected file
      setFileError("");
      setSelectedFile(file);
    } catch (error) {
      // Catch and log any errors
      console.error("Error while handling file change:", error);

      // Show a generic error message using notistack
      enqueueSnackbar("An error occurred while processing the file.", {
        variant: "error", // Display error message
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    try {
      const file = e.dataTransfer.files[0];

      // Check if a file is actually dropped
      if (!file) {
        enqueueSnackbar("No file selected", {
          variant: "error", // Display error message
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        return;
      }

      // Check if the file type is supported
      if (!supportedFileTypes.includes(file.type)) {
        setSelectedFile(null);
        enqueueSnackbar("Unsupported file format", {
          variant: "error", // Display error message
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        return;
      }

      // Clear any previous error and set the selected file
      setFileError("");
      setSelectedFile(file);
    } catch (error) {
      // Catch and log any errors
      console.error("Error while handling file drop:", error);

      // Show a generic error message using notistack
      enqueueSnackbar("An error occurred while processing the file.", {
        variant: "error", // Display error message
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const handleOpenConfirmationDialog = () => {
    if (!selectedFile) {
      setFileError("Please select a file");
      return;
    }
    setOpenConfirmationDialog(true);
  };

  const handleEventPlaceChange = (event) => {
    try {
      const selectedPlace = event.target.value;
      setSelectedEventPlace(selectedPlace);

      // Filter tags based on selected event place
      const tags =
        eventPlaces?.data
          .filter(
            (event) => event.eventPlace === selectedPlace && event.tag !== null,
          )
          .map((event) => event.tag) || [];

      setFilteredTags(tags);

      // Reset the tag field (clear the value)
      setValue("tag", ""); // This will clear the tag field
    } catch (error) {
      // Catch and log any errors
      console.error("Error while handling event place change:", error);
    }
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 360, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">Bulk Add User</Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <Stack spacing={3}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  error={Boolean(errors.senderOrganizationId)}
                >
                  Sender Organization
                </InputLabel>
                <Select
                  {...register("senderOrganizationId", { required: true })}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sender Organization"
                  inputProps={{ "data-testid": "senderOrganizationId" }}
                  error={Boolean(errors.senderOrganizationId)}
                  value={watch("senderOrganizationId")}
                >
                  {isOrgLoading || isOrgFetching ? (
                    <MenuItem
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size={20} />
                    </MenuItem>
                  ) : (
                    organizations?.data
                      ?.toSorted((a, b) => a?.name?.localeCompare(b?.name))
                      ?.map((org) => (
                        <MenuItem key={org?.id} value={org?.id}>
                          {org?.name}
                        </MenuItem>
                      ))
                  )}
                </Select>
                <FormHelperText error={Boolean(errors.senderOrganizationId)}>
                  {errors.senderOrganizationId?.message}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel
                  id="recipientOrganizationLabel"
                  error={Boolean(errors.recipientOrganizationId)}
                >
                  Recipient Organization
                </InputLabel>
                <Select
                  {...register("recipientOrganizationId", { required: true })}
                  labelId="recipientOrganizationLabel"
                  id="demo-simple-select"
                  label="Recipient Organization"
                  inputProps={{ "data-testid": "recipientOrganizationId" }}
                  error={Boolean(errors.recipientOrganizationId)}
                  value={watch("recipientOrganizationId")}
                >
                  {isOrgLoading || isOrgFetching ? (
                    <MenuItem
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size={20} />
                    </MenuItem>
                  ) : (
                    organizations?.data
                      ?.toSorted((a, b) => a?.name?.localeCompare(b?.name))
                      ?.map((org) => (
                        <MenuItem key={org?.id} value={org?.id}>
                          {org?.name}
                        </MenuItem>
                      ))
                  )}
                </Select>
                <FormHelperText error={Boolean(errors.recipientOrganizationId)}>
                  {errors.recipientOrganizationId?.message}
                </FormHelperText>
              </FormControl>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="eventPlace-label">Event Place</InputLabel>
                  <Select
                    labelId="eventPlace-label"
                    id="demo-simple-select"
                    {...register("eventPlace")} // Register eventPlace
                    label="Event Place"
                    value={selectedEventPlace}
                    onChange={handleEventPlaceChange}
                    inputProps={{ "data-testid": "eventPlace" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {isLoadingEventPlaces && (
                      <MenuItem>
                        <CircularProgress size={24} />
                      </MenuItem>
                    )}
                    {isSuccessEventPlaces &&
                      eventPlaces?.data?.map((eventPlace, index) => (
                        <MenuItem
                          key={`${eventPlace.eventPlace}-${index}`}
                          value={eventPlace.eventPlace}
                        >
                          {eventPlace.eventPlace}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="tag-label">Tag</InputLabel>
                  <Select
                    labelId="tag-label"
                    id="tag-select"
                    {...register("tag")} // Register tag
                    label="Tag"
                    value={watch("tag") ?? ""}
                    disabled={filteredTags.length === 0} // Disable tag select if no tags are available
                    inputProps={{ "data-testid": "tag" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {filteredTags.length > 0 ? (
                      filteredTags.map((tag, index) => (
                        <MenuItem key={index} value={tag}>
                          {tag}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No tags available</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>

              <InputLabel htmlFor="logo">File</InputLabel>

              <input
                onChange={handleFileChange}
                ref={fileRef}
                type="file"
                // accept=".xlsx, .xls, .csv"
                hidden
                data-testid="file-input"
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px dashed #D9D9D9",
                  borderRadius: "10px",
                  padding: "20px",
                  justifyContent: "center",
                  flexDirection: "column",
                  cursor: "pointer",
                  height: 200,
                  "&:hover": {
                    backgroundColor: "#F5F5F5",
                  },
                }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
              >
                <UploadFile
                  sx={{
                    fontSize: "50px",
                    color: "#D9D9D9",
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    marginTop: 0.5,
                    textAlign: "center",
                    fontStyle: "italic",
                  }}
                >
                  {selectedFile ? selectedFile.name : "(No file selected)"}
                </Typography>
                <Button
                  sx={{
                    marginTop: 3,
                    boxShadow: "none",
                  }}
                  variant="outlined"
                  onClick={() => fileRef.current.click()}
                >
                  Browse
                </Button>
                <Typography
                  variant="body2"
                  sx={{
                    marginTop: 0.5,
                    textAlign: "center",
                  }}
                >
                  Or drag here{" "}
                  <Typography
                    component={"span"}
                    variant="body2"
                    sx={{
                      fontStyle: "italic",
                    }}
                  >
                    (.xlsx)
                  </Typography>
                </Typography>
              </Box>
              {fileError && (
                <FormHelperText error={Boolean(fileError)}>
                  {fileError}
                </FormHelperText>
              )}

              <a
                href="https://sunbots-people.s3.ap-south-1.amazonaws.com/1724828563100_1710311771521_SmartOn%20User%20Form.xlsx"
                style={{
                  textTransform: "none",
                  textDecoration: "underline",
                  color: "#1976d2",
                  cursor: "pointer",
                }}
                rel="noreferrer"
                target="_blank"
              >
                Download Template
              </a>

              <LoadingButton
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                // disabled={errors.senderOrganizationId || !selectedFile || fileError || errors.recipientOrganizationId}
                onClick={handleSubmit(handleOpenConfirmationDialog)}
              >
                Submit
              </LoadingButton>
            </Stack>
          </Stack>
        </Scrollbar>
      </Drawer>
      <CustomDialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        title="File Upload"
        description={`Are you sure you want to upload ${selectedFile?.name} file and proceed?`}
        onConfirm={handleSubmit(handleClick)}
        isLoading={loading}
      />
      <CustomDialog
        open={openExistingUserDialog}
        // onClose={() => {
        //   setOpenExistingUserDialog(false);
        //   onClose();
        // }}
        title="Existing Users"
        description={
          <>
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", marginBottom: 2 }}
            >
              The following users already exist in the system. Please review and
              try again.
            </Typography>
            {existingUsers?.length && (
              <DataGrid
                columns={[
                  {
                    field: "contactNumber",
                    headerName: "Contact Number",
                    width: 150,
                  },
                  {
                    field: "email",
                    headerName: "Email",
                    width: 180,
                  },
                  {
                    field: "firstName",
                    headerName: "First Name",
                    width: 150,
                  },
                  {
                    field: "lastName",
                    headerName: "Last Name",
                    width: 150,
                  },
                ]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                rows={existingUsers}
                sx={{ width: "100%" }}
                getRowId={(row) => row?.id}
                // select
                disableRowSelectionOnClick
                filterMode="client"
                // pagination
                pagination
                pageSizeOptions={[10, 25, 50, 100]}
                paginationMode="client"
                // sorting
                sortingMode="client"
                scrollbarSize={1}
                autoHeight
                slots={{
                  toolbar: GridToolbarExport,
                }}
              />
            )}
          </>
        }
        cancelButton={false}
        confirmText="Close"
        onConfirm={() => {
          setOpenExistingUserDialog(false);
          setOpenConfirmationDialog(false);
        }}
        isLoading={loading}
      />
    </>
  );
}
