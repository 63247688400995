import { Helmet } from "react-helmet-async";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
// @mui
import {
  Card,
  Stack,
  Button,
  MenuItem,
  Container,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,

} from "@mui/material";
import { useForm } from "react-hook-form";
import {
  useDeleteDeviceMutation,
  useGetDevicesQuery,
  useGetEventPlacesQuery,
} from "../redux/services/device.service";

// components
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar";
import Protected from "../protected/Protected";
// sections
import DeviceForm from "../sections/@dashboard/device/device.form";
import CustomTable from "../components/data-table/CustomDataTable";
import { selectAbility } from "../redux/slices/casl.slice";
import CustomDeleteConfirmation from "../components/dialog/CustomDeleteConfirmation";
import GenerateCodeForm from "../sections/@dashboard/device/generateDistributorCode.form";
import { useGetOrganizationsQuery } from "../redux/services/organization.service";
import useResponsive from "../hooks/useResponsive";

export default function DevicePage() {
  const ability = useSelector(selectAbility);

  const [openDeviceForm, setOpenDeviceForm] = useState(false);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const [openGenerateCodeForm, setOpenGenerateCodeForm] = useState(false);

  const [openRow, setOpenRow] = useState(null);

  const [deleteDevice, { isLoading: isDeleting }] = useDeleteDeviceMutation();

  const [selectedEventPlace, setSelectedEventPlace] = useState("");
  const [filteredTags, setFilteredTags] = useState([]);

  const downloadRef2 = useRef(null);

  const isTablet = useResponsive("down", "lg");

  const handleDownloadCodes = (devices) => {
    const data = devices.map((code) => `${code},`);

    const textContent = data.join("\n");

    const blob = new Blob([textContent], { type: "text/plain" });

    const url = URL.createObjectURL(blob);

    downloadRef2.current.href = url;
    downloadRef2.current.click();

    URL.revokeObjectURL(url);
  };

  const {
    isLoading: isOrgLoading,
    isFetching: isOrgFetching,
    data: organizations,
  } = useGetOrganizationsQuery(null, {
    // skip: user?.role?.name !== "admin",
  });

  const {
    data: eventPlaces,
    isSuccess: isSuccessEventPlaces,
    isLoading: isLoadingEventPlaces,
  } = useGetEventPlacesQuery({
    organizationId: localStorage.getItem("organizationId"),
  });
  
  const {
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      organizationId: "",
      distributorId: "",
    },
  });


  const handleResetFilter = () => {
    setValue("organizationId", "");
    setSelectedEventPlace("");
    setValue("tag", "");
    setValue("deployDates", []);
  };

  const handleEventPlaceChange = (event) => {
    try {
      const selectedPlace = event.target.value;
      setSelectedEventPlace(selectedPlace);

      // Filter tags based on selected event place
      const tags =
        eventPlaces?.data
          .filter(
            (event) => event.eventPlace === selectedPlace && event.tag !== null,
          )
          .map((event) => event.tag) || [];

      setFilteredTags(tags);

      // Reset the tag field (clear the value)
      setValue("tag", ""); // This will clear the tag field
    } catch (error) {
      // Catch and log any errors
      console.error("Error while handling event place change:", error);
    }
  };

  console.log(filteredTags);

  return (
    <Protected>
      <Helmet>
        <title> Devices </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Device
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            {ability.can("post", "Device") && (
              <Button
                variant="contained"
                onClick={setOpenDeviceForm}
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Devices
              </Button>
            )}
            {ability.can("post", "Generate-Distributor-Device-Code") && (
              <Button
                variant="contained"
                onClick={() => {
                  setOpenGenerateCodeForm(true);
                }}
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Generate Device Code
              </Button>
            )}
          </Box>

          {(ability.can("post", "Device") ||
            ability.can("patch", "Device")) && (
            <DeviceForm
              open={openDeviceForm}
              onClose={() => {
                setOpenDeviceForm(false);
              }}
              onDownload={handleDownloadCodes}
            />
          )}

          {ability.can("post", "Generate-Distributor-Device-Code") && (
            <GenerateCodeForm
              open={openGenerateCodeForm}
              onClose={() => setOpenGenerateCodeForm(false)}
              edit={false}
              onDownload={handleDownloadCodes}
            />
          )}
        </Stack>

        <Card>
          <a
            ref={downloadRef2}
            style={{ display: "none" }}
            download="devices.txt"
          >
            a
          </a>

          <Scrollbar>
            <CustomTable
              canSelect
              useDataQuery={useGetDevicesQuery}
              defaultQueryParams={{
                organizationId: watch("organizationId"),

                eventPlace: selectedEventPlace,
                tag: watch("tag"),

              }}
              tableName="Device"
              searchLabel="Search Device..."
              searchCols={["code"]}
              resetParentForm={handleResetFilter}
              extraHeaderField={
                <Box
                  sx={{
                    display: "flex",

                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    flexWrap: "wrap", // Allow wrapping to handle small screens
                    gap: 1, // Add space between the elements
                    marginTop: 2, // Optional: add some margin for better separation
                    width: "100%",
                    marginLeft: 1,
                  }}
                >
                  {/* Organization Select */}
                  {ability.can("get", "Organizations") && (
                    <FormControl fullWidth sx={{ flexBasis: "32%" }}>
                      <InputLabel
                        id="org-select-label"
                        error={Boolean(errors.organizationId)}
                      >
                        Organization
                      </InputLabel>
                      <Select
                        {...register("organizationId", { required: true })}
                        labelId="org-select-label"
                        id="demo-simple-select"
                        inputProps={{ "data-testid": "organizationId" }}
                        label="Organization"
                        error={Boolean(errors.organizationId)}
                        value={watch("organizationId")}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {isOrgLoading || isOrgFetching ? (
                          <MenuItem
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <CircularProgress size={20} />
                          </MenuItem>
                        ) : (
                          organizations?.data
                            ?.toSorted((a, b) =>
                              a?.name?.localeCompare(b?.name),
                            )
                            ?.map((org) => (
                              <MenuItem key={org?.id} value={org?.id}>
                                {org?.name}
                              </MenuItem>
                            ))
                        )}
                      </Select>
                    </FormControl>
                  )}


                  {/* Event Place Select */}
                  <Box sx={{ flexBasis: "32%" }}>
                    {ability.can("get", "All-Event-Place") && (
                      <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel id="eventPlace-label">
                          Event Place
                        </InputLabel>
                        <Select
                          labelId="eventPlace-label"
                          id="demo-simple-select"
                          {...register("eventPlace")}
                          label="Event Place"
                          value={selectedEventPlace}
                          onChange={handleEventPlaceChange}
                          inputProps={{ "data-testid": "eventPlace" }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {isLoadingEventPlaces && (
                            <MenuItem>
                              <CircularProgress size={24} />
                            </MenuItem>
                          )}
                          {isSuccessEventPlaces &&
                            eventPlaces?.data?.map((eventPlace, index) => (
                              <MenuItem
                                key={`${eventPlace.eventPlace}-${index}`}
                                value={eventPlace.eventPlace}
                              >
                                {eventPlace.eventPlace}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  </Box>

                  {/* Tag Select */}
                  <Box sx={{ flexBasis: "32%" }}>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                      <InputLabel id="tag-label">Tag</InputLabel>
                      <Select
                        labelId="tag-label"
                        id="tag-select"
                        {...register("tag")}
                        label="Tag"
                        value={watch("tag") ?? ""}
                        disabled={filteredTags.length === 0}
                        inputProps={{ "data-testid": "tag" }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {filteredTags.length > 0 ? (
                          filteredTags.map((tag, index) => (
                            <MenuItem key={index} value={tag}>
                              {tag}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No tags available</MenuItem>
                        )}
                      </Select>
                    </FormControl>

                  </Box>
                </Box>
              }
            />
          </Scrollbar>
        </Card>
      </Container>

      {ability.can("delete", "Offer") && (
        <CustomDeleteConfirmation
          open={openDeleteConfirmation}
          onClose={() => setOpenDeleteConfirmation(false)}
          tableName="Device"
          deleteFunc={deleteDevice}
          nameKey="code"
          row={openRow}
          isLoading={isDeleting}
        />
      )}
    </Protected>
  );
}
