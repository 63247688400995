import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Stack,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Edit } from "@mui/icons-material";
import { selectAbility } from "../redux/slices/casl.slice";
import Protected from "../protected/Protected";
import Iconify from "../components/iconify";
import ActivatePlans from "../sections/@dashboard/plans/ActivatePlans.form";
import Scrollbar from "../components/scrollbar/Scrollbar";
import CustomTable from "../components/data-table/CustomDataTable";
import { useGetPlansQuery } from "../redux/services/plans.service";
import AddPlansForm from "../sections/@dashboard/plans/AddPlansForm";
import PlansForm from "../sections/@dashboard/plans/plans.form";

export default function UserPage() {
  const ability = useSelector(selectAbility);

  const [openActivatePlansForm, setOpenActivatePlansForm] = useState(false);
  const [openAddPlansForm, setOpenAddPlansForm] = useState(false);
  const [openUpdatePlansForm, setOpenUpdatePlansForm] = useState(false);

  const [openRow, setOpenRow] = useState(null);

  return (
    <Protected>
      <Helmet>
        <title>Plans</title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Plans
          </Typography>
          <Box display="flex" alignItems="center" gap={1}>
            {ability.can("post", "activate-plan") && (
              <>
                <Button
                  variant="contained"
                  startIcon={<Iconify icon="eva:checkmark-fill" />}
                  onClick={() => setOpenActivatePlansForm(true)}
                >
                  Activate Plan
                </Button>
                <Button
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  onClick={() => setOpenAddPlansForm(true)}
                >
                  Add Plans
                </Button>
              </>
            )}
          </Box>
        </Stack>

        {ability.can("post", "activate-plan") && (
          <ActivatePlans
            open={openActivatePlansForm}
            onClose={() => setOpenActivatePlansForm(false)}
          />
        )}

        {ability.can("post", "add-plan") && (
          <AddPlansForm
            open={openAddPlansForm}
            onClose={() => setOpenAddPlansForm(false)}
          />
        )}

        {ability.can("patch", "update-plan") && (
          <PlansForm
            open={openUpdatePlansForm}
            onClose={() => setOpenUpdatePlansForm(false)}
            openRow={openRow}
            edit
          />
        )}

        <Card>
          <Scrollbar>
            <CustomTable
              canSelect
              useDataQuery={useGetPlansQuery}
              tableName="Plans"
              searchLabel="Search Plan..."
              searchCols={["name"]}
              actions={
                ability.can("patch", "update-plan")
                  ? {
                      field: "actions",
                      headerName: "Actions",
                      type: "actions",
                      getActions: ({ row }) => [
                        ability.can("Is", "admin") ? (
                          <GridActionsCellItem
                            key="edit"
                            icon={<Edit />}
                            label="Edit"
                            showInMenu
                            onClick={() => {
                              setOpenRow(row);
                              setOpenUpdatePlansForm(true);
                            }}
                          />
                        ) : null,
                      ],
                    }
                  : null
              }
              extraHeaderField={
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <FormControl
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      margin: "0 1rem",
                      gap: "1rem",
                    }}
                  />
                </Box>
              }
            />
          </Scrollbar>
        </Card>
      </Container>
    </Protected>
  );
}
