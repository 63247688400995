import ResetPasswordForm from "../sections/auth/reset-password/resetPassword.form";
import AuthLayout from "../layouts/auth/AuthLayout";

export default function ResetPasswordPage() {
  return (
    <AuthLayout formHeading="Reset Your Password" title="Reset Password | Sunbots">
      <ResetPasswordForm />
    </AuthLayout>
  );
}
