import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Checkbox,
  Typography,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
  Box,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

// components
import { Close } from "@mui/icons-material";
import { useUpdatePermissionMutation } from "../../../redux/services/permission.service";
import Scrollbar from "../../../components/scrollbar";
import { PermissionSchema } from "../../../forms-schema/form.schema";
import { useGetRolesQuery } from "../../../redux/services/role.service";

PermissionForm.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  openRow: PropTypes.object,
};

export default function PermissionForm({ open, onClose, edit, openRow }) {
  const {
    isLoading: isRolesLoading,
    isFetching: isRolesFetching,
    data: roles,
  } = useGetRolesQuery();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(PermissionSchema),
  });

  // const [createPermission, { isLoading }] = useCreatePermissionMutation();
  const [updatePermission, { isLoading: isUpdateLoading }] =
    useUpdatePermissionMutation();

  const handleClick = async (values) => {
    // let resultAction;
    // if (edit) {
    const resultAction = await updatePermission({
      ...values,
      id: openRow.id,
    });
    // } else {
    //   resultAction = await createPermission({ ...values });
    // }
    if (resultAction?.data?.success) {
      onClose();
      reset();
    }
  };

  useEffect(() => {
    if (edit) {
      setValue("tag", openRow.tag);
      setValue("description", openRow.description);
      setValue("protected", openRow.protected);
      setValue("role_ids", openRow?.role_ids ?? []);
    } else {
      reset();
    }
  }, [edit, open, openRow, reset, setValue]);

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: { width: 400, border: "none", overflow: "hidden" },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">
                  {edit ? "Edit" : "Create"} Permission
                </Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                {edit && (
                  <TextField
                    name="route"
                    label="Route"
                    value={edit ? openRow.route : ""}
                    disabled
                  />
                )}

                <TextField
                  name="tag"
                  label="Tag"
                  {...register("tag", { required: true })}
                  error={!!errors.tag}
                  helperText={errors.tag?.message}
                  inputProps={{ "data-testid": "tag" }}
                />

                <TextField
                  name="description"
                  label="Description"
                  {...register("description", { required: true })}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                  inputProps={{ "data-testid": "description" }}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={edit && openRow?.protected}
                      checked={watch("protected")}
                      {...register("protected")}
                      inputProps={{ "data-testid": "protected" }}
                    />
                  }
                  label="Protected"
                />

                <FormControl fullWidth error={!!errors.role_ids}>
                  <InputLabel id="role_ids" error={!!errors.role_ids}>
                    Role
                  </InputLabel>
                  <Select
                    id="demo-simple-select"
                    multiple
                    value={watch("role_ids") || []}
                    label="Type"
                    error={!!errors.role_ids}
                    labelId="role_ids"
                    name="role_ids"
                    {...register("role_ids", { required: true })}
                    renderValue={(selected) =>
                      selected
                        ?.map(
                          (value) =>
                            roles?.data?.find((role) => role?.id === value)
                              ?.name,
                        )
                        ?.join(", ")
                    }
                    inputProps={{ "data-testid": "orgRoleId" }}
                  >
                    {isRolesLoading ||
                      (isRolesFetching && (
                        <MenuItem
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          disabled
                        >
                          <CircularProgress size={20} />
                        </MenuItem>
                      ))}
                    {!isRolesLoading &&
                      !isRolesFetching &&
                      roles?.data
                        ?.toSorted((a, b) => a?.name.localeCompare(b?.name))
                        ?.map((role) => (
                          <MenuItem key={role?.id} value={role?.id}>
                            <Checkbox
                              checked={
                                watch("role_ids")?.indexOf(role?.id) > -1
                              }
                            />
                            {role?.name}
                          </MenuItem>
                        ))}
                  </Select>
                  <FormHelperText>{errors.role_ids?.message}</FormHelperText>
                </FormControl>

                <LoadingButton
                  loading={isUpdateLoading}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
