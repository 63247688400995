import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBaseQuery } from "../basequery";

export const languageApi = createApi({
  reducerPath: "languageApi",
  baseQuery: extendedBaseQuery,
  tagTypes: ["Language"],
  endpoints: (builder) => ({
    getLanguages: builder.query({
      query: () => ({
        url: "language/get-languages",
        method: "GET",
      }),
      providesTags: ["Language"],
    }),
    getWordsOfLanguage: builder.query({
      query: ({ languageCode, ...params }) => {
        const query = {};
        if (params?.skip) query.skip = params.skip;
        if (params?.limit) query.limit = params.limit;
        if (params?.search) query.search = params.search;
        if (params?.filter) query.filter = params.filter;
        if (params?.sortBy) query.sortBy = params.sortBy;
        if (params?.orderBy) query.orderBy = params.orderBy;
        
        query.languageCode = languageCode;

        return {
          url: `language/get-words?${new URLSearchParams(query).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Language"],
    }),
    addEnglishWords: builder.mutation({
      query: (body) => ({
        url: "language/add-english-words",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Language"],
    }),
    addEnglishWord: builder.mutation({
      query: (body) => ({
        url: `language/add-word`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Language"],
    }),
    addLanguage: builder.mutation({
      query: (body) => ({
        url: `language/add-language`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Language"],
    }),
  }),
});

export const {
  useGetLanguagesQuery,
  useGetWordsOfLanguageQuery,
  useAddEnglishWordsMutation,
  useAddEnglishWordMutation,
  useAddLanguageMutation,
} = languageApi;
