import { createApi } from "@reduxjs/toolkit/query/react";
import { format } from "date-fns";
import { extendedBaseQuery } from "../basequery";

export const googleAnalyticsApi = createApi({
  reducerPath: "googleAnalyticsApi",
  baseQuery: extendedBaseQuery,
  endpoints: (builder) => ({
    getGoogleAnalytics: builder.query({
      query: ({
        refresh,
        organizationId,
        deviceCreationDates,
        distributorId,
        eventPlace,
        tag,
        cameraType,
        userType,
      }) => {
        const query = {};
        query.refresh = refresh;
        if (organizationId) query.organizationId = organizationId;
        if (deviceCreationDates?.length)
          query.deviceCreationDates = deviceCreationDates;
        if (distributorId) query.distributorId = distributorId;
        if (eventPlace) query.eventPlace = eventPlace;
        if (tag) query.tag = tag;
        if (cameraType) query.cameraType = cameraType;
        if (userType) query.userType = userType;

        return {
          url: `google-analytics?date=${
            new Date(new Date().setDate(new Date().getDate() - 1))
              .toISOString()
              .split("T")[0]
          }&${new URLSearchParams(query).toString()}`,
          method: "GET",
        };
      },
      // ({
      //     url: `google-analytics${refresh ? '?refresh=true' : ''}`,
      //     method: 'GET',
      // }),
    }),
    getGoogleAnalyticsByRange: builder.query({
      query: ({
        startDate,
        endDate,
        organizationId,
        distributorId,
        deviceCreationDates,
        eventPlace,
        tag,
        cameraType,
        userType,
      }) => {
        const query = {};
        if (startDate) query.startDate = startDate;
        if (endDate) query.endDate = endDate;
        if (organizationId) query.organizationId = organizationId;
        if (distributorId) query.distributorId = distributorId;
        if (deviceCreationDates?.length)
          query.deviceCreationDates = deviceCreationDates;
        if (eventPlace) query.eventPlace = eventPlace;
        if (tag) query.tag = tag;
        if (cameraType) query.cameraType = cameraType;
        if (userType) query.userType = userType;

        return {
          url: `google-analytics/getAnalyticsByTimeRange?${new URLSearchParams(
            query,
          ).toString()}`,
          method: "GET",
        };
      },
    }),
    getOneUserUsage: builder.query({
      query: ({ refresh, id }) => ({
        url: `google-analytics/getAnalyticsByUserId?userId=${id}&date=${new Date()
          .toISOString()
          .slice(0, 10)}&refresh=${refresh}`,
        method: "GET",
      }),
    }),

    getFeatureAnalytics: builder.query({
      query: (params) => {
        const query = {};
        if (params.startDate) query.startDate = params.startDate;
        if (params.endDate) query.endDate = params.endDate;
        if (params.organizationId) query.organizationId = params.organizationId;
        if (params.type) query.type = params.type;
        if (params.eventPlace) query.eventPlace = params.eventPlace;
        if (params.tag) query.tag = params.tag;

        return {
          url: `features-analytics/get-all-analytics?${new URLSearchParams(
            query,
          ).toString()}`,

          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetGoogleAnalyticsQuery,
  useGetGoogleAnalyticsByRangeQuery,
  useGetOneUserUsageQuery,

  useGetFeatureAnalyticsQuery

} = googleAnalyticsApi;
