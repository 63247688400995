import { Navigate } from "react-router-dom";
import VerifyUserForm from "../sections/auth/verify-user/verifyUser.form";
import AuthLayout from "../layouts/auth/AuthLayout";

export default function VerifyUserPage() {
  const user = JSON.parse(localStorage.getItem("user"));

  if (!user) return <Navigate to="/login" />;

  if (user?.isVerified) {
    return <Navigate to="/dashboard/app" />;
  }

  return (
    <AuthLayout
      title="Change Generated Password | Sunbots"
      formHeading="Change your Auto Generated Password"
    >
      <VerifyUserForm />
    </AuthLayout>
  );
}
