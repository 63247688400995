import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Drawer,
  Divider,
  Typography,
  TextField,
  Autocomplete,
  Chip,
  Box,
  IconButton,
  FormHelperText,
  InputLabel,
  Avatar,
  Button,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// components
import { LoadingButton } from "@mui/lab";
import { Close, CloudUploadOutlined } from "@mui/icons-material";
import Scrollbar from "../../../components/scrollbar";
import { BlogSchema } from "../../../forms-schema/form.schema";
import {
  useCreateBlogMutation,
  useEditBlogMutation,
} from "../../../redux/services/blog.service";
import TipTapEditor from "../../../components/tiptap/TipTapEditor";
import useResponsive from "../../../hooks/useResponsive";
import { useUploadMultipleFilesMutation } from "../../../redux/services/upload.service";

BlogForm.propTypes = {
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  edit: PropTypes.bool,
  openRow: PropTypes.object,
};

const TYPES = [
  {
    value: "smarton",
    label: "Smarton",
  },
  {
    value: "sunbots",
    label: "Sunbots",
  },
];

export default function BlogForm({ open, onClose, edit, openRow }) {
  const isMedium = useResponsive("down", "md");

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(BlogSchema),
  });

  const [createBlog, { isLoading }] = useCreateBlogMutation();
  const [editBlog, { isLoading: isEditLoading }] = useEditBlogMutation();
  const [uploadMultiple, { isLoading: isUploadLoading }] =
    useUploadMultipleFilesMutation();
  const [coverImage, setCoverImage] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const thumbnailRef = useRef();
  const coverRef = useRef();

  useEffect(() => {
    if (edit) {
      setValue("title", openRow?.title || "");
      setValue("permalink", openRow?.permalink || "");
      setValue("tags", openRow?.tags || []);
      setValue("description", openRow?.description || "");
      setValue("shortDescription", openRow?.shortDescription || "");
      setValue("type", openRow?.type || "");
      setValue("thumbnailImage", openRow?.thumbnailImage || "");
      setValue("thumbnailAltText", openRow?.thumbnailAltText || "");
      setValue("coverImage", openRow?.coverImage || "");
      setValue("coverAltText", openRow?.coverAltText || "");
      setValue("ogTitle", openRow?.ogTitle || "");
      setValue("ogDescription", openRow?.ogDescription || "");
      setValue("twitterCard", openRow?.twitterCard || "");
      setValue("twitterTitle", openRow?.twitterTitle || "");
      setValue("twitterDescription", openRow?.twitterDescription || "");
      setValue("metaDescription", openRow?.metaDescription || "");
      setValue("seoTitle", openRow?.seoTitle || "");
      setValue("schemaMarkup", openRow?.schemaMarkup || "");
    } else {
      reset();
      setThumbnailImage(null);
      setCoverImage(null);
    }
  }, [open, reset, openRow, edit, setValue]);
  console.log("errors", errors);
  const handleClick = async (values) => {
    if (!thumbnailImage && !values?.thumbnailImage) {
      setError("thumbnailImage", {
        type: "required",
        message: "Thumbnail Image is required",
      });
      return;
    }

    if (!coverImage && !values?.coverImage) {
      setError("coverImage", {
        type: "required",
        message: "Cover Image is required",
      });
      return;
    }

    let fileUploadRes;
    if (thumbnailImage || coverImage) {
      const uploadBody = [];
      if (thumbnailImage) {
        uploadBody.push(thumbnailImage);
      }

      if (coverImage) {
        uploadBody.push(coverImage);
      }

      fileUploadRes = await uploadMultiple(uploadBody);

      if (thumbnailImage && coverImage) {
        values.thumbnailImage = fileUploadRes?.data?.data?.[0]?.Location;
        values.coverImage = fileUploadRes?.data?.data?.[1]?.Location;
        values.ogImage = fileUploadRes?.data?.data?.[0]?.Location;
        values.twitterImage = fileUploadRes?.data?.data?.[0]?.Location;
      } else if (thumbnailImage) {
        values.thumbnailImage = fileUploadRes?.data?.data?.[0]?.Location;
        values.ogImage = fileUploadRes?.data?.data?.[0]?.Location;
        values.twitterImage = fileUploadRes?.data?.data?.[0]?.Location;
      } else if (coverImage) {
        values.coverImage = fileUploadRes?.data?.data?.[0]?.Location;
      }
    }
    let resultAction;
    if (edit) {
      resultAction = await editBlog({
        ...values,
        ogImage: values.thumbnailImage,
        twitterImage: values.thumbnailImage,
        id: openRow?.id,
      });
    } else {
      resultAction = await createBlog({
        ...values,
      });
    }
    if (resultAction?.data?.success) {
      reset();
      onClose();
      setThumbnailImage(null);
      setCoverImage(null);
    }
  };

  const handleUpdateDescription = (editor) => {
    setValue("description", editor?.editor?.getHTML());
    // setDescription(editor?.editor?.getHTML());
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: isMedium ? "100%" : "80%",
            border: "none",
            overflow: "hidden",
            maxWidth: 1200,
          },
        }}
      >
        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 0.5,
                }}
              >
                <Typography variant="subtitle1">
                  {edit ? "Edit" : "Create"} Blog
                </Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </div>
            <form onSubmit={handleSubmit(handleClick)}>
              <Stack spacing={3}>
                <TextField
                  name="title"
                  label="Title"
                  {...register("title", { required: true })}
                  error={Boolean(errors.title)}
                  helperText={errors.title && errors.title.message}
                  inputProps={{
                    "data-testid": "title",
                  }}
                />

                <TextField
                  multiline
                  maxRows={4}
                  name="shortDescription"
                  label="Short Description"
                  {...register("shortDescription")}
                  error={!!errors.shortDescription}
                  helperText={errors.shortDescription?.message}
                  inputProps={{ "data-testid": "shortDescription" }}
                />
                <FormControl fullWidth>
                  <InputLabel
                    id="type-select-label"
                    error={Boolean(errors.type)}
                  >
                    Type
                  </InputLabel>
                  <Select
                    {...register("type", { required: true })}
                    labelId="type-select-label"
                    label="Type"
                    role="combobox"
                    name="type"
                    inputProps={{ "data-testid": "type" }}
                    value={watch("type") ?? ""}
                  >
                    {TYPES.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={Boolean(errors.type)}>
                    {errors.type?.message}
                  </FormHelperText>
                </FormControl>

                <TextField
                  name="permalink"
                  label="Permalink"
                  {...register("permalink", { required: true })}
                  error={Boolean(errors.permalink)}
                  helperText={errors.permalink && errors.permalink.message}
                  inputProps={{
                    "data-testid": "permalink",
                  }}
                />

                <TextField
                  name="seoTitle"
                  label="SEO Title"
                  {...register("seoTitle", { required: true })}
                  error={Boolean(errors.seoTitle)}
                  helperText={errors.seoTitle?.message}
                  inputProps={{
                    "data-testid": "seoTitle",
                  }}
                />

                <TextField
                  name="metaDescription"
                  label="Meta Description"
                  {...register("metaDescription", { required: true })}
                  error={Boolean(errors.metaDescription)}
                  helperText={errors.metaDescription?.message}
                  inputProps={{
                    "data-testid": "metaDescription",
                  }}
                />

                <Box>
                  <InputLabel
                    sx={{
                      marginBottom: 1,
                    }}
                    htmlFor="thumbnailImage"
                    error={Boolean(errors.thumbnailImage)}
                  >
                    Thumbnail Image
                  </InputLabel>
                  {thumbnailImage || watch("thumbnailImage") ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Avatar
                        src={
                          thumbnailImage instanceof File
                            ? URL.createObjectURL(thumbnailImage)
                            : watch("thumbnailImage")
                        }
                        slotProps={{
                          img: {
                            sx: {
                              objectFit: "contain",
                            },
                          },
                        }}
                        sx={{
                          width: 40,
                          height: 40,
                          marginRight: 1,
                          borderRadius: 0,
                        }}
                      />
                      <Button
                        variant="text"
                        onClick={() => {
                          setThumbnailImage(null);
                          setValue("thumbnailImage", "");
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          thumbnailRef.current.click();
                        }}
                        size="medium"
                        style={{ marginLeft: "-1px" }}
                      >
                        <CloudUploadOutlined />
                        <input
                          ref={thumbnailRef}
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            setThumbnailImage(e.target.files[0]);
                          }}
                          data-testid="thumbnailImage"
                        />
                      </IconButton>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: 16,
                          color: "#95999C",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Choose a file
                      </Typography>
                    </Box>
                  )}
                  <FormHelperText error={Boolean(errors.thumbnailImage)}>
                    {errors.thumbnailImage?.message}
                  </FormHelperText>
                </Box>

                <TextField
                  name="thumbnailImageAlt"
                  label="Thumbnail Image Alt"
                  {...register("thumbnailAltText", { required: true })}
                  error={Boolean(errors.thumbnailAltText)}
                  helperText={errors.thumbnailAltText?.message}
                  inputProps={{
                    "data-testid": "thumbnailImageAlt",
                  }}
                />

                <Box>
                  <InputLabel
                    sx={{
                      marginBottom: 1,
                    }}
                    htmlFor="coverImage"
                    error={Boolean(errors.coverImage)}
                  >
                    Cover Image
                  </InputLabel>
                  {coverImage || watch("coverImage") ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Avatar
                        src={
                          coverImage instanceof File
                            ? URL.createObjectURL(coverImage)
                            : watch("coverImage")
                        }
                        slotProps={{
                          img: {
                            sx: {
                              objectFit: "contain",
                            },
                          },
                        }}
                        sx={{
                          width: 40,
                          height: 40,
                          marginRight: 1,
                          borderRadius: 0,
                        }}
                      />
                      <Button
                        variant="text"
                        onClick={() => {
                          setCoverImage(null);
                          setValue("coverImage", "");
                        }}
                      >
                        Remove
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          coverRef.current.click();
                        }}
                        size="medium"
                        style={{ marginLeft: "-1px" }}
                      >
                        <CloudUploadOutlined />
                        <input
                          ref={coverRef}
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            setCoverImage(e.target.files[0]);
                          }}
                          data-testid="coverImage"
                        />
                      </IconButton>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: 16,
                          color: "#95999C",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Choose a file
                      </Typography>
                    </Box>
                  )}
                  <FormHelperText error={Boolean(errors.coverImage)}>
                    {errors.coverImage?.message}
                  </FormHelperText>
                </Box>

                <TextField
                  name="coverImageAlt"
                  label="Cover Image Alt"
                  {...register("coverAltText", { required: true })}
                  error={Boolean(errors.coverAltText)}
                  helperText={errors.coverAltText?.message}
                  inputProps={{
                    "data-testid": "coverImageAlt",
                  }}
                />

                <TextField
                  name="schemaMarkup"
                  label="Schema Markup"
                  {...register("schemaMarkup", { required: true })}
                  error={Boolean(errors.schemaMarkup)}
                  helperText={errors.schemaMarkup?.message}
                  inputProps={{
                    "data-testid": "schemaMarkup",
                  }}
                />

                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  freeSolo
                  value={watch("tags") || []}
                  renderTags={(value, getTagProps) =>
                    value?.map((option, index) => (
                      <Chip
                        key={index}
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  {...register("tags", { required: true })}
                  onChange={(e, value) => {
                    setValue(`tags`, value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // {...item}
                      fullWidth
                      label="Tags"
                      inputProps={{
                        ...params.inputProps,
                        "data-testid": "tags",
                      }}
                      error={Boolean(errors.tags)}
                      helperText={errors.tags && errors.tags.message}
                    />
                  )}
                />

                <TextField
                  name="ogTitle"
                  label="OG Title"
                  {...register("ogTitle", { required: true })}
                  error={Boolean(errors.ogTitle)}
                  helperText={errors.ogTitle?.message}
                  inputProps={{
                    "data-testid": "ogTitle",
                  }}
                />

                <TextField
                  name="ogDescription"
                  label="OG Description"
                  {...register("ogDescription", { required: true })}
                  error={Boolean(errors.ogDescription)}
                  helperText={errors.ogDescription?.message}
                  inputProps={{
                    "data-testid": "ogDescription",
                  }}
                />

                <TextField
                  name="twitterCard"
                  label="Twitter Card"
                  {...register("twitterCard", { required: true })}
                  error={Boolean(errors.twitterCard)}
                  helperText={errors.twitterCard?.message}
                  inputProps={{
                    "data-testid": "twitterCard",
                  }}
                />

                <TextField
                  name="twitterTitle"
                  label="Twitter Title"
                  {...register("twitterTitle", { required: true })}
                  error={Boolean(errors.twitterTitle)}
                  helperText={errors.twitterTitle?.message}
                  inputProps={{
                    "data-testid": "twitterTitle",
                  }}
                />

                <TextField
                  name="twitterDescription"
                  label="Twitter Description"
                  {...register("twitterDescription", { required: true })}
                  error={Boolean(errors.twitterDescription)}
                  helperText={errors.twitterDescription?.message}
                  inputProps={{
                    "data-testid": "twitterDescription",
                  }}
                />

                {edit && openRow?.description && watch("description") && (
                  <TipTapEditor
                    onUpdate={handleUpdateDescription}
                    content={watch("description") || ""}
                  />
                )}

                {!edit && (
                  <TipTapEditor
                    onUpdate={handleUpdateDescription}
                    content={watch("description") || ""}
                  />
                )}
                {errors.description && (
                  <FormHelperText error>
                    {errors.description.message}
                  </FormHelperText>
                )}

                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    width: isMedium ? "100%" : 240,
                  }}
                  loading={isLoading || isEditLoading || isUploadLoading}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </form>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
