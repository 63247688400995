import * as yup from "yup";

export const BulkAddUserSchema = yup.object().shape({
  senderOrganizationId: yup
    .string()
    .required("sender Organization is required"),
  recipientOrganizationId: yup
    .string()
    .required("Recipient Organization is required"),
  eventPlace: yup.string().required("Event Place is required"),
});

export const ActivatePlansFormSchema = yup.object().shape({
  plans: yup.string().required("Plan is Required"),
});

export const AddPlansFormSchema = yup.object().shape({
  name: yup.string().required("PlanName is Required"),
  type: yup.string().required("PlanType is Required"),
  interval: yup
    .number()
    .required("PlanInterval is Required")
    .typeError("Interval must be a valid Number"),
  countryCode: yup.string().required("Country is Required"),
  period: yup.string().required("PlanPeriod is Required"),
  amount: yup
    .number()
    .required("PlanAmount is Required")
    .typeError("Amount must be a valid Number"),
  currency: yup.string().required("PlanCurrency is Required"),
  smartEyeLimit: yup
    .number()
    .required("SmartEyeLimit is Required")
    .typeError("SmartEyeLimit must be a valid Number"),
  aiChatLimit: yup
    .number()
    .typeError("AiChatLimit must be a valid Number")
    .required("AiChatLimit is Required"),
  docAiLimit: yup
    .number()
    .typeError("DocAiLimit must be a valid number") // Handle invalid type error
    .required("DocAiLimit is Required"),
  description: yup.string(),
  isFree: yup.boolean().optional(),

  durationDays: yup
    .number()
    .when("isFree", {
      is: true, // When isFree is true
      then: yup.number().required("DurationDays is Required"),
      otherwise: yup.number().notRequired(),
    })
    .typeError("DurationDays must be a Number"),
});

export const planSchema = yup.object().shape({
  durationDays: yup
    .number()
    .when("isFree", {
      is: true, // When isFree is true
      then: yup.number().required("DurationDays is Required"),
      otherwise: yup.number().notRequired(),
    })
    .typeError("DurationDays must be a Number"),

  isFree: yup.boolean().optional(),
  smartEyeLimit: yup
    .number()
    .required("SmartEyeLimit is Required")
    .typeError("SmartEyeLimit must be a valid Number"),
  aiChatLimit: yup
    .number()
    .typeError("AiChatLimit must be a valid Number")
    .required("AiChatLimit is Required"),
  docAiLimit: yup
    .number()
    .typeError("DocAiLimit must be a valid number") // Handle invalid type error
    .required("DocAiLimit is Required"),
});

export const CountrySchema = yup.object().shape({
  countryName: yup.string().required("Country Name is required"),
  countryMobileCode: yup.string().required("Country Mobile Code is required"),
});

export const DevicesSchema = yup.object().shape({
  numberOfDevices: yup
    .number()
    .required("Total Device is required")
    .typeError("Total Device must be a number"),
  senderOrganization: yup.string().required("sender Organization is required"),
  recipientOrganization: yup
    .string()
    .required("Recipient Organization is required"),
  eventPlace: yup.string().required("Event Place is required"),
  tag: yup.string().optional(),
});

export const OfferSchema = yup.object().shape({
  name: yup.string().required("Offer Name is required"),
  description: yup.string().required("Description is required"),
  discountAmount: yup
    .number()
    .required("Max Discount is required")
    .typeError("Max Discount must be a number"),
  code: yup.string().required("Code is required"),
  status: yup.string().optional(),
});

export const LanguageSchema = yup.object().shape({
  language: yup.string().required("Language is required"),
});

export const LinkCodeSchema = yup.object().shape({
  deviceCode: yup.string().required("Device Code is required"),
});

export const OrganizationSchema = yup.object().shape({
  createAccount: yup.boolean(),
  name: yup.string().required("Organization Name is required"),
  address: yup.string(),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  country: yup.string().required("Country is required"),
  // zipCode: yup.string().required("Zip Code is required"),
  // contactNumber: yup.string(),
  contactNumber: yup.string().when("createAccount", {
    is: true,
    then: yup
      .string()
      .required("Contact Number is required")
      .matches(/^[0-9]{10}$/, "Contact Number must be exactly 10 digits"),
    otherwise: yup
      .string()
      .test(
        "is-empty",
        "Contact Number cannot be empty if provided",
        (value) => {
          // If value is not empty, apply other validations
          if (!value) return true; // Skip validation if the field is empty
          return /^[0-9]{1,10}$/.test(value); // Validate if the number is numeric and not more than 10 digits
        },
      ),
  }),

  zipCode: yup
    .string()
    .max(6, "Zip Code must not exceed 6 characters") // Limit to 6 characters
    .matches(/^[0-9]+$/, "Zip Code must be numeric") // Ensure only numbers are allowed // Required field
    .optional(),
  email: yup.string(),
  website: yup
    .string()
    .optional()
    .test("is-valid-url", "Website must be a valid URL", (value) => {
      // Check if the value is empty, if so, skip validation
      if (!value) return true; // If it's empty, validation passes
      // Otherwise, validate the URL format with or without protocol
      return /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\S*)?$/.test(
        value,
      );
    }),

  logo: yup.mixed().optional(),
  description: yup.string(),
  isSplashScreen: yup.boolean().optional(),
  speakOutSentence: yup.string().optional().nullable(),
  type: yup.string().required("Type is required"),
});

export const SupportedDevicesSchema = (deviceOptions, edit) =>
  yup.object().shape({
    deviceName: yup
      .string()
      .required("Device Name is required")
      .test("is-valid-device", "Device already exists", (value) => {
        if (edit) {
          return true;
        }
        const validDeviceNames = deviceOptions?.map(
          (option) => option.deviceName,
        );
        const enteringValue = value?.replace(/\s/g, "")?.toLowerCase();
        return !validDeviceNames?.some(
          (name) => name?.replace(/\s/g, "")?.toLowerCase() === enteringValue,
        );
      }),
    isSupported: yup
      .string()
      .required("Supported is required")
      .typeError("Supported is required"),
  });

export const UserSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First Name is required")
    .typeError("First Name is required"),
  lastName: yup
    .string()
    .required("Last Name is required")
    .typeError("Last Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .typeError("Email is required")
    .email("Enter a valid email address"),
});

export const WordSchema = yup.object().shape({
  englishWords: yup
    .array()
    .of(yup.string().required("English word is required")),
});

export const OrderSchema = yup.object().shape({
  totalDevices: yup
    .number()
    .required("Total Devices is required")
    .typeError("Total Devices is required"),
  orderTo: yup.string().required("Order To is required"),
  totalAmount: yup
    .number()
    .optional()
    .nullable()
    .typeError("Total Amount must be a number"),
  orderDate: yup.string().when("orderStatus", {
    is: (orderStatus) => orderStatus !== null && orderStatus?.length,
    then: yup
      .string()
      .required("Order Date is required")
      .typeError("Order Date is required"),
    otherwise: yup.string().optional().nullable(),
  }),
  paymentDate: yup.string().when("paymentStatus", {
    is: "success",
    then: yup
      .string()
      .required("Payment Date is required")
      .typeError("Payment Date is required"),
    otherwise: yup.string().optional().nullable(),
  }),
  dueDate: yup.string().optional().nullable(),
  orderStatus: yup.string().optional().nullable(),
  paymentStatus: yup.string().optional().nullable(),
  deliveryStatus: yup.string().optional().nullable(),
});

export const BlogSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  permalink: yup.string().required("Permalink is required"),
  tags: yup
    .array()
    .of(yup.string())
    .min(1, "Tags is required")
    .typeError("Tags is required"),
  description: yup.string().required("Description is required"),
  shortDescription: yup.string().required("Short Description is required"),
  type: yup.string().required("Type is required"),
  thumbnailImage: yup.string().optional().nullable(),
  thumbnailAltText: yup.string().required("Thumbnail Alt Text is required"),
  coverImage: yup.string().optional().nullable(),
  coverAltText: yup.string().required("Cover Alt Text is required"),
  metaDescription: yup.string().required("Meta Description is required"),
  seoTitle: yup.string().required("SEO Title is required"),
  schemaMarkup: yup.string().required("Schema Markup is required"),
  ogTitle: yup.string().required("OG Title is required"),
  ogDescription: yup.string().required("OG Description is required"),
  twitterCard: yup.string().required("Twitter Card is required"),
  twitterTitle: yup.string().required("Twitter Title is required"),
  twitterDescription: yup.string().required("Twitter Description is required"),
});

export const AuthUserSchema = (roles) =>
  yup.object().shape({
    contactNumber: yup.string().required("Contact Number is required"),
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup.string().required("Email is required").email("Invalid Email"),
    dateOfBirth: yup.string().required("Date of Birth is required"),
    gender: yup.string().required("Gender is required"),
    address: yup.string().required("Address is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    country: yup.string().required("Country is required"),
    zipCode: yup.string().required("Zip Code is required"),
    companyName: yup.string().optional().nullable(),
    taxId: yup.string().optional().nullable(),
    roleIds: yup
      .array(
        yup.string().required("Role is required").typeError("Role is required"),
      )
      .required("Role is required")
      .min(1, "Role is required")
      .typeError("Role is required"),
    purchasePrice: yup.number().when("roleIds", {
      is: (roleIds) =>
        roleIds?.includes(
          roles?.find((role) => role.name === "distributor")?.id,
        ),
      then: yup
        .number()
        .required("Purchase Price is required")
        .typeError("Purchase Price is required"),
      otherwise: yup.number().optional().nullable(),
    }),
  });

export const RoleSchema = yup.object().shape({
  name: yup.string().required("Role Name is required"),
});

export const PermissionSchema = yup.object().shape({
  protected: yup.boolean().required("Protected is required"),
  tag: yup.string().required("Tag is required"),
  description: yup.string().required("Description is required"),
});

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const ForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email("Email must be a valid email address")
    .required("Email is required"),
});

export const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character",
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const VerifyUserSchema = yup.object().shape({
  password: yup.string().required("Generated Password is required"),
  newPassword: yup
    .string()
    .required("New Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character",
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

export const SupportedLanguagesSchema = yup.object().shape({
  languageName: yup.string().required("Language Name is required"),
  languageCode: yup.string().required("Language Code is required"),
  isOffline: yup.boolean().required("Offline is required"),
});

export const DistributorSchema = yup.object().shape({
  contactNumber: yup.string().required("Contact Number is required"),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().required("Email is required").email("Invalid Email"),
  dateOfBirth: yup.string().required("Date of Birth is required"),
  gender: yup.string().required("Gender is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  country: yup.string().required("Country is required"),
  zipCode: yup.string().required("Zip Code is required"),
  companyName: yup.string().optional().nullable(),
  taxId: yup.string().optional().nullable(),
  purchasePrice: yup
    .number()
    .required("Purchase Price is required")
    .typeError("Purchase Price is required"),
});

export const ChangeMobileSchema = (isSuccess) =>
  isSuccess
    ? yup.object().shape({
        newMobileNumber: yup.string().required("New Mobile Number is required"),
        otp: yup.string().required("OTP is required"),
      })
    : yup.object().shape({
        newMobileNumber: yup.string().required("New Mobile Number is required"),
        otp: yup.string().optional(),
      });

export const ColumnAccessControlSchema = yup.object().shape({
  entityName: yup.string().required("Entity Name is required"),
  columnName: yup.string().required("Column Name is required"),
  type: yup.string().required("Type is required"),
  headerName: yup.string().required("Header Name is required"),
  defaultVisible: yup.boolean().required("Default Visible is required"),
  isDownloadable: yup.boolean().required("Is Downloadable is required"),
  isFilterable: yup.boolean().required("Is Filterable is required"),
  isSortable: yup.boolean().required("Is Sortable is required"),
  isViewable: yup.boolean().required("Is Viewable is required"),
  isAccessible: yup.boolean().required("Is Accessible is required"),
  isForeignKey: yup.boolean().required("Is Foreign Key is required"),
});

export const PrioritySchema = yup.object().shape({
  priority: yup.array().of(yup.string()).required("Priority is required"),
});
