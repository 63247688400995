import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import PropTypes from "prop-types";
import "./UsageChart.css";
import moment from "moment";
import { fNumber, fShortenNumber } from "../../../utils/formatNumber";

UsageChart.propTypes = {
  duration: PropTypes.string,
  chartData: PropTypes.array,
};

export default function UsageChart({ duration, chartData }) {
  const seriesData = [
    {
      name: "Usage",
      data: chartData?.map((i) => i?.totalCount) || [],
    },
  ];
  const chartOptions = {
    series: seriesData,
    chart: {
      type: "area",
      height: "500",
      zoom: {
        enabled: false,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: false,
      },
    },
    labels: chartData?.map((i) => {
      const date = new Date(i?.date?.split("T")?.[0]);
      if (duration === "monthly") {
        return moment(date).format("DD/MM");
      }
      if (duration === "yearly") {
        return `${i?.month?.split(" ")[0].slice(0, 3)} ${
          i?.month?.split(" ")[1]
        }`;
      }
      return moment(date).format("DD/MM/YYYY");
    }),
    markers: {
      size: 0,
      style: "hollow",
    },
    tooltip: {
      custom: ({
        series,
        seriesIndex,
        dataPointIndex,
        w,
      }) => `<div class="tooltipWrapper">
          <div class="tooltipHeader">
            <div class="tooltipTitle">${
              duration === "yearly"
                ? chartData?.[dataPointIndex]?.month
                : moment(
                    chartData?.[dataPointIndex]?.date?.split("T")?.[0],
                  )?.format("DD-MM-YYYY")
            }</div>
          </div>
          <div class="tooltipBody">
            <div class="tooltipContent">
              <div class="row">
                <div class="column">
                  <div class="tooltipContentItem">Object: ${fNumber(
                    chartData?.[dataPointIndex].objectCount,
                  )}</div>
                  <div class="tooltipContentItem">Currency: ${fNumber(
                    chartData?.[dataPointIndex].currencyCount,
                  )}</div>
                  <div class="tooltipContentItem">Words: ${fNumber(
                    chartData?.[dataPointIndex].wordCount,
                  )}</div>
                </div>
                <div class="column">
                  <div class="verticalSeparator"></div>
                </div>
                <div class="column">
                  <div class="tooltipContentItem">Smart Eye: ${fNumber(
                    chartData?.[dataPointIndex].smartEye,
                  )}</div>
                  <div class="tooltipContentItem">Doc AI: ${fNumber(
                    chartData?.[dataPointIndex].docAI,
                  )}</div>
                  <div class="tooltipContentItem">AI Tutor: ${fNumber(
                    chartData?.[dataPointIndex].aiChat,
                  )}</div>
                </div>
              </div>
              <div class="separator"></div>
              <div class="tooltipContentItem">Total: ${fNumber(
                chartData?.[dataPointIndex].totalCount,
              )}</div>
            </div>
          </div>
        </div>
        `,
      // y: {
      // title: {
      //   formatter: (val, { series, seriesIndex, dataPointIndex, w }) => {
      //     return ``
      //   },
      // },
      // formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
      //   return `hey ${value}`
      // },
      // },
      //   x: {
      //     format: 'HH:mm',
      //   },
    },
    // toolbar:{
    //   show: false
    // },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };
  // useEffect(() => {
  //   switch (duration) {
  //     case 'one_day':
  //       setDataOfChart(charData.one_day.map(i => i.value))
  //       setChartLabels(charData.one_day.map(i => `${new Date(i.label).getHours()}:${new Date(i.label).getMinutes()}`))
  //       break
  //     case 'one_weekly':
  //       setDataOfChart(charData.one_weekly.map(i => i.value))
  //       setChartLabels(charData.one_weekly.map(i => `${new Date(i.label).getDate()}/${new Date(i.label).getmonthly() + 1}`))
  //       break
  //     case 'one_monthly':
  //       setDataOfChart(charData.one_monthly.map(i => i.value))
  //       setChartLabels(
  //         charData.one_monthly.map(i => `${new Date(i.label).getDate()}/${new Date(i.label).getmonthly() + 1}`)
  //       )
  //       break
  //     case 'one_yearly':
  //       setDataOfChart(charData.one_yearly.map(i => i.value))
  //       setChartLabels(
  //         charData.one_yearly.map(i => `${new Date(i.label).getmonthly() + 1}/${new Date(i.label).getFullyearly()}`)
  //       )
  //       break
  //     default:
  //   }
  // }, [duration])

  return (
    <Card sx={{ height: "450px" }} data-testid="usageChart">
      <CardHeader title="Usage" />
      <ReactApexChart
        options={chartOptions}
        series={seriesData}
        type="area"
        height={398}
      />
    </Card>
  );
}
