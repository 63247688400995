import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBaseQuery } from "../basequery";

export const plansApi = createApi({
  reducerPath: "plansApi",
  baseQuery: extendedBaseQuery,
  tagTypes: ["plans"],
  endpoints: (builder) => ({
    getPlans: builder.query({
      query: (id) => ({
        url: `plans/all`,
        method: "GET",
      }),
      providesTags: ["plans"],
    }),
    activatePlan: builder.mutation({
      query: (body) => ({
        url: "plans/activatePlan",
        method: "POST",
        body,
      }),
      invalidatesTags: ["plans"],
    }),
    createPlan: builder.mutation({
      query: (body) => ({
        url: "plans/create",
        method: "POST",
        body,
      }),
    }),
    updatePlan: builder.mutation({
      query: (body) => ({
        url: `plans/updatePlan/${body.id}`,
        method: "PATCH",
        body,
      }),
    }),
  }),
});

export const {
  useGetPlansQuery,
  useActivatePlanMutation,
  useCreatePlanMutation,
  useUpdatePlanMutation,
} = plansApi;
