import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBaseQuery } from "../basequery";

export const blogApi = createApi({
  reducerPath: "blogApi",
  baseQuery: extendedBaseQuery,
  tagTypes: ["Blog"],
  endpoints: (builder) => ({
    getBlogs: builder.query({
      query: (params) => {
        const query = {};
        if (params?.skip) query.skip = params.skip;
        if (params?.limit) query.limit = params.limit;
        if (params?.search) query.search = params.search;
        if (params?.filter) query.filter = params.filter;
        if (params?.sortBy) query.sortBy = params.sortBy;
        if (params?.orderBy) query.orderBy = params.orderBy;

        return {
          url: `blogs?${new URLSearchParams(query)}`,
          method: "GET",
        };
      },
      providesTags: ["Blog"],
    }),
    createBlog: builder.mutation({
      query: (body) => ({
        url: "blogs",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Blog"],
    }),
    editBlog: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `blogs/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Blog"],
    }),
    deleteBlog: builder.mutation({
      query: (id) => ({
        url: `blogs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Blog"],
    }),
    changePriority: builder.mutation({
      query: (body) => ({
        url: `blogs/update-pinned-order`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Blog"],
    }),
  }),
});

export const {
  useGetBlogsQuery,
  useCreateBlogMutation,
  useEditBlogMutation,
  useDeleteBlogMutation,
  useChangePriorityMutation
} = blogApi;
