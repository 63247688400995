import { createApi } from "@reduxjs/toolkit/query/react";
import { extendedBaseQuery } from "../basequery";

export const roleApi = createApi({
  reducerPath: "roleApi",
  baseQuery: extendedBaseQuery,
  tagTypes: ["Role"],
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: (params) => {
        const query = {};
        if (params?.skip) query.skip = params.skip;
        if (params?.limit) query.limit = params.limit;
        if (params?.search) query.search = params.search;
        if (params?.filter) query.filter = params.filter;
        if (params?.sortBy) query.sortBy = params.sortBy;
        if (params?.orderBy) query.orderBy = params.orderBy;

        return {
          url: `role?${new URLSearchParams(query).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Role"],
    }),
    createRole: builder.mutation({
      query: (body) => ({
        url: "role",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Role"],
    }),
    updateRole: builder.mutation({
      query: (body) => ({
        url: `role/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Role"],
    }),
    deleteRole: builder.mutation({
      query: (id) => ({
        url: `role/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Role"],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = roleApi;
