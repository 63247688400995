import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";

// @mui
import { Stack, IconButton, InputAdornment, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { useResetPasswordMutation } from "../../../redux/services/auth.service";
import Iconify from "../../../components/iconify";
import { ResetPasswordSchema } from "../../../forms-schema/form.schema";
// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useParams();

  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const handleClick = async (values) => {
    try {
      const resultAction = await resetPassword({
        ...values,
        token,
      });
      unwrapResult(resultAction);
      if (resultAction?.data?.success) {
        enqueueSnackbar(resultAction?.data?.message, { variant: "success" });
        navigate("/login", { replace: true });
      }
    } catch (err) {
      enqueueSnackbar(err?.data?.message ?? err?.error, { variant: "error" });
      console.error("Failed to resetPassword: ", err);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleClick)}>
      <Stack spacing={3}>
        <TextField
          name="password"
          label="New Password"
          {...register("password", { required: true })}
          type={showNewPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  edge="end"
                  data-testid="toggle-password"
                >
                  <Iconify
                    icon={showNewPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
          inputProps={{ "data-testid": "password" }}
          autoComplete="off"
        />

        <TextField
          name="confirmPassword"
          label="Confirm New Password"
          {...register("confirmPassword", { required: true })}
          type={showConfirmPassword ? "text" : "password"}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                  data-testid="toggle-confirm-password"
                >
                  <Iconify
                    icon={
                      showConfirmPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={Boolean(errors.confirmPassword)}
          helperText={errors.confirmPassword?.message}
          inputProps={{ "data-testid": "confirmPassword" }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isLoading}
        >
          Reset Password
        </LoadingButton>
      </Stack>
    </form>
  );
}
